import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Dropdown, DropdownProps } from 'semantic-ui-react';
import './HomeComponent.scss';
import { appContext } from '../../App';
import { addTaskToQueueJob, getAllWebsites, getComparisonPagesByWebsiteId, verifyUser } from '../../Helpers/api';
import { DropDownObj, cellDataType } from '../../Helpers/customTypes';
import AppHeaderComponent from '../AppHeaderComponent/AppHeaderComponent';
import ProductEditComponent from '../ProductEditComponent/ProductEditComponent';
import { ComparisonPage, MENU_TABS, Website } from '../../Modals/ContextObj';
import ComparisonEditComponent from '../ComparisonEditComponent/ComparisonEditComponent';
import AddBulkProductsComponent from '../AddBulkProductsComponent/AddBulkProductsComponent';
import readXlsxFile from 'read-excel-file';
import { WEBSITE_DOMAIN_NAMES, getPathFromCategories } from '../../Helpers/consts';
import WebsiteSettingsEditComponent from '../WebsiteSettingsEditComponent/WebsiteSettingsEditComponent';
import AmobeezPromptsComponent from '../AmobeezPromptsComponent/AmobeezPromptsComponent';
import ComparisonPagesTableComponent from '../ComparisonPagesTableComponent/ComparisonPagesTableComponent';
import LoginComponent from '../LoginComponent/LoginComponent';
import { useNavigate } from 'react-router-dom';
import KeywordsManagementComponent from '../KeywordsManagementComponent/KeywordsManagementComponent';

// import getPathFromCategories from '../../Helpers/consts';

interface HomeComponentProps {}

const HomeComponent = (props: HomeComponentProps) => {
  const navigate = useNavigate();
  const { currentTab, updateCurrentTab, setIsLoading } = useContext(appContext);
  const { updateUserAccessLevel } = useContext(appContext);
  const { userLoggedIn, updateUserLoggedIn } = useContext(appContext);
  const [firstInitial, setFirstInitial] = useState(true);

  const [websitesDropDown, setWebsitesDropDown] = useState<DropDownObj[]>([]);
  const [originalWebsites, setOriginalWebsites] = useState<Website[]>([]);
  const [selectedWebsite, setSelectedWebsite] = useState<string>('');
  const [selectedWebsiteId, setSelectedWebsiteId] = useState<number>(-1);

  const [uploadedFile, setUploadedFile] = useState<string | null>();
  const [originalFileRows, setOriginalFileRows] = useState<cellDataType[][]>([]);

  const [comparisonPagesByWebsite, setComparisonPagesByWebsite] = useState<ComparisonPage[]>([]);
  const [urlsDropDown, setUrlsDropDown] = useState<DropDownObj[]>([]);

  useEffect(() => {
    updateCurrentTab(MENU_TABS.KEYWORDS_MANAGEMENT);
    initData();
  }, []);

  useEffect(() => {
    let tmpUrls = comparisonPagesByWebsite.map((c) => ({
      key: c.id,
      text: getPathFromCategories(c),
      value: getPathFromCategories(c),
    }));

    setUrlsDropDown(tmpUrls);
  }, [comparisonPagesByWebsite]);

  const initData = async () => {
    setIsLoading(true);
    const logged = await checkIfUserLoggedIn();
    updateUserLoggedIn(logged);
    setFirstInitial(false);

    let websites: Website[] = await getAllWebsites();
    websites = websites.filter((w) => w.displayName !== WEBSITE_DOMAIN_NAMES.DECOR_HOME_BEAUTY);
    setOriginalWebsites(websites);

    let tmpWebsites = websites.map((c) => ({
      key: c.id,
      text: c.domain,
      value: c.domain,
    }));

    setWebsitesDropDown(tmpWebsites);

    setIsLoading(false);
  };

  const checkIfUserLoggedIn = async () => {
    const res = await verifyUser();
    console.log(res);
    if (res?.success && res?.verified) {
      //login success
      updateUserAccessLevel(res.accessLevel);

      if (res.accessLevel.indexOf('JPOST') !== -1) {
        window.history.pushState(null, '', '/jpost');
        navigate('/jpost');
      } else {
        if (res.accessLevel.indexOf('AMOBEEZ') !== -1) {
          window.history.pushState(null, '', '/amobeez');
          navigate('/amobeez');
        }
      }

      return true;
    } else {
      if (res?.success === false) {
        toast.error(`Error login - ${res.message}`);
        return false;
      }
    }

    toast.error('Seesion expired. You need to login again.');

    return false;
  };

  const handleWebsiteChange = async (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = websitesDropDown.find((o: DropDownObj) => o.value === data.value)?.value;

    if (value) {
      const obj = originalWebsites.find((c) => c.domain === value);
      if (obj) {
        setSelectedWebsite(value);
        setSelectedWebsiteId(obj.id);
        const comparisonPagesReps: ComparisonPage[] = await getComparisonPagesByWebsiteId(obj.id);
        setComparisonPagesByWebsite(comparisonPagesReps);
      }
    }
  };

  /*   const addAsinDataToCurrentCategory = async () => {
  
      try {
        setIsLoading(true);
        const category: Category = breadcrumbArr.slice(-1)?.[0];
  
        const res = await generateProductData(asinToAdd, category.id);
        setIsLoading(false);
        toast.success('Product saved successfully!')
      } catch (e) {
        setIsLoading(false);
        toast.error('Failed to save product');
      }
  
    } */

  const handleFileUploaded = async (input: any) => {
    setUploadedFile(input.name);
    const rows: cellDataType[][] = await readXlsxFile(input);
    setOriginalFileRows(rows);
  };

  const handleImportAsinsClicked = async () => {
    let prepareComparisonRows: { asins: cellDataType[] }[] = [];
    for (let i = 0; i < originalFileRows.length; i++) {
      prepareComparisonRows.push({ asins: originalFileRows[i] });
    }

    const payload = {
      websiteId: originalWebsites.find((w) => w.domain === selectedWebsite)?.id,
      prepareComparisonRows,
    };

    toast.success('Starting the process, it will take a while..');
    // await generateCompleteComparisonPagesInBulk(payload);
    await addTaskToQueueJob({
      websiteId: originalWebsites.find((w) => w.domain === selectedWebsite)?.id,
      action: 'generateCompleteComparisonPagesInBulk',
      params: payload,
    });
    toast.success('Updated Successfully!');
  };

  if (firstInitial) {
    return <></>;
  } else if (!userLoggedIn) {
    return <LoginComponent />;
  } else {
    return (
      <div className="home-container">
        <AppHeaderComponent publisherName={'Amobeez Affiliate'} />

        <div className="home-content-container">
          {currentTab !== MENU_TABS.AMAZON_PRODUCTS_DATABASE_OPERATIONS && (
            <div className="website-dropdown-container">
              <div className="title">Select Website: </div>
              <Dropdown
                search
                selection
                options={websitesDropDown}
                onChange={handleWebsiteChange}
                value={selectedWebsite}
              />
            </div>
          )}
          {currentTab === MENU_TABS.CREATE_COMPARISON_PAGE && (
            <>
              {selectedWebsite && (
                <div className="import-asins-csv-container">
                  <div className="title">Import ASINs For Bulk Comparison Pages Creation:</div>

                  <div className="file-upload-container">
                    <input
                      type="file"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={(e) => handleFileUploaded(e?.target?.files?.[0])}
                      title={' '}
                    />

                    {uploadedFile && (
                      <>
                        <span>
                          {' '}
                          File Name: <b>{uploadedFile}</b> <br />
                          {`${originalFileRows.length} Rows`}
                        </span>

                        <Button
                          icon="cloud"
                          content="Generate Comparison Pages from File"
                          onClick={() => handleImportAsinsClicked()}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}

              {selectedWebsite && comparisonPagesByWebsite.length > 0 && (
                <div className="list-of-comparison-pages-container">
                  <div className="title">{comparisonPagesByWebsite.length} Active Comparison Pages:</div>

                  <ComparisonPagesTableComponent
                    comparisonPages={comparisonPagesByWebsite}
                    selectedWebsite={selectedWebsite}
                    selectedWebsiteId={selectedWebsiteId}
                  />
                  {/*  {comparisonPagesByWebsite.map((cp: ComparisonPage, index: number) => (
                <div className='comparison-page-url-row' key={index}>
                  <a href={`https://${selectedWebsite}/comparison/${getPathFromCategories(cp)}`} target='_blank'>{getPathFromCategories(cp)}</a>
                </div>
              ))} */}
                </div>
              )}
            </>
          )}

          {currentTab === MENU_TABS.PRODUCTS && (
            <>
              <ProductEditComponent domain={selectedWebsite} selectedWebsiteId={selectedWebsiteId} />
            </>
          )}

          {currentTab === MENU_TABS.COMPARISON_PAGE && (
            <>
              <ComparisonEditComponent
                domain={selectedWebsite}
                selectedWebsiteId={selectedWebsiteId}
                comparisonPagesByWebsite={comparisonPagesByWebsite}
                urlsDropDown={urlsDropDown}
              />
            </>
          )}

          {currentTab === MENU_TABS.AMAZON_PRODUCTS_DATABASE_OPERATIONS && (
            <>
              <AddBulkProductsComponent />
            </>
          )}

          {currentTab === MENU_TABS.KEYWORDS_MANAGEMENT && (
            <>
              <KeywordsManagementComponent selectedWebsiteId={selectedWebsiteId} />
            </>
          )}

          {currentTab === MENU_TABS.WEBSITE_SETTINGS && (
            <>
              <WebsiteSettingsEditComponent originalWebsites={originalWebsites} selectedWebsiteId={selectedWebsiteId} />
            </>
          )}

          {currentTab === MENU_TABS.AMOBEEZ_PROMPTS && (
            <>
              <AmobeezPromptsComponent originalWebsites={originalWebsites} selectedWebsiteId={selectedWebsiteId} />
            </>
          )}
        </div>
      </div>
    );
  }
};

export default HomeComponent;
