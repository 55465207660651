import { DropDownObj } from "../Helpers/customTypes";

export interface ContextObj {
  isLoading: boolean;
  username: string;
  currentTab: string;
  currentEditComparisonPage: DropDownObj;
  userAccessLevel: string;
  userLoggedIn: boolean;

  updateUsername: (value: string) => void;
  setIsLoading: (value: boolean) => void;
  updateCurrentTab: (value: MENU_TABS) => void;
  updateCurrentEditComparisonPage: (value: DropDownObj) => void;
  updateUserAccessLevel: (value: string) => void;
  updateUserLoggedIn: (value: boolean) => void;
}

export type cellDataType = string | number | boolean | DateConstructor;

export enum MENU_TABS {
  CREATE_COMPARISON_PAGE = 'create comparison page',
  PRODUCTS = 'edit products',
  COMPARISON_PAGE = 'edit comparison_page',
  AMAZON_PRODUCTS_DATABASE_OPERATIONS = 'amazon products database operations',
  AMOBEEZ_PROMPTS = 'amobeez prompts',
  WEBSITE_SETTINGS = 'website settings',
  KEYWORDS_MANAGEMENT = 'keywords management',
  JPOST_PROMPTS = 'prompts',
  JPOST_PRODUCTS = 'edit products',
  JPOST_COMPARISON_PAGE = 'edit comparison_page',
  EDIT_ARTICLE = 'edit article',
  JPOST_AMAZON_REPORTS = 'amazon reports',
  GTR_CAMPAIGN_REPORTS = 'campaign reports',
  JPOST_MANUAL_EDITOR = 'manual editor',
  DUPLICATE_ARTICLE = 'duplicate article',
  VIDEO_EDITOR = 'video editor'
}

export interface Website {
  id: number,
  domain: string,
  displayName: string,
  settings: string,
  menuItems: string,
  affiliateLink: string,
  htmlBlock1: string,
  htmlBlock2: string,
  createdAt: string,
  updatedAt: string,
}

export interface Prompt {
  id: number,
  tag: string,
  name: string,
  text: string,
  createdAt: string,
  updatedAt: string,
}

export interface TrendingProduct {
  id: number,
  asin: string,
  categoriesFlat: string,
  usage: any,
  createdAt: string,
  updatedAt: string,
}

export interface Keyword {
  id: number,
  name: string,
  info: string,
  difficulty?: number,
  searchVolume?: number,
  createdAt: string,
  updatedAt: string,
}

export interface KeywordsManagement {
  id: number,
  generalKwPerDay: number,
  affiliateKwPerDay: number,
  topSaleKwPerDay: number,
  websiteId: number,
  createdAt: string,
  updatedAt: string
}

export interface AmazonReportWithAdditions extends AmazonReport {
  affiliateType: string,
  comparisonPages: string[]
}

export interface AmazonReport {
  id: number,
  category: string,
  name: string,
  asin: string,
  seller: string,
  trackingId: string,
  dateShipped: string,
  price: number,
  itemsShipped: number,
  returns: number,
  revenue: number,
  adFees: number,
  device: string,
  affiliateType: string,
  additionalFees: number,
  websiteId: number
}

export interface Category {
  id: number;
  name: string;
  displayName: string;
  parentCategory: number;
  createdAt: string;
  updatedAt: string;
}

export interface Writer {
  id: number;
  name: string;
  linkedin: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ComparisonPageResp {
  comparisonPage: ComparisonPage;
  products: { product: Product, amazonData: AmazonProduct, keyword?: string }[];
}

export interface ComparisonPage {
  id: number;
  category1: string;
  category2: string;
  category3: string;
  title: string;
  date: string;
  writterName: string;
  introContent: string;
  introContentEdited: string;
  conclusion: string;
  conclusionEdited: string;
  categoryImage: string;
  buyersGuideAndFaq: string;
  buyersGuideAndFaqEdited: string;
  faq: string;
  faqEdited: string;
  verified: boolean;
  analystVersion: boolean;
  keyword: string;
  catalogItemsCount?: number;
  affiliatorItemsCount?: number;
  otherItemsCount?: number;
  productionPageUrl?: string;
  version: number;
  relatedKeywords: string;
  teaser: string;
  teaserEdited: string;
  notes: string;
  comparisonPageType: string; // 'COMPARISON' || 'SINGLE' || 'TRENDING' || 'BLOG'
  wordpressPageId: number;
  wordpressParentId: number;
  wordpressPageSlug: string;
  amazonCategoryId: string;
  excludedAsins: string;
  excludedWords: string;
  amazonUniqueTag: string; 
  forceBrandingLimit: boolean;
  blogContent: string;
  websiteId: number;
  createdAt: string;
  updatedAt: string;
}

export interface Product {
  id: number;
  asin: string;
  displayName: string;
  pros: string;
  prosEdited: string;
  cons: string;
  consEdited: string;
  productDescription: string;
  position: number;
  grade: number;
  miniProductDescription: string;
  miniProductDescriptionEdited: string;
  title: string;
  mainSpecs: string;
  itemType: string; // REGULAR / DEAL
  nonReplacable: boolean;
  comparisonPageId: number
  websiteId: number;
  createdAt: string;
  updatedAt: string;
}

// export interface Product {
//   sku: string;
//   merchant_code: string;
//   asin: string;
//   name: string;
//   merchant_name: string;
//   upc: string;
//   product_cost: number;
//   average_shipping_cost: number;
// }

export interface AmazonProduct {
  id: number;
  sku: string;
  merchant_code: string;
  asin: string;
  name: string;
  merchant_name: string;
  upc: string;
  product_cost: number;
  average_shipping_cost: number;
  brand: string;
  rating: number;
  reviews: number;
  price: number;
  category1: string;
  category2: string;
  category3: string;
  category4: string;
  category5: string;
  category6: string;
  category7: string;
  category8: string;
  category9: string;
  lastSubCategory: string;
  categoriesFlat: string,
  link: string;
  title: string;
  bsrFlat: string;
  bsrCategory: string;
  bsrNumber: number;
  monthlySalesEstimation: number;
  simillarItems: string;
  keywords: string;
  parentASIN: string;
  image1: string;
  image2: string;
  specifications: string;
  bestSellerUrl: string;
  stock: boolean;
  discountPercentage: number,
  subscribePercentage: number,
  priceBefore: number;
  affiliateType: string;
  affiliateLink: string;
  affiliateMerchant: string;
  affiliateCommission: number;
  creatorConnectionCommission: number;
  creatorConnectionExpDate: Date;
  createdAt: Date;
  updatedAt: Date;
}