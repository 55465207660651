import { useContext, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Dropdown, DropdownProps, Input, Icon, Image, Menu, Segment } from 'semantic-ui-react';
import consts, { ACCESS_LEVEL } from '../../Helpers/consts';
import './AppHeaderComponent.scss';
import { appContext } from '../../App';
import { MENU_TABS } from '../../Modals/ContextObj';

interface AppHeaderComponentProps {
  publisherName: string;
}

const AppHeaderComponent = (props: AppHeaderComponentProps) => {
  const { currentTab, updateCurrentTab } = useContext(appContext);
  const { userAccessLevel, updateUserAccessLevel } = useContext(appContext);

  const logout = () => {
    localStorage.setItem('data', '');

    window.location.reload();
  };

  return (
    <div className="app-header-container">
      <div className="top-container">
        <div className="logo-slogen-container">
          <div className="logo-container">
            <Image src="/assets/images/logo_amobeez.png" />
          </div>
          <div className="slogen">{props.publisherName} Admin Interface</div>
        </div>
        <div className="logout-button">
          <button onClick={logout}>Logout</button>
        </div>
      </div>

      <div className="menu-container">
        <Segment inverted>
          {props.publisherName === 'Amobeez Affiliate' && (
            <Menu inverted pointing secondary>
              {/* <Menu.Item
                            name={MENU_TABS.CREATE_COMPARISON_PAGE}
                            active={currentTab === MENU_TABS.CREATE_COMPARISON_PAGE}
                            onClick={() => updateCurrentTab(MENU_TABS.CREATE_COMPARISON_PAGE)}
                        /> */}
              {userAccessLevel === ACCESS_LEVEL.ADMIN && (
                <Menu.Item
                  name={MENU_TABS.KEYWORDS_MANAGEMENT}
                  active={currentTab === MENU_TABS.KEYWORDS_MANAGEMENT}
                  onClick={() => updateCurrentTab(MENU_TABS.KEYWORDS_MANAGEMENT)}
                />
              )}
              <Menu.Item
                name={MENU_TABS.PRODUCTS}
                active={currentTab === MENU_TABS.PRODUCTS}
                onClick={() => updateCurrentTab(MENU_TABS.PRODUCTS)}
              />
              <Menu.Item
                name={MENU_TABS.COMPARISON_PAGE}
                active={currentTab === MENU_TABS.COMPARISON_PAGE}
                onClick={() => updateCurrentTab(MENU_TABS.COMPARISON_PAGE)}
              />
              {userAccessLevel === ACCESS_LEVEL.ADMIN && (
                <Menu.Item
                  name={MENU_TABS.AMAZON_PRODUCTS_DATABASE_OPERATIONS}
                  active={currentTab === MENU_TABS.AMAZON_PRODUCTS_DATABASE_OPERATIONS}
                  onClick={() => updateCurrentTab(MENU_TABS.AMAZON_PRODUCTS_DATABASE_OPERATIONS)}
                />
              )}
              {userAccessLevel === ACCESS_LEVEL.ADMIN && (
                <Menu.Item
                  name={MENU_TABS.AMOBEEZ_PROMPTS}
                  active={currentTab === MENU_TABS.AMOBEEZ_PROMPTS}
                  onClick={() => updateCurrentTab(MENU_TABS.AMOBEEZ_PROMPTS)}
                />
              )}
              {userAccessLevel === ACCESS_LEVEL.ADMIN && (
                <Menu.Item
                  name={MENU_TABS.WEBSITE_SETTINGS}
                  active={currentTab === MENU_TABS.WEBSITE_SETTINGS}
                  onClick={() => updateCurrentTab(MENU_TABS.WEBSITE_SETTINGS)}
                />
              )}
              {userAccessLevel === ACCESS_LEVEL.ADMIN && (
                <Menu.Item
                  name={MENU_TABS.KO}
                  active={currentTab === MENU_TABS.KO}
                  onClick={() => updateCurrentTab(MENU_TABS.KO)}
                />
              )}
            </Menu>
          )}

          {[
            'JPOST',
            'GUIDE TOP REVIEW',
            'LENSVID',
            'CTECH',
            'INN',
            'NEWS_DIRECT',
            'YNET',
            'AMOBEEZ DEMO',
            'YNET NEWS',
            'BUYEREVIEWS',
            'ISRAEL HAYOM',
          ].includes(props?.publisherName) && (
            <Menu inverted pointing secondary>
              <Menu.Item
                name={MENU_TABS.CREATE_COMPARISON_PAGE}
                active={currentTab === MENU_TABS.CREATE_COMPARISON_PAGE}
                onClick={() => updateCurrentTab(MENU_TABS.CREATE_COMPARISON_PAGE)}
              />

              <Menu.Item
                name={MENU_TABS.JPOST_PRODUCTS}
                active={currentTab === MENU_TABS.JPOST_PRODUCTS}
                onClick={() => updateCurrentTab(MENU_TABS.JPOST_PRODUCTS)}
              />
              <Menu.Item
                name={MENU_TABS.JPOST_COMPARISON_PAGE}
                active={currentTab === MENU_TABS.JPOST_COMPARISON_PAGE}
                onClick={() => updateCurrentTab(MENU_TABS.JPOST_COMPARISON_PAGE)}
              />

              {/* <Menu.Item
                            name={MENU_TABS.JPOST_MANUAL_EDITOR}
                            active={currentTab === MENU_TABS.JPOST_MANUAL_EDITOR}
                            onClick={() => updateCurrentTab(MENU_TABS.JPOST_MANUAL_EDITOR)}
                        /> */}

              {/*  {userAccessLevel === ACCESS_LEVEL.ADMIN &&
                            <Menu.Item
                                name={MENU_TABS.JPOST_PROMPTS}
                                active={currentTab === MENU_TABS.JPOST_PROMPTS}
                                onClick={() => updateCurrentTab(MENU_TABS.JPOST_PROMPTS)}
                            />
                        } */}

              <Menu.Item
                name={MENU_TABS.EDIT_ARTICLE}
                active={currentTab === MENU_TABS.EDIT_ARTICLE}
                onClick={() => updateCurrentTab(MENU_TABS.EDIT_ARTICLE)}
              />

              {/* {userAccessLevel === ACCESS_LEVEL.ADMIN &&
                            <Menu.Item
                                name={MENU_TABS.JPOST_AMAZON_REPORTS}
                                active={currentTab === MENU_TABS.JPOST_AMAZON_REPORTS}
                                onClick={() => updateCurrentTab(MENU_TABS.JPOST_AMAZON_REPORTS)}
                            />
                        } */}

              {userAccessLevel === ACCESS_LEVEL.ADMIN && props?.publisherName === 'GUIDE TOP REVIEW' && (
                <Menu.Item
                  name={MENU_TABS.GTR_CAMPAIGN_REPORTS}
                  active={currentTab === MENU_TABS.GTR_CAMPAIGN_REPORTS}
                  onClick={() => updateCurrentTab(MENU_TABS.GTR_CAMPAIGN_REPORTS)}
                />
              )}

              {['NOT IN USE'].includes(props?.publisherName) && userAccessLevel === ACCESS_LEVEL.ADMIN && (
                <Menu.Item
                  name={MENU_TABS.DUPLICATE_ARTICLE}
                  active={currentTab === MENU_TABS.DUPLICATE_ARTICLE}
                  onClick={() => updateCurrentTab(MENU_TABS.DUPLICATE_ARTICLE)}
                />
              )}

              {['GUIDE TOP REVIEW'].includes(props?.publisherName) &&
                userAccessLevel === ACCESS_LEVEL.ADMIN &&
                false && (
                  <Menu.Item
                    name={MENU_TABS.VIDEO_EDITOR}
                    active={currentTab === MENU_TABS.VIDEO_EDITOR}
                    onClick={() => updateCurrentTab(MENU_TABS.VIDEO_EDITOR)}
                  />
                )}

              {/* <Menu.Item
                            name={MENU_TABS.AMAZON_PRODUCTS_DATABASE_OPERATIONS}
                            active={currentTab === MENU_TABS.AMAZON_PRODUCTS_DATABASE_OPERATIONS}
                            onClick={() => updateCurrentTab(MENU_TABS.AMAZON_PRODUCTS_DATABASE_OPERATIONS)}
                        />
                        <Menu.Item
                            name={MENU_TABS.AMOBEEZ_PROMPTS}
                            active={currentTab === MENU_TABS.AMOBEEZ_PROMPTS}
                            onClick={() => updateCurrentTab(MENU_TABS.AMOBEEZ_PROMPTS)}
                        />
                        <Menu.Item
                            name={MENU_TABS.WEBSITE_SETTINGS}
                            active={currentTab === MENU_TABS.WEBSITE_SETTINGS}
                            onClick={() => updateCurrentTab(MENU_TABS.WEBSITE_SETTINGS)}
                        /> */}
            </Menu>
          )}
        </Segment>
      </div>
    </div>
  );
};

export default AppHeaderComponent;
