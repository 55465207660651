import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Input, Image, Form, TextArea, Popup } from 'semantic-ui-react';
import './EditProductComponent.scss';
import { appContext } from '../../App';
import { generateJpostMiniProductDescription, generateJpostProductDisplayName, generateJpostProductProsAndCons, generateJpostProductTitle, updateProduct } from '../../Helpers/api';
import { AmazonProduct, Product } from '../../Modals/ContextObj';


interface EditProductComponentProps {
    selectedWebsiteId: number,
    product: Product,
    amazonData: AmazonProduct,
    keyword: string
}

const EditProductComponent = (props: EditProductComponentProps) => {

    const { setIsLoading } = useContext(appContext);

    const [displayName, setDisplayName] = useState(props.product.displayName || '');
    const [title, setTitle] = useState(props.product.title || '');
    const [pros, setPros] = useState(props.product.pros || '');
    const [prosEdited, setProsEdited] = useState(props.product.prosEdited || '');
    const [cons, setCons] = useState(props.product.cons || '');
    const [consEdited, setConsEdited] = useState(props.product.consEdited || '');
    const [miniProductDescription, setMiniProductDescription] = useState(props.product.miniProductDescription || '');
    const [miniProductDescriptionEdited, setMiniProductDescriptionEdited] = useState(props.product.miniProductDescriptionEdited || '');
    const [position, setPosition] = useState(props.product.position >= 0 ? props.product.position : 10);

    const [asin, setAsin] = useState(props.product.asin || '');
    const [affiliateType, setAffiliateType] = useState(props.amazonData.affiliateType || '');
    const [affiliateLink, setAffiliateLink] = useState(props.amazonData.affiliateLink || '');
    const [affiliateMerchant, setAffiliateMerchant] = useState(props.amazonData.affiliateMerchant || '');
    const [image1, setImage1] = useState(props.amazonData.image1 || '');
    const [price, setPrice] = useState(props.amazonData.price || 0);
    const [rating, setRating] = useState(props.amazonData.rating || 0);
    const [link] = useState(props.amazonData.link || '');
    const [amazonTitle] = useState(props.amazonData.title || '');
    const [brand] = useState(props.amazonData.brand || '');
    const [lastSubCategory] = useState(props.amazonData.lastSubCategory || '');

    const [keyword] = useState(props.keyword || '');

    const updateProductData = async () => {
        if (props.selectedWebsiteId !== -1) {

            const payload = {
                asin,
                displayName,
                title,
                pros,
                prosEdited,
                cons,
                consEdited,
                miniProductDescription,
                miniProductDescriptionEdited,
                image1,
                price,
                rating,
                affiliateType,
                affiliateLink,
                affiliateMerchant,
                websiteId: props.selectedWebsiteId
            }

            const res = await updateProduct(payload);

            if (res === true) {
                toast.success('Updated Successfully!');
            } else {
                toast.error('Something went wrong..')
            }
        } else {
            toast.error('Must select website before saving data.')
        }

    }


    const regenerateJpostProductDisplayName = async () => {
        setIsLoading(true);
        const res = await generateJpostProductDisplayName({ link, amazonTitle, brand });
        setDisplayName(res.displayName);
        setIsLoading(false);
    }

    const regenerateJpostProductTitle = async () => {
        setIsLoading(true);
        const res = await generateJpostProductTitle({ lastCategory: lastSubCategory, keyword, productName: displayName });
        setDisplayName(res.displayName);
        setIsLoading(false);
    }

    const regenerateJpostProductProsAndCons = async () => {
        setIsLoading(true);
        const res = await generateJpostProductProsAndCons({ link, amazonTitle });
        setPros(res.pros);
        setCons(res.cons);
        setIsLoading(false);
    }

    const regenerateJpostMiniProductDescription = async () => {
        setIsLoading(true);
        const res = await generateJpostMiniProductDescription({ link, amazonTitle, position, keyword });
        setMiniProductDescription(res.miniProductDescription);
        setIsLoading(false);
    }

    return (
        <div className='edit-prodcut-container'>

            <Form>

                <Form.Group widths='equal'>
                    <Form.Field
                        id='form-input-control-asin'
                        control={Input}
                        label='ASIN'
                        placeholder='ASIN'
                        value={asin}
                        onChange={(e: any) => { setAsin(e.target.value) }}
                    />
                    <Form.Field
                        id='form-input-control-star-ranking'
                        control={Input}
                        label='Star Ranking'
                        placeholder='Star Ranking'
                        value={rating}
                        onChange={(e: any) => { setRating(e.target.value) }}
                    />
                    <Form.Field
                        id='form-input-control-price'
                        control={Input}
                        label='Price'
                        placeholder='Price'
                        value={price}
                        onChange={(e: any) => { setPrice(e.target.value) }}
                    />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field
                        id='form-input-control-affiliate-type'
                        control={Input}
                        label='Affiliate Type'
                        placeholder='Affiliate Type'
                        value={affiliateType}
                        onChange={(e: any) => { setAffiliateType(e.target.value) }}
                    />
                    <Form.Field
                        id='form-input-control-affiliate-link'
                        control={Input}
                        label='Affiliate Link'
                        placeholder='Affiliate Link'
                        value={affiliateLink}
                        onChange={(e: any) => { setAffiliateLink(e.target.value) }}
                    />
                    <Form.Field
                        id='form-input-control-affiliate-merchant'
                        control={Input}
                        label='Affiliate Merchant'
                        placeholder='Affiliate Merchant'
                        value={affiliateMerchant}
                        onChange={(e: any) => { setAffiliateMerchant(e.target.value) }}
                    />
                </Form.Group>

                <div className='generate-part'>
                    <Popup
                        trigger={
                            <Button icon='refresh' onClick={() => regenerateJpostProductDisplayName()} />
                        }
                        content='Re-Generate Display Name'
                        inverted
                    />
                </div>

                <Form.Field
                    id='form-textarea-control-product-display-name'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={displayName}
                    onChange={(e: any) => { setDisplayName(e.target.value) }}
                />

                <div className='generate-part'>
                    <Popup
                        trigger={
                            <Button icon='refresh' onClick={() => regenerateJpostProductTitle()} />
                        }
                        content='Re-Generate Title'
                        inverted
                    />
                </div>

                <Form.Field
                    id='form-textarea-control-product-title'
                    control={TextArea}
                    label='Title'
                    placeholder='Title'
                    value={title}
                    onChange={(e: any) => { setTitle(e.target.value) }}
                />

                <div className='generate-part'>
                    <Popup
                        trigger={
                            <Button icon='refresh' onClick={() => regenerateJpostProductProsAndCons()} />
                        }
                        content='Re-Generate Pros and Cons'
                        inverted
                    />
                </div>

                <Form.Field
                    id='form-input-control-pros'
                    control={Input}
                    label={<div style={{ display: 'flex', alignItems: 'baseline'}}>🪄 Pros<p style={{fontWeight: '300', fontSize: '12px', color: 'gray', paddingLeft: '5px'}}>( Suggessted )</p></div>}
                    placeholder='Pros - Suggessted'
                    value={pros}
                    onChange={(e: any) => { setPros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-edited'
                    control={Input}
                    label={<div style={{ display: 'flex', alignItems: 'baseline'}}>📝 Pros<p style={{fontWeight: '300', fontSize: '12px', color: 'gray', paddingLeft: '5px'}}>( Edited )</p></div>}
                    placeholder='Pros - Edited'
                    value={prosEdited}
                    onChange={(e: any) => { setProsEdited(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons'
                    control={Input}
                    label={<div style={{ display: 'flex', alignItems: 'baseline'}}>🪄 Cons<p style={{fontWeight: '300', fontSize: '12px', color: 'gray', paddingLeft: '5px'}}>( Suggessted )</p></div>}
                    placeholder='Cons - Suggessted'
                    value={cons}
                    onChange={(e: any) => { setCons(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-edited'
                    control={Input}
                    label={<div style={{ display: 'flex', alignItems: 'baseline'}}>📝 Cons<p style={{fontWeight: '300', fontSize: '12px', color: 'gray', paddingLeft: '5px'}}>( Edited )</p></div>}
                    placeholder='Cons - Edited'
                    value={consEdited}
                    onChange={(e: any) => { setConsEdited(e.target.value) }}
                />

                <div className='generate-part'>
                    <Popup
                        trigger={
                            <Button icon='refresh' onClick={() => regenerateJpostMiniProductDescription()} />
                        }
                        content='Re-Generate Product Description'
                        inverted
                    />
                </div>

                <Form.Field
                    id='form-textarea-control-product-mini-description'
                    control={TextArea}
                    label={<div style={{ display: 'flex', alignItems: 'baseline'}}>🪄 Product Description<p style={{fontWeight: '300', fontSize: '12px', color: 'gray', paddingLeft: '5px'}}>( Suggessted )</p></div>}
                    placeholder='Product Description - Suggessted'
                    value={miniProductDescription}
                    onChange={(e: any) => { setMiniProductDescription(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-edited'
                    control={TextArea}
                    label={<div style={{ display: 'flex', alignItems: 'baseline'}}>📝 Product Description<p style={{fontWeight: '300', fontSize: '12px', color: 'gray', paddingLeft: '5px'}}>( Edited )</p></div>}
                    placeholder='Product Description - Edited'
                    value={miniProductDescriptionEdited}
                    onChange={(e: any) => { setMiniProductDescriptionEdited(e.target.value) }}
                />

                <div className='img-container-image-url'>
                    <Form.Field
                        id='form-input-control-image-url'
                        control={Input}
                        label='Image Url'
                        placeholder='Image Url'
                        value={image1}
                        onChange={(e: any) => { setImage1(e.target.value) }}
                    />
                    <Image
                        src={image1}
                        as='a'
                        size='small'
                        href={image1}
                        target='_blank'
                    />
                </div>

                <div className='update-btn'>
                    <Button
                        content='Update Product Data'
                        disabled={asin === ''}
                        onClick={() => updateProductData()}
                    />
                </div>
            </Form>
        </div>
    );

}

export default EditProductComponent;