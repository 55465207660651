import axios from "axios";
import { Category } from "../Modals/ContextObj";

const backendApi = axios.create({
  //baseURL: 'http://localhost:8000', //PROD
  baseURL: 'https://auth.oom-panel.com',
  withCredentials: true,
  timeout: 300000,
  // headers: {
  //   'Content-Security-Policy': 'upgrade-insecure-requests'
  // }
});


const DatabaseApi = axios.create({
  baseURL: 'https://api.oom-panel.com',
  withCredentials: true,
  timeout: 300000,
});

const DatabaseGTRApi = axios.create({
  baseURL: 'https://dbgtr.oom-panel.com',
  withCredentials: true,
  timeout: 300000,
});

const CacheApi = axios.create({
  baseURL: 'http://143.198.28.205:8000', //PROD
  withCredentials: true
})

const fetchWithRetry = async (url: string, method: 'GET' | 'POST', payload?: any) => {
  let res;
  try {
    if (method === 'GET') {
      res = await DatabaseApi.get(url);
    } else if (method === 'POST') {
      res = await DatabaseApi.post(url, payload);
    }
    if (res?.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    try {
      if (method === 'GET') {
        res = await DatabaseApi.get(url);
      } else if (method === 'POST') {
        res = await DatabaseApi.post(url, payload);
      }
      if (res?.status === 200) {
        return res?.data?.data;
      } else {
        return [];
      }
    }
    catch (error) {
      return [];
    }
  }
}

const fetchWithRetryBatches = async (url: string, method: 'GET' | 'POST', payload?: any) => {

  let res;
  try {

    if (method === 'GET') {
      res = await DatabaseApi.get(url);
      if (res?.status === 200) {
        return res?.data?.data;
      } else {
        return [];
      }
    }

    let continueLoop = true;
    const limit = 5000;
    let counter = 0;
    let data = [];
    while (continueLoop) {

      if (method === 'POST') {

        payload.offset = (limit * counter);
        payload.limit = limit;

        res = await fetchWithRetry(url, method, payload);

        if (res) {
          data.push(...res);
        }
        counter++;
        if (res.length < limit) {
          continueLoop = false;
        }
      }
    }

    return data;

  }
  catch (e: any) {

    console.log(e?.message ? e.message : e)
    return [];
  }

}

export const addTaskToQueueJob = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/queueJob/addTaskToQueueJob', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const getSuggestPossibleComparisonPagesFromDB = async () => {
  try {
    const res = await backendApi.get('/api/generate/suggestPossibleComparisonPages');
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getSuggestAffiliatorsPossibleComparisonPagesFromDB = async () => {
  try {
    const res = await backendApi.get('/api/generate/getSuggestAffiliatorsPossibleComparisonPagesFromDB');
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const scrapeBulkProducts = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/amazon/scrapeBulkProducts', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const scrapeAndAddNewAsinsToTrendingProduct = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/amazon/scrapeAndAddNewAsinsToTrendingProduct', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const duplicateArticaleByComparisonPageData = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/duplicateArticaleByComparisonPageData', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateCompleteComparisonPagesInBulk = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/generateCompleteComparisonPagesInBulk', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateProductProsAndCons = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/generateProductProsAndCons', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateProductBottomLineAndBestUse = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/generateProductBottomLineAndBestUse', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateProductDescription = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/generateProductDescription', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateProductDisplayName = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/generateProductDisplayName', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const regenerateForAll = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateAll/regenerateForAll', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateTitle = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/generateTitle', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateIntroContent = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/generateIntroContent', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateConclusion = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/generateConclusion', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateBuyersGuideAndFaq = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/generateBuyersGuideAndFaq', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}



// ############################ J P O S T ################################## //

export const generateJpostProductDisplayName = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostProductDisplayName', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateJpostProductTitle = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostProductTitle', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostProductProsAndCons = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostProductProsAndCons', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostProductBottomLineAndBestUse = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostProductBottomLineAndBestUse', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostProductDescription = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostProductDescription', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostMiniProductDescription = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostMiniProductDescription', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostWhatWeLike = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostWhatWeLike', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostWhatWeDidntLike = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostWhatWeDidntLike', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostProductConclusion = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostProductConclusion', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateJpostProductMainSpecs = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostProductMainSpecs', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostTitle = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostTitle', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostIntroContent = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostIntroContent', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostBuyersGuide = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostBuyersGuide', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const generateJpostFaq = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostFaq', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateJpostTeaser = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostTeaser', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}



export const generateJpostConclusion = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostConclusion', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateJpostCoverImage = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostCoverImage', payload, { timeout: 900000 });
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}



export const getJpostHtmlFilesByComparisonPage = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/generateJpost/getJpostHtmlFilesByComparisonPage', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const validateArticleBeforePublish = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/article/validateArticleBeforePublish', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const publishOrUpdateComparisonPage = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/jpostApi/publishOrUpdateComparisonPage', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const updateCoverImageInWP = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/generateToWpCms/updateCoverImage', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getCtechYnetFilesByComparisonPage = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/ctechYnet/getCtechYnetFilesByComparisonPage', payload, { responseType: 'arraybuffer' });
    if (res.status === 200) {
      return res;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const publishNewOrUpdateComparisonPageToWordpress = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/generateWordpress/publishNewOrUpdateComparisonPageToWordpress', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const publishNewOrUpdateComparisonPageToWpCms = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/generateToWpCms/publishNewOrUpdateComparisonPageToWpCms', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const publishNewOrUpdateComparisonPageToINN = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/inn/publishNewOrUpdateComparisonPageToINN', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const publishNewOrUpdateComparisonPageToNewsDirect = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/newsDirect/publishNewOrUpdateComparisonPageToNewsDirect', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const createSingleComparisonPageByAsin = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/generateJpost/createSingleComparisonPageByAsin', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getJpostSingleProductHtmlFileByComparisonPageId = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/generateJpost/getJpostSingleProductHtmlFileByComparisonPageId', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getJpostDealsHtmlFileByComparisonPageId = async (payload: any) => {

  try {
    const res = await backendApi.post('/api/generateJpost/getJpostDealsHtmlFileByComparisonPageId', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getCampaignsNameAndId = async () => {
  try {
    const res = await DatabaseApi.post('/api/amazonReportOrders/getCampaignsNameAndId', {});
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getAllProductsDataFromDB = async () => {
  try {
    const res = await fetchWithRetryBatches(`/api/amazonProduct/getAllAmazonProducts`, 'POST', {});
    return res;
  }
  catch (error) {
    return [];
  }
}

export const getAllTrendingProducts = async () => {
  try {
    const res = await DatabaseApi.get('/api/trendingProduct/getAllTrendingProducts');
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

/* export const generateProductData = async (asin: string, categoryId: number) => {
  try {
    const payload = { asin, categoryId };
    const res = await backendApi.post('/api/generate/generateProductByAsin', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
} */

export const generateComparisonPageData = async (category: Category) => {
  try {
    const payload = { category };
    const res = await backendApi.post('/api/generate/generateComparisonPageData', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getAllWebsites = async () => {
  try {
    const res = await DatabaseApi.get('/api/website/getAllWebsites');
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const getAllBaseCategories = async () => {
  try {
    const res = await DatabaseApi.get('/api/category/getAllBaseCategories');
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getChildCategoriesByParentId = async (id: number) => {
  try {
    const res = await DatabaseApi.get(`/api/category/getChildCategoriesByParentId/${id}`);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getComparisonPageByKeyword = async (keyword: string, websiteId: number, version: number) => {
  try {
    const payload = {
      keyword,
      websiteId,
      version
    };

    const res = await DatabaseApi.post(`/api/comparisonPage/getComparisonPageByKeyword`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getComparisonPageByPath = async (path: string, websiteId: number) => {
  try {
    const payload = {
      path,
      websiteId
    };

    const res = await DatabaseApi.post(`/api/comparisonPage/getComparisonPageByPath`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getComparisonPagesByWebsiteId = async (websiteId: number, limit?: number) => {
  try {
    const payload = {
      websiteId,
      limit
    };

    if (limit) {
      const res = await DatabaseApi.post(`/api/comparisonPage/getComparisonPagesByWebsiteId`, payload);
      if (res.status === 200) {
        return res?.data?.data;
      } else {
        return [];
      }
    }
    else {
      const res = await fetchWithRetryBatches(`/api/comparisonPage/getComparisonPagesByWebsiteId`, 'POST', payload);
      return res;
    }
  }
  catch (error) {
    return [];
  }
}

export const updateComparisonPage = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/comparisonPage/updateComparisonPage`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const removeByComparisonPagePath = async (payload: any) => {
  try {
    const res = await DatabaseGTRApi.post(`/api/cache/removeByComparisonPagePath`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const updateWebsite = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/website/updateWebsite`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}


export const getProductByAsin = async (asin: string, websiteId: number) => {
  try {
    const payload = {
      asin,
      websiteId
    }

    const res = await DatabaseApi.post(`/api/product/getProductByAsin`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const updateProduct = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/product/updateProduct`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const removedCacheKeys = async (payload: any) => {
  try {
    const res = await CacheApi.post(`/cache/api/removedCacheKeys`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const getAllPromptsByTagHandler = async (payload: any) => {
  try {
    const res = await DatabaseApi.post('/api/prompt/getAllPromptsByTag', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const updatePromptByTagAndName = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/prompt/updatePromptByTagAndName`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const downloadSelectedImageByIndex = async (payload: any) => {
  try {
    const res = await backendApi.post(`/api/images/downloadSelectedImageByIndex`, payload, { responseType: 'blob' });
    if (res.status === 200) {
      return res;
    } else {
      return '';
    }
  }
  catch (error) {
    return '';
  }
}

export const filterKeywordsToRelevantItemsOnly = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/chat/filterKeywordsToRelevantItemsOnly', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getAllKeywords = async (payload: any) => {
  try {
    const res = await fetchWithRetryBatches(`/api/keyword/getAllKeywords`, 'POST', payload);
    return res;
  }
  catch (error) {
    return [];
  }
}

export const addKeywordToWebsiteId = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/keyword/addKeywordToWebsiteId`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const deleteKeywordById = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/keyword/deleteKeywordById`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const getAllKeywordsManagement = async () => {
  try {
    const res = await DatabaseApi.get(`/api/keywordsManagement/getAllKeywordsManagement`);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const updateKeywordsManagementByWebsiteId = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/keywordsManagement/updateKeywordsManagementByWebsiteId`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const getAllUsedKeywordsByWebsiteId = async (payload: any) => {
  try {
    const res = await fetchWithRetryBatches(`/api/keyword/getAllUsedKeywordsByWebsiteId`, 'POST', payload);
    return res;
  }
  catch (error) {
    return [];
  }
}

export const addAmazonReportItems = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/amazonReport/addAmazonReportItems`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const getAmazonReportByDates = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/amazonReport/getAmazonReportByDates`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const generatePaidCampaignsReport = async (payload: any) => {
  // not in use
  return null
  try {
    const res = await backendApi.post(`/api/amazonReport/generatePaidCampaignsReport`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const addProductToCreatorConnection = async (payload: any) => {
  try {
    const res = await backendApi.post(`/api/creatorConnection/addProductToCreatorConnection`, payload);
    if (res.status === 200) {
      return res?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const generateOptimizeArticlesReport = async (payload: any) => {
  try {
    const res = await backendApi.post(`/api/amazonReport/generateOptimizeArticlesReport`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const getAmazonReportWithAdditionals = async (payload: any) => {
  try {
    const res = await backendApi.post(`/api/amazonReport/getAmazonReportWithAdditionals`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const crawlProductsMedia = async (payload: any) => {
  try {
    const res = await backendApi.post(`/api/video/crawlProductsMedia`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}

export const getComparisonPagesByAsinExistence = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/product/getComparisonPagesByAsinExistence`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return [];
  }
}


export const suggestJpostPossibleComparisonPagesFromDB = async () => {
  try {
    const res = await backendApi.get('/api/generateJpost/suggestJpostPossibleComparisonPages');
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateJpostCompleteComparisonPagesInBulk = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostCompleteComparisonPagesInBulk', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateTrendingCompleteComparisonPagesInBulk = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateTrendingCompleteComparisonPagesInBulk', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateJpostCompleteComparisonPagesInBulkFromKeywords = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostCompleteComparisonPagesInBulkFromKeywords', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const generateJpostCompleteComparisonPagesInBulkFromLongTailKeywords = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/generateJpostCompleteComparisonPagesInBulkFromLongTailKeywords', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const addNewProductsToExistingJpostComparisonId = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generateJpost/addNewProductsToExistingJpostComparisonId', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const addNewProductsToExistingComparisonId = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/generate/addNewProductsToExistingComparisonId', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}


export const rewriteComparisonPageProductsById = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/article/rewriteComparisonPageProductsById', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getAllAmobeezCatalogProductsByCategories = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/article/getAffiliateProductsByCategories', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getAllAmobeezCatalogProductsByCategoriesFlatPath = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/article/getAllAmobeezCatalogProductsByCategoriesFlatPath', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const deleteComparisonPageById = async (id: number) => {
  try {
    const res = await DatabaseApi.delete(`/api/comparisonPage/deleteComparisonPageById/${id}`);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const createManualVersionByComparisonPageId = async (id: number) => {
  try {
    const res = await DatabaseApi.post(`/api/comparisonPage/createManualVersionByComparisonPageId`, { id });
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const createAnalystVersionByComparisonPageId = async (id: number) => {
  try {
    const res = await DatabaseApi.post(`/api/comparisonPage/createAnalystVersionByComparisonPageId`, { id });
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const searchArticleForAdminPanel = async (payload: any) => {
  try {
    const res = await DatabaseApi.post(`/api/website/searchArticleForAdminPanel`, payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const getTopAffiliateProducts = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/article/getTopAffiliateProducts', payload);
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

// ############################ USERS ################################## //

export const validateUser = async (payload: any) => {
  try {
    const res = await backendApi.post('/api/user/validateUser', payload);
    if (res?.status === 200) {
      return res?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}

export const verifyUser = async (): Promise<any> => {
  try {

    const encryptedData = localStorage.getItem('data');
    if (encryptedData === null || encryptedData === '') {
      return null;
    }
    const res = await backendApi.post('/api/user/verifyUserLoginToken', {
      encryptedData: encryptedData ? encryptedData : ''
    });
    if (res?.status === 200) {

      return res?.data;
    } else {
      return null;
    }
  }
  catch (error) {
    return null;
  }
}

export const getResourcesOptionsFromDB = async (selectedWebsiteId : number) => {
  try {

    const res = await backendApi.get('/api/article/getResourcesOptions', { params: { websiteId: selectedWebsiteId } });
    if (res.status === 200) {
      return res?.data?.data;
    } else {
      return [];
    }
  }
  catch (error) {
    return [];
  }
}