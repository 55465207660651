import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Dropdown, DropdownProps, Input, Image, Form, TextArea } from 'semantic-ui-react';
import './ComparisonEditComponent.scss';
import { appContext } from '../../App';
import { addNewProductsToExistingComparisonId, deleteComparisonPageById, generateBuyersGuideAndFaq, generateConclusion, generateIntroContent, generateTitle, getComparisonPageByPath, removedCacheKeys, updateComparisonPage, updateProduct } from '../../Helpers/api';
import { AmazonProduct, ComparisonPage, ComparisonPageResp, Product } from '../../Modals/ContextObj';
import { DropDownObj } from '../../Helpers/customTypes';
import { getPathFromCategories, randomGradeByPosition } from '../../Helpers/consts';
import DraggableList from '../DraggableList/DraggableList';

// TOO OLD FILE

interface ComparisonEditComponentProps {
    domain: string,
    selectedWebsiteId: number,
    urlsDropDown: DropDownObj[],
    comparisonPagesByWebsite: ComparisonPage[]
}

const ComparisonEditComponent = (props: ComparisonEditComponentProps) => {

    const { setIsLoading } = useContext(appContext);

    const [id, setId] = useState(-1);
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [writterName, setWritterName] = useState('');
    const [introContent, setIntroContent] = useState('');
    const [conclusion, setConclusion] = useState('');
    const [buyersGuideAndFaq, setBuyersGuideAndFaq] = useState('');
    const [categoryImage, setCategoryImage] = useState('');
    const [category3, setCategory3] = useState('');

    const [urlsDropDown, setUrlsDropDown] = useState<DropDownObj[]>(props.urlsDropDown);
    const [selectedUrl, setSelectedUrl] = useState('');
    const [linksForGenerate, setLinksForGenerate] = useState('');
    const [originalProducts, setOriginalProducts] = useState<{ product: Product; amazonData: AmazonProduct; keyword?: string | undefined; }[]>([]);
    const [finalHeaders, setFinalHeaders] = useState<string[]>([]);

    const [asinToRemove, setAsinToRemove] = useState('');
    const [asinToAdd, setAsinToAdd] = useState('');


    const getComparisonDataFromDB = async (selectedPath: string) => {
        setIsLoading(true);
        const res: ComparisonPageResp = await getComparisonPageByPath(selectedPath, props.selectedWebsiteId);
        if (res?.comparisonPage) {
            setId(res.comparisonPage.id);
            setTitle(res.comparisonPage.title);
            setDate(res.comparisonPage.date);
            setWritterName(res.comparisonPage.writterName);
            setIntroContent(res.comparisonPage.introContent);
            setConclusion(res.comparisonPage.conclusion);
            setBuyersGuideAndFaq(res.comparisonPage.buyersGuideAndFaq);
            setCategoryImage(res.comparisonPage.categoryImage);
            setCategory3(res.comparisonPage.category3);

            setLinksForGenerate(res.products.map((p: any) => p.amazonData.link).join(', '));

            setOriginalProducts(res?.products.filter(p => p.product.itemType === 'REGULAR').sort((a, b) => a.product.position - b.product.position));

        } else {
            toast.error('Comparison Data not found for this category!');
        }
        setIsLoading(false);
    }

    const regenerateData = async () => {
        try {
            setIsLoading(true);

            // await generateComparisonPageData(selectedUrl);
            setIsLoading(false);
            toast.success('Comparison data generated successfully!')
        } catch (e) {
            setIsLoading(false);
            toast.error('Failed to generated Comparison Page');
        }
    }

    const reloadProducts = async () => {
        //TODO
    }

    const deleteTheEntireComparisonPage = async () => {
        setIsLoading(true);

        await deleteComparisonPageById(id);

        toast.success('Deleted successfully!')
        setIsLoading(false);
    }

    const removeAsinFromComparisonPage = async () => {
        const found = originalProducts.find(p => p.product.asin === asinToRemove);

        if (found) {
            setIsLoading(true);

            const payload = {
                asin: asinToRemove,
                websiteId: props.selectedWebsiteId,
                comparisonPageId: id,
                shouldDelete: true
            }

            await updateProduct(payload);
            setIsLoading(false);
            await reloadProducts();
            toast.success('Product removed successfully!')
        } else {
            toast.error('This ASIN doesn`t exist in this Comparison Page')
        }
    }

    const addAsinToComparisonPage = async () => {
        const found = originalProducts.find(p => p.product.asin === asinToAdd);

        if (!found) {
            setIsLoading(true);

            const payload = {
                asins: [asinToAdd],
                websiteId: props.selectedWebsiteId,
                comparisonPageId: id
            }

            await addNewProductsToExistingComparisonId(payload);
            setIsLoading(false);
            await reloadProducts();
            toast.success('Product Added successfully!')
        } else {
            toast.error('This ASIN already exist in this Comparison Page')
        }
    }

    const saveNewProductsOrder = async () => {

        setIsLoading(true);

        for (let i = 0; i < finalHeaders.length; i++) {

            const product = originalProducts.find(p => p.product.asin === finalHeaders[i]);

            const payload = {
                asin: product?.product.asin,
                position: i,
                grade: randomGradeByPosition(i),
                websiteId: props.selectedWebsiteId
            }
            await updateProduct(payload);
        }

        setIsLoading(false);
        toast.success('New product`s order saved successfully!')
    }

    const updateComparisonPageData = async () => {

        const payload = {
            id,
            title,
            date,
            writterName,
            introContent,
            conclusion,
            categoryImage,
            buyersGuideAndFaq
        }

        const res = await updateComparisonPage(payload);
        /*   await removedCacheKeys({
              cacheKeys: [`/api/website/getWebsiteByDomain::domain:${props.domain}`,
              `/api/categoriesPage/getCategoriesPageByPath::path:${selectedUrl}::websiteId${props.selectedWebsiteId}`,
              `/api/comparisonPage/getComparisonPageByPath::path:${selectedUrl}::websiteId${props.selectedWebsiteId}`]
          }); */

        if (res?.length > 0) {
            toast.success('Updated Successfully!');
        } else {
            toast.error('Something went wrong..')
        }
    }

    const handleUrlChange = async (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const value = urlsDropDown.find((o: DropDownObj) => o.value === data.value)?.value;

        if (value) {
            const obj = props.comparisonPagesByWebsite.find(cp => getPathFromCategories(cp) === value);
            if (obj) {
                setSelectedUrl(value);
                await getComparisonDataFromDB(value);
            }
        }
    }

    const regenerateTitle = async () => {
        setIsLoading(true);
        const res = await generateTitle({ links: linksForGenerate, lastCategory: category3 });
        setTitle(res.title);
        setIsLoading(false);
    }

    const regenerateIntroContent = async () => {
        setIsLoading(true);
        const res = await generateIntroContent({ links: linksForGenerate, lastCategory: category3 });
        setIntroContent(res.introContent);
        setIsLoading(false);
    }

    const regenerateConclusion = async () => {
        setIsLoading(true);
        const res = await generateConclusion({ links: linksForGenerate, lastCategory: category3 });
        setConclusion(res.conclusion);
        setIsLoading(false);
    }

    const regenerateBuyersGuideAndFaq = async () => {
        setIsLoading(true);
        const res = await generateBuyersGuideAndFaq({ lastCategory: category3 });
        setBuyersGuideAndFaq(res.buyersGuideAndFaq);
        setIsLoading(false);
    }


    const headersOrderChanged = (newList: any[]) => {
        setFinalHeaders(newList);
    }


    return (
        <div className='comparison-edit-container'>

            <div className='title'>Select Comparison Page Url: </div>
            <Dropdown
                search
                selection
                options={urlsDropDown}
                onChange={handleUrlChange}
                value={selectedUrl}
            />

            <div className='get-comparison'>
                {/* 
                <Button
                    icon='refresh'
                    content='RE-GENERATE DATA'
                    style={{ marginLeft: '300px', backgroundColor: '#ff6d6d', color: 'white' }}
                    onClick={() => regenerateData()} /> */}
            </div>


            <Form>
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Title' onClick={() => regenerateTitle()} />
                </div>
                <Form.Field
                    id='form-input-control-title'
                    control={Input}
                    label='Title'
                    placeholder='Title'
                    value={title}
                    onChange={(e: any) => { setTitle(e.target.value) }}
                />

                <Form.Group widths='equal'>
                    <Form.Field
                        id='form-input-control-dateAndName'
                        control={Input}
                        label='Date'
                        placeholder='Date'
                        value={date}
                        onChange={(e: any) => { setDate(e.target.value) }}
                    />

                    <Form.Field
                        id='form-input-control-dateAndName'
                        control={Input}
                        label='Writter Name'
                        placeholder='Writter Name'
                        value={writterName}
                        onChange={(e: any) => { setWritterName(e.target.value) }}
                    />
                </Form.Group>

                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Intro Content' onClick={() => regenerateIntroContent()} />
                </div>
                <Form.Field
                    id='form-textarea-control-intro-content'
                    control={TextArea}
                    label='Intro Content'
                    placeholder='Intro Content'
                    value={introContent}
                    onChange={(e: any) => { setIntroContent(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Conclusion' onClick={() => regenerateConclusion()} />
                </div>
                <Form.Field
                    id='form-textarea-control-conclusion'
                    control={TextArea}
                    label='Conclusion'
                    placeholder='Conclusion'
                    value={conclusion}
                    onChange={(e: any) => { setConclusion(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Buyers Guide and FAQ' onClick={() => regenerateBuyersGuideAndFaq()} />
                </div>
                <Form.Field
                    id='form-textarea-control-buyersGuideAndFaq'
                    control={TextArea}
                    label='Buyers Guide and FAQ'
                    placeholder='Buyers Guide and FAQ'
                    value={buyersGuideAndFaq}
                    onChange={(e: any) => { setBuyersGuideAndFaq(e.target.value) }}
                />

                <div className='img-container-image-url'>
                    <Form.Field
                        id='form-input-control-image-url'
                        control={Input}
                        label='Category Image'
                        placeholder='Category Image'
                        value={categoryImage}
                        onChange={(e: any) => { setCategoryImage(e.target.value) }}
                    />
                    <Image
                        src={categoryImage}
                        as='a'
                        href={categoryImage}
                        target='_blank'
                    />
                </div>

                <div className='update-btn'>
                    <Form.Field
                        id='form-button-control-update-btn'
                        control={Button}
                        content='Update Comparison Page Data'
                        disabled={id === -1}
                        onClick={() => updateComparisonPageData()}
                    />
                </div>
            </Form>

            <div className='edit-products-container'>
                <div className='title'><div>Edit Comparison Page Products:</div> <Button
                    icon='trash'
                    content='Remove Comparison Page'
                    onClick={() => deleteTheEntireComparisonPage()} /></div>
                <div className='remove-product-by-asin'>
                    <div className='title-inputs'>Remove Product by ASIN: </div>
                    <Input
                        placeholder='ASIN'
                        value={asinToRemove}
                        onChange={(e) => { setAsinToRemove(e.target.value) }}>
                        <input />
                    </Input>
                    <Button
                        icon='trash'
                        content='Remove'
                        onClick={() => removeAsinFromComparisonPage()} />
                </div>

                <div className='add-product-by-asin'>
                    <div className='title-inputs'>Add Product by ASIN: </div>
                    <Input
                        placeholder='ASIN'
                        value={asinToAdd}
                        onChange={(e) => { setAsinToAdd(e.target.value) }}>
                        <input />
                    </Input>
                    <Button
                        icon='edit'
                        content='Add New'
                        onClick={() => addAsinToComparisonPage()} />
                </div>

                <DraggableList items={originalProducts} headersOrderChanged={headersOrderChanged} websiteId={props.selectedWebsiteId} comparisonPageId={id}></DraggableList>

                <Button
                    icon='save'
                    content='Save New Order'
                    onClick={() => saveNewProductsOrder()} />
            </div>
        </div>
    );

}

export default ComparisonEditComponent;
