import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Dropdown, DropdownProps } from 'semantic-ui-react';
import './DuplicateArticleComponent.scss';
import { appContext } from '../../App';
import { getComparisonPagesByWebsiteId, duplicateArticaleByComparisonPageData, addTaskToQueueJob } from '../../Helpers/api';
import { ComparisonPage } from '../../Modals/ContextObj';
import { DropDownObj } from '../../Helpers/customTypes';
import { WEBSITE_ID, getPathFromCategories, getVersionStringByComparisonPage } from '../../Helpers/consts';


interface DuplicateArticleComponentProps {
    selectedWebsiteId: number,
}

const DuplicateArticleComponent = (props: DuplicateArticleComponentProps) => {

    const { setIsLoading, currentEditComparisonPage } = useContext(appContext);

    const [comparisonPagesByWebsite, setComparisonPagesByWebsite] = useState<ComparisonPage[]>([]);
    const [urlsDropDown, setUrlsDropDown] = useState<DropDownObj[]>([]);
    const [selectedUrl, setSelectedUrl] = useState('');


    useEffect(() => {

        initPage();

    }, [])

    const initPage = async () => {
        setIsLoading(true);
        const comparisonPagesReps: ComparisonPage[] = await getComparisonPagesByWebsiteId(2);
        setComparisonPagesByWebsite(comparisonPagesReps);

        setIsLoading(false);

    }

    useEffect(() => {
        let tmpUrls = comparisonPagesByWebsite.map(c => ({
            key: c.id,
            text: getPathFromCategories(c) + getVersionStringByComparisonPage(c),
            value: getPathFromCategories(c) + getVersionStringByComparisonPage(c),
        }));

        setUrlsDropDown(tmpUrls);

    }, [comparisonPagesByWebsite]);

    const duplicateArticle = async () => {
        try {
            setIsLoading(true);
            const obj = comparisonPagesByWebsite.find(cp => getPathFromCategories(cp) + getVersionStringByComparisonPage(cp) === selectedUrl);
            // await duplicateArticaleByComparisonPageData({ keyword: obj?.keyword, version: obj?.version });
            await addTaskToQueueJob({
                websiteId: props.selectedWebsiteId,
                action: 'duplicateArticaleByComparisonPageData',
                params: { websiteId: props.selectedWebsiteId, keyword: obj?.keyword, version: obj?.version }
            });
            setIsLoading(false);
            toast.success('Comparison data generated successfully!')
        } catch (e) {
            setIsLoading(false);
            toast.error('Failed to generated Comparison Page');
        }
    }

    const handleUrlChange = async (event: React.SyntheticEvent<HTMLElement, Event> | null, data: DropdownProps) => {
        const value = urlsDropDown.find((o: DropDownObj) => o.value === data.value)?.value;

        if (value) {
            const obj = comparisonPagesByWebsite.find(cp => getPathFromCategories(cp) + getVersionStringByComparisonPage(cp) === value);
            if (obj) {
                setSelectedUrl(value);
            }
        }
    }

    return (
        <div className='duplicate-article-container'>

            <div className='title'>Select JPOST Comparison Page: </div>
            <Dropdown
                search
                selection
                options={urlsDropDown}
                onChange={handleUrlChange}
                value={selectedUrl}
            />

            <Button style={{ width: '400px', }}
                icon='save'
                content={'Duplicate Article to' + (props.selectedWebsiteId === WEBSITE_ID.GUIDE_TOP_REVIEW ?
                    ' Guide Top Review'
                    :
                    props.selectedWebsiteId === WEBSITE_ID.LENSVID ?
                        ' Lensvid'
                        :
                        props.selectedWebsiteId === WEBSITE_ID.CTECH ?
                            ' CTECH'
                            :
                            props.selectedWebsiteId === WEBSITE_ID.INN ?
                                ' INN'
                                :
                                props.selectedWebsiteId === WEBSITE_ID.NEWS_DIRECT ?
                                ' News Direct'
                                : ' Ynet')}
                onClick={() => duplicateArticle()} />

        </div>
    );

}

export default DuplicateArticleComponent;
