import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Dropdown,
  DropdownProps,
  Input,
  Icon,
  Image,
  Form,
  TextArea,
  Checkbox,
  Modal,
  ModalHeader,
  ModalContent,
  ModalDescription,
  ModalActions,
  Select,
} from 'semantic-ui-react';
import './JpostComparisonEditComponent.scss';
import { appContext } from '../../App';
import {
  addNewProductsToExistingJpostComparisonId,
  createAnalystVersionByComparisonPageId,
  createManualVersionByComparisonPageId,
  deleteComparisonPageById,
  downloadSelectedImageByIndex,
  generateJpostBuyersGuide,
  generateJpostConclusion,
  generateJpostCoverImage,
  generateJpostFaq,
  generateJpostIntroContent,
  generateJpostTeaser,
  generateJpostTitle,
  getAllAmobeezCatalogProductsByCategories,
  getComparisonPageByKeyword,
  getComparisonPageByPath,
  getResourcesOptionsFromDB,
  removeByComparisonPagePath,
  rewriteComparisonPageProductsById,
  updateComparisonPage,
  updateProduct,
} from '../../Helpers/api';
import { AmazonProduct, ComparisonPage, ComparisonPageResp, Product } from '../../Modals/ContextObj';
import { DropDownObj } from '../../Helpers/customTypes';
import {
  ACCESS_LEVEL,
  PUBLISHERS,
  WEBSITE_ID,
  getPathFromCategories,
  getVersionStringByComparisonPage,
  randomGradeByPosition,
} from '../../Helpers/consts';
import DraggableList from '../DraggableList/DraggableList';
import {
  downloadLayoutsFiles,
  parentCtechYnetDownloadZipComparisonPage,
  parentPublishComparisonPageINN,
  parentPublishComparisonPageNewsDirect,
} from '../JpostHomeComponent/JpostHomeComponent';
import {
  createGoogleAdsCampaignForComparisonPage,
  getCampaignByUrl,
  removeGoogleIndexByUrl,
} from '../../Helpers/affMsGoogle.api';

// TOO OLD FILE

interface JpostComparisonEditComponentProps {
  domain: string;
  selectedWebsiteId: number;
  urlsDropDown: DropDownObj[];
  comparisonPagesByWebsite: ComparisonPage[];
}

const JpostComparisonEditComponent = (props: JpostComparisonEditComponentProps) => {
  const { setIsLoading, currentEditComparisonPage, userAccessLevel } = useContext(appContext);
  const [writerOptions, setWriterOptions] = useState<{ key: string; text: string; value: string }[]>([]);
  const [affiliationModalOpen, setAffiliationModalOpen] = useState(false);
  const [additionalAffiliationProducts, setAdditionalAffiliationProducts] = useState<
    {
      asin: string;
      image: string;
      price: number;
      percentage: string;
      affiliateType: string;
      potentialProfit: number;
      reviews: number;
      rating: number;
      link: string;
      brand: string;
    }[]
  >([]);

  const [id, setId] = useState(-1);
  const [comparisonPage, setComparisonPage] = useState<ComparisonPage>();
  const [keyword, setKeyword] = useState('');
  const [relatedKeywords, setRelatedKeywords] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [writterName, setWritterName] = useState('');
  const [introContent, setIntroContent] = useState('');
  const [conclusion, setConclusion] = useState('');
  const [buyersGuideAndFaq, setBuyersGuideAndFaq] = useState('');
  const [faq, setFaq] = useState('');
  const [teaser, setTeaser] = useState('');
  const [notes, setNotes] = useState('');
  const [categoryImage, setCategoryImage] = useState('');
  const [productionPageUrl, setProductionPageUrl] = useState('');
  const [category3, setCategory3] = useState('');
  const [verified, setVerified] = useState(false);
  const [comparisonPageType, setComparisonPageType] = useState('');
  const [excludedAsins, setExcludedAsins] = useState('');
  const [excludedWords, setExcludedWords] = useState('');
  const [amazonCategoryId, setAmazonCategoryId] = useState('');
  const [forceBrandingLimit, setForceBrandingLimit] = useState(false);
  const [epcTestingModeEnabled, setEpcTestingModeEnabled] = useState(false);
  const [version, setVersion] = useState(1);

  const [urlsDropDown, setUrlsDropDown] = useState<DropDownObj[]>(props.urlsDropDown);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [linksForGenerate, setLinksForGenerate] = useState('');
  const [originalProducts, setOriginalProducts] = useState<
    {
      product: Product;
      amazonData: AmazonProduct;
      keyword?: string | undefined;
    }[]
  >([]);
  const [finalHeaders, setFinalHeaders] = useState<string[]>([]);

  const [asinToRemove, setAsinToRemove] = useState('');
  const [asinToAdd, setAsinToAdd] = useState('');

  const [alreadyHandled, setAlreadyHandled] = useState(false);

  const [urlPath, setUrlPath] = useState('');

  const [campaignsData, setCampaignsData] = useState<any[]>([]);

  const getAffiliateProductsFromDB = async () => {
    setIsLoading(true);

    const res: {
      asin: string;
      image: string;
      price: number;
      percentage: string;
      affiliateType: string;
      potentialProfit: number;
      reviews: number;
      rating: number;
      link: string;
      brand: string;
    }[] = await getAllAmobeezCatalogProductsByCategories({
      categoriesFlatPath: originalProducts?.[3]?.amazonData?.categoriesFlat.split(' > ').slice(-3).join(' > '),
      keyword,
    });

    setAdditionalAffiliationProducts(res);
    setAffiliationModalOpen(true);
    setIsLoading(false);
  };

  function isWriterFound(writerName: string, array: { key: string; text: string; value: string }[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].text === writerName) {
        return true;
      }
    }
    return false;
  }

  const getComparisonDataFromDB = async (keyword: string, version: number) => {
    setIsLoading(true);
    const res: ComparisonPageResp = await getComparisonPageByKeyword(keyword, props.selectedWebsiteId, version);
    if (res?.comparisonPage) {
      setComparisonPage(res.comparisonPage);
      setId(res.comparisonPage.id);
      setKeyword(res.comparisonPage.keyword);
      setRelatedKeywords(res.comparisonPage.relatedKeywords);
      setTitle(res.comparisonPage.title);
      setDate(res.comparisonPage.date);
      setWritterName(res.comparisonPage.writterName);
      setIntroContent(res.comparisonPage.introContent);
      setConclusion(res.comparisonPage.conclusion);
      setBuyersGuideAndFaq(res.comparisonPage.buyersGuideAndFaq);
      setFaq(res.comparisonPage.faq);
      setTeaser(res.comparisonPage.teaser);
      setNotes(res.comparisonPage.notes);
      setProductionPageUrl(res.comparisonPage.productionPageUrl || '');
      setCategoryImage(res.comparisonPage.categoryImage);
      setCategory3(res.comparisonPage.category3);
      setVerified(res.comparisonPage.verified || false);
      setComparisonPageType(res.comparisonPage.comparisonPageType);
      setExcludedAsins(res.comparisonPage.excludedAsins || '');
      setExcludedWords(res.comparisonPage.excludedWords || '');
      setAmazonCategoryId(res.comparisonPage.amazonCategoryId || '');
      setForceBrandingLimit(res.comparisonPage.forceBrandingLimit || false);
      setEpcTestingModeEnabled(res.comparisonPage.epcTestingModeEnabled || false);
      setVersion(res.comparisonPage.version || 1);

      setLinksForGenerate(
        res.products
          .slice(0, 6)
          .map((p) => `link title: ${p.amazonData.title}.  from this url: ${p.amazonData.link} . `)
          .join(', '),
      );

      const originalProductsss = [
        ...res?.products
          .filter((p) => p.product.itemType === 'REGULAR')
          .sort((a, b) => a.product.position - b.product.position),
        ...res?.products
          .filter((p) => p.product.itemType === 'DEAL')
          .sort((a, b) => a.product.position - b.product.position),
      ];
      setOriginalProducts(originalProductsss);

      const category1 = res?.comparisonPage?.category1
        .replace(/\s+/g, '')
        .replace(/[^\w\s]/g, '-')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase();
      const category2 = res?.comparisonPage?.category2
        .replace(/\s+/g, '')
        .replace(/[^\w\s]/g, '-')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase();
      const category3 = res?.comparisonPage?.category3
        .replace(/\s+/g, '')
        .replace(/[^\w\s]/g, '-')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase();
      setUrlPath(category3);

      if (
        [WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) &&
        res?.comparisonPage?.productionPageUrl
      ) {
        const campaignResuls = await getCampaignByUrl({
          campaignUrl: res.comparisonPage.productionPageUrl,
          websiteId: props.selectedWebsiteId,
        });
        if (campaignResuls?.length > 0) {
          setCampaignsData(campaignResuls.map((c: any) => c.campaign));
        } else {
          setCampaignsData([]);
        }
      } else {
        setCampaignsData([]);
      }
    } else {
      toast.error('Comparison Data not found for this category!');
    }

    let writersMapped: { key: string; text: string; value: string }[] = [];

    if (writerOptions.length === 0) {
      const { writers, categories } = await getResourcesOptionsFromDB(props.selectedWebsiteId);

      writersMapped = writers.map((w: any) => ({
        key: w.id,
        text: w.name,
        value: w.name,
      }));

      setWriterOptions(writersMapped);

      if (!isWriterFound(res?.comparisonPage?.writterName, writersMapped) && writersMapped.length > 0) {
        setWritterName(writersMapped[0].text);
      }
    } else {
      if (!isWriterFound(res?.comparisonPage?.writterName, writerOptions)) {
        setWritterName(writerOptions[0].text);
      }
    }

    setIsLoading(false);
  };

  const regenerateData = async () => {
    try {
      setIsLoading(true);

      // await generateComparisonPageData(selectedUrl);
      setIsLoading(false);
      toast.success('Comparison data generated successfully!');
    } catch (e) {
      setIsLoading(false);
      toast.error('Failed to generated Comparison Page');
    }
  };

  const updateComparisonPageData = async () => {
    const payload = {
      id,
      title,
      date,
      writterName,
      introContent,
      conclusion,
      categoryImage,
      buyersGuideAndFaq,
      faq,
      teaser,
      notes,
      productionPageUrl,
      relatedKeywords,
      excludedAsins: excludedAsins?.replace(/\s/g, '') || '',
      excludedWords: excludedWords?.toLowerCase().replace(/\s/g, '') || '',
      forceBrandingLimit,
      epcTestingModeEnabled,
      amazonCategoryId,
    };

    const res = await updateComparisonPage(payload);

    if ([WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)) {
      await removeByComparisonPagePath({
        path: urlPath,
        websiteId: props.selectedWebsiteId,
      });
    }

    if (res?.length > 0) {
      toast.success('Updated Successfully!');
    } else {
      toast.error('Something went wrong..');
    }
  };

  const handleUrlChange = async (event: React.SyntheticEvent<HTMLElement, Event> | null, data: DropdownProps) => {
    const value = urlsDropDown.find((o: DropDownObj) => o.value === data.value)?.value;

    if (value) {
      const obj = props.comparisonPagesByWebsite.find(
        (cp) => getPathFromCategories(cp) + getVersionStringByComparisonPage(cp) === value,
      );
      if (obj) {
        setSelectedUrl(value);
        await getComparisonDataFromDB(obj.keyword, obj.version);
      }
    }
  };

  const regenerateJpostTitle = async () => {
    setIsLoading(true);
    const res = await generateJpostTitle({ keyword, lastCategory: category3 });
    setTitle(res.title);
    setIsLoading(false);
  };

  const regenerateJpostIntroContent = async () => {
    setIsLoading(true);
    const res = await generateJpostIntroContent({ keyword, title, relatedKeywords });
    setIntroContent(res.introContent);
    setIsLoading(false);
  };

  const regenerateJpostConclusion = async () => {
    setIsLoading(true);
    const res = await generateJpostConclusion({
      listOfProducts: linksForGenerate,
      keyword,
    });
    setConclusion(res.conclusion);
    setIsLoading(false);
  };

  const regenerateJpostBuyersGuide = async () => {
    setIsLoading(true);
    let allKeywords = relatedKeywords !== '' ? `${keyword}, ${relatedKeywords}` : keyword;
    const res = await generateJpostBuyersGuide({ keyword: allKeywords });
    setBuyersGuideAndFaq(res.buyersGuideAndFaq);
    setIsLoading(false);
  };

  const regenerateJpostFaq = async () => {
    setIsLoading(true);
    let allKeywords = relatedKeywords !== '' ? `${keyword}, ${relatedKeywords}` : keyword;
    const res = await generateJpostFaq({ keyword: allKeywords });
    setFaq(res.faq);
    setIsLoading(false);
  };

  const regenerateJpostTeaser = async () => {
    setIsLoading(true);
    const res = await generateJpostTeaser({ keyword });
    setTeaser(res.teaser);
    setIsLoading(false);
  };

  const regenerateJpostCoverImage = async () => {
    setIsLoading(true);

    let type = '';
    if (props.selectedWebsiteId === WEBSITE_ID.JPOST) {
      type = PUBLISHERS.JPOST;
    } else if ([WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)) {
      type = PUBLISHERS.GUIDE_TOP_REVIEW;
    } else if (props.selectedWebsiteId === WEBSITE_ID.LENSVID) {
      type = PUBLISHERS.LENSVID;
    } else if (props.selectedWebsiteId === WEBSITE_ID.CTECH) {
      type = PUBLISHERS.CTEC;
    } else if (props.selectedWebsiteId === WEBSITE_ID.INN) {
      type = PUBLISHERS.INN;
    } else if (props.selectedWebsiteId === WEBSITE_ID.NEWS_DIRECT) {
      type = PUBLISHERS.NEWS_DIRECT;
    } else if (props.selectedWebsiteId === WEBSITE_ID.YNET) {
      type = PUBLISHERS.YNET;
    } else if (props.selectedWebsiteId === WEBSITE_ID.AMOBEEZ_DEMO) {
      type = PUBLISHERS.AMOBEEZ_DEMO; // we want here image without lable
    } else if (props.selectedWebsiteId === WEBSITE_ID.YNET_NEWS) {
      type = PUBLISHERS.YNET;
    } else if (props.selectedWebsiteId === WEBSITE_ID.BUYEREVIEWS) {
      type = PUBLISHERS.BUYEREVIEWS;
    } else if (props.selectedWebsiteId === WEBSITE_ID.ISRAEL_HAYOM) {
      type = PUBLISHERS.ISRAEL_HAYOM;
    }

    const res = await generateJpostCoverImage({
      introContent,
      title,
      type,
      comparisonPageType,
    });
    if (res.imageUrl) {
      setCategoryImage(res.imageUrl);
    } else {
      toast.error('Failed to generate image. :(');
    }
    setIsLoading(false);
  };

  const reloadProducts = async () => {
    //TODO
  };

  const deleteTheEntireComparisonPage = async () => {
    setIsLoading(true);

    await deleteComparisonPageById(id);

    if ([WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)) {
      await removeGoogleIndexByUrl({
        url: productionPageUrl,
        websiteId: props.selectedWebsiteId,
      });
    }

    toast.success('Deleted successfully!');
    setIsLoading(false);
  };

  const createManualVersionComparisonPage = async () => {
    setIsLoading(true);
    await createManualVersionByComparisonPageId(id);
    setIsLoading(false);
  };

  const createAnalystVersionByComparison = async () => {
    setIsLoading(true);
    await createAnalystVersionByComparisonPageId(id);
    setIsLoading(false);
  };

  const rewriteComparisonPageProducts = async () => {
    rewriteComparisonPageProductsById({
      keyword,
      websiteId: props.selectedWebsiteId,
      version,
    });
    toast.success('Executed, it will take few minutes..');
  };

  const createGoogleAdsCampaign = async (websiteId: number) => {
    if (productionPageUrl && productionPageUrl !== null && productionPageUrl !== '') {
      setIsLoading(true);
      await createGoogleAdsCampaignForComparisonPage({
        keyword,
        productionPageUrl,
        websiteId,
      });
      setIsLoading(false);
    } else {
      toast.error('Production page URL is missing!');
    }
  };

  const removeAsinFromComparisonPage = async () => {
    const found = originalProducts.find((p) => p.product.asin === asinToRemove);

    if (found) {
      setIsLoading(true);

      const payload = {
        asin: asinToRemove,
        websiteId: props.selectedWebsiteId,
        comparisonPageId: id,
        shouldDelete: true,
      };

      await updateProduct(payload);
      setIsLoading(false);
      await reloadProducts();
      toast.success('Product removed successfully!');
    } else {
      toast.error('This ASIN doesn`t exist in this Comparison Page');
    }
  };

  const addAsinToComparisonPage = async () => {
    const found = originalProducts.find((p) => p.product.asin === asinToAdd);

    if (!found) {
      setIsLoading(true);

      const payload = {
        asins: [asinToAdd],
        websiteId: props.selectedWebsiteId,
        comparisonPageId: id,
        keyword: keyword,
        comparisonPageType,
      };

      await addNewProductsToExistingJpostComparisonId(payload);
      setIsLoading(false);
      await reloadProducts();
      toast.success('Product Added successfully!');
    } else {
      toast.error('This ASIN already exist in this Comparison Page');
    }
  };

  const saveNewProductsOrder = async () => {
    setIsLoading(true);

    let itemType = 'REGULAR';

    for (let i = 0; i < finalHeaders.length; i++) {
      const product = originalProducts.find((p) => p.product.asin === finalHeaders[i]);

      let position = i;

      /*             if ([WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && i >= 12) { //12 regular items
                            itemType = 'DEAL';
                            position = position - 12;
                        } */

      const payload = {
        asin: product?.product.asin,
        position,
        itemType,
        comparisonPageId: id,
        grade: randomGradeByPosition(i),
        websiteId: props.selectedWebsiteId,
      };
      await updateProduct(payload);
    }

    if ([WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)) {
      await removeByComparisonPagePath({
        path: urlPath,
        websiteId: props.selectedWebsiteId,
      });
    }

    setIsLoading(false);
    toast.success('New product`s order saved successfully!');
  };

  const headersOrderChanged = (newList: any[]) => {
    setFinalHeaders(newList);
  };

  const cutImage = async (id: number) => {
    setIsLoading(true);
    try {
      const response: any = await downloadSelectedImageByIndex({
        id,
        title,
        categoryImage,
      });
      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'coverImage.png');

      // Simulate a click on the link to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
    setIsLoading(false);
  };

  if (currentEditComparisonPage.key !== -1 && !alreadyHandled) {
    handleUrlChange(null, currentEditComparisonPage);
    console.log('need to show ' + currentEditComparisonPage.text);
    setAlreadyHandled(true);
  }

  const copyToClipboard = (text: string) => {
    const input = document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    toast.success('Copied to clipboard!');
  };

  return (
    <div className="comparison-edit-container">
      <div className="title">Select Comparison Page Url: </div>

      <div className="selected-url-and-reload-btn">
        <Dropdown search selection options={urlsDropDown} onChange={handleUrlChange} value={selectedUrl} />

        {props.selectedWebsiteId === WEBSITE_ID.JPOST && (
          <Button
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '0.5px solid',
              margin: '0px',
              marginLeft: '15px',
              width: '50px',
            }}
            icon="download"
            disabled={selectedUrl === ''}
            onClick={(cp) => downloadLayoutsFiles(comparisonPage, '', '8', userAccessLevel, setIsLoading)}
          />
        )}

        {props.selectedWebsiteId === WEBSITE_ID.CTECH ||
          (props.selectedWebsiteId === WEBSITE_ID.YNET && (
            <Button
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '0.5px solid',
                margin: '0px',
                marginLeft: '15px',
                width: '50px',
              }}
              icon="download"
              disabled={selectedUrl === ''}
              onClick={(cp) => parentCtechYnetDownloadZipComparisonPage(comparisonPage, setIsLoading)}
            />
          ))}

        {props.selectedWebsiteId === WEBSITE_ID.INN && (
          <Button
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '0.5px solid',
              margin: '0px',
              marginLeft: '15px',
              width: '50px',
            }}
            icon="send"
            disabled={selectedUrl === ''}
            onClick={(cp) => parentPublishComparisonPageINN(comparisonPage, '8', setIsLoading)}
          />
        )}

        {props.selectedWebsiteId === WEBSITE_ID.NEWS_DIRECT && (
          <Button
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '0.5px solid',
              margin: '0px',
              marginLeft: '15px',
              width: '50px',
            }}
            icon="send"
            disabled={selectedUrl === ''}
            onClick={(cp) => parentPublishComparisonPageNewsDirect(comparisonPage, '8', setIsLoading)}
          />
        )}

        <Button
          style={{
            backgroundColor: 'white',
            color: 'black',
            border: '0.5px solid',
            margin: '0px',
            marginLeft: '15px',
            width: '50px',
          }}
          icon="refresh"
          disabled={selectedUrl === ''}
          onClick={() => getComparisonDataFromDB(keyword, version)}
        />
      </div>

      {campaignsData?.length > 0 && (
        <div
          style={{
            border: '0.5px solid lightgray',
            padding: '20px',
            marginTop: '15px',
            backgroundColor: '#00a4ff0f',
          }}
        >
          {campaignsData.map((c: any) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <b>Campaign ID: </b>
                {c.id}
              </div>
              <div>
                <b>Name: </b>
                {c.name}
              </div>
              <div>
                <b>Started At: </b>
                {c.startDate}
              </div>
              <div>
                <b>Status: </b>
                <span style={c.status === 'ENABLED' ? { color: 'green' } : { color: 'red' }}>
                  <b>{c.status}</b>
                </span>
              </div>
            </div>
          ))}
        </div>
      )}

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          style={{
            backgroundColor: '#6435c9',
            color: 'white',
            margin: '0px',
            marginTop: '10px',
          }}
          icon="user"
          disabled={selectedUrl === '' || verified}
          content="Create Manual Editor Version"
          onClick={() => createManualVersionComparisonPage()}
        />

        <Button
          style={{
            backgroundColor: '#35c992',
            color: 'white',
            margin: '0px',
            marginTop: '10px',
          }}
          icon="line graph"
          disabled={selectedUrl === ''}
          content="Create Analyst Version"
          onClick={() => createAnalystVersionByComparison()}
        />

        {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
          <Button
            style={{
              backgroundColor: '#4c6de7',
              color: 'white',
              margin: '0px',
              marginTop: '10px',
            }}
            icon="google drive"
            disabled={selectedUrl === ''}
            content="Create Google Ads Campaign"
            onClick={() => createGoogleAdsCampaign(props.selectedWebsiteId)}
          />
        )}

        {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
          <Button
            style={{
              backgroundColor: 'black',
              color: 'white',
              margin: '0px',
              marginTop: '10px',
            }}
            icon="write"
            disabled={selectedUrl === ''}
            content="Re-write Article"
            onClick={() => rewriteComparisonPageProducts()}
          />
        )}
      </div>
      <div className="get-comparison">
        {/* 
                <Button
                    icon='refresh'
                    content='RE-GENERATE DATA'
                    style={{ marginLeft: '300px', backgroundColor: '#ff6d6d', color: 'white' }}
                    onClick={() => regenerateData()} /> */}
      </div>

      <Form>
        <Form.Group widths="equal">
          <Form.Field
            id="form-input-control-keyword"
            control={Input}
            label="Keyword - (not editable)"
            placeholder="Keyword"
            value={keyword}
            // disabled={true}
            onChange={(e: any) => {
              setKeyword(keyword);
            }}
          />

          <Form.Field
            id="form-input-control-relatedKeywords"
            control={Input}
            label="Related Keywords"
            placeholder="Related Keywords"
            value={relatedKeywords}
            onChange={(e: any) => {
              setRelatedKeywords(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Field
          icon={
            <Icon
              name="share square"
              size="large"
              color="blue"
              link
              onClick={() =>
                productionPageUrl && productionPageUrl !== '' ? window.open(productionPageUrl, '_target') : null
              }
            />
          }
          id="form-input-control-productionPageUrl"
          control={Input}
          label="Production Page URL"
          placeholder="...."
          value={productionPageUrl}
          onChange={(e: any) => {
            setProductionPageUrl(e.target.value);
          }}
        />

        <div className="divider-dotted" />

        <div className="generate-part">
          <Button icon="refresh" content="Re-Generate Title" onClick={() => regenerateJpostTitle()} />
        </div>
        <Form.Field
          id="form-input-control-title"
          control={Input}
          label="Title"
          placeholder="Title"
          value={title}
          onChange={(e: any) => {
            setTitle(e.target.value);
          }}
        />

        <Form.Group widths="equal">
          <Form.Field
            id="form-input-control-dateAndName"
            control={Input}
            label="Date"
            placeholder="Date"
            value={date}
            onChange={(e: any) => {
              setDate(e.target.value);
            }}
          />

          {/* <Form.Field
                        id='form-input-control-dateAndName'
                        control={Input}
                        label='Writter Name'
                        placeholder='Writter Name'
                        value={writterName}
                        onChange={(e: any) => { setWritterName(e.target.value) }}
                    /> */}
          <Form.Field
            control={Select}
            options={writerOptions}
            label="Writer"
            placeholder="Writer"
            search
            value={writterName}
            onChange={(e: any) => {
              setWritterName(e.target.innerText);
            }}
            searchInput={{ id: 'form-select-control-writer-dd' }}
          />
        </Form.Group>

        <div className="divider-dotted" />
        <div className="generate-part">
          <Button icon="refresh" content="Re-Generate Intro Content" onClick={() => regenerateJpostIntroContent()} />
        </div>
        <Form.Field
          id="form-textarea-control-intro-content"
          control={TextArea}
          label="Intro Content"
          placeholder="Intro Content"
          value={introContent}
          onChange={(e: any) => {
            setIntroContent(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="generate-part">
          <Button icon="refresh" content="Re-Generate Conclusion" onClick={() => regenerateJpostConclusion()} />
        </div>
        <Form.Field
          id="form-textarea-control-conclusion"
          control={TextArea}
          label="Conclusion"
          placeholder="Conclusion"
          value={conclusion}
          onChange={(e: any) => {
            setConclusion(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="generate-part">
          <Button icon="refresh" content="Re-Generate Buyers Guide" onClick={() => regenerateJpostBuyersGuide()} />
        </div>
        <Form.Field
          id="form-textarea-control-buyersGuide"
          control={TextArea}
          label="Buyers Guide"
          placeholder="Buyers Guide"
          value={buyersGuideAndFaq}
          onChange={(e: any) => {
            setBuyersGuideAndFaq(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="generate-part">
          <Button icon="refresh" content="Re-Generate Teaser" onClick={() => regenerateJpostTeaser()} />
        </div>
        <Form.Field
          id="form-textarea-control-teaser"
          control={TextArea}
          label="Teaser"
          placeholder="Teaser"
          value={teaser}
          onChange={(e: any) => {
            setTeaser(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="generate-part">
          <Button icon="refresh" content="Re-Generate FAQ" onClick={() => regenerateJpostFaq()} />
        </div>
        <Form.Field
          id="form-textarea-control-Faq"
          control={TextArea}
          label="FAQ"
          placeholder="FAQ"
          value={faq}
          onChange={(e: any) => {
            setFaq(e.target.value);
          }}
        />

        <Form.Field
          id="form-input-control-excludedAsins"
          control={Input}
          label="Excluded ASINs - (List of ASINs that will not be part of this article)"
          placeholder="B066351GHG,B068351GU7,B01835OP7H..."
          value={excludedAsins}
          onChange={(e: any) => {
            setExcludedAsins(e.target.value);
          }}
        />

        <Form.Field
          id="form-input-control-excludedAsins"
          control={Input}
          label="Excluded Words - (List of words that will not be part of the products title)"
          placeholder="word1,word2,word3..."
          value={excludedWords}
          onChange={(e: any) => {
            setExcludedWords(e.target.value);
          }}
        />

        {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
          <Form.Group widths="equal">
            <Form.Field
              id="form-input-control-amazonCategoryId"
              control={Input}
              label="Amazon Category Id"
              placeholder="Amazon Category Id"
              value={amazonCategoryId}
              onChange={(e: any) => {
                setAmazonCategoryId(e.target.value);
              }}
            />

            <Form.Field>
              <Checkbox
                style={{ marginTop: '35px', marginLeft: '30px' }}
                label={
                  [WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)
                    ? 'Branded Lineup'
                    : 'Force Branding Limit'
                }
                checked={forceBrandingLimit}
                onChange={(e: any, data: any) => {
                  console.log(data.checked);
                  setForceBrandingLimit(data.checked);
                }}
              />
            </Form.Field>

            {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
              <Form.Field>
                <Checkbox
                  style={{ marginTop: '35px', marginLeft: '30px' }}
                  label="EPC Testing Mode Enabled"
                  checked={epcTestingModeEnabled}
                  onChange={(e: any, data: any) => {
                    console.log(data.checked);
                    setEpcTestingModeEnabled(data.checked);
                  }}
                />
              </Form.Field>
            )}
          </Form.Group>
        )}

        <div className="img-container-image-url">
          <Form.Field
            id="form-input-control-image-url"
            control={Input}
            label="Category Image"
            placeholder="Category Image"
            value={categoryImage}
            onChange={(e: any) => {
              setCategoryImage(e.target.value);
            }}
          />
          <div className="generate-part">
            <Button icon="refresh" content="Re-Generate Cover Image" onClick={() => regenerateJpostCoverImage()} />
          </div>

          {props.selectedWebsiteId === WEBSITE_ID.JPOST && (
            <div className="split-images-container">
              <div onClick={() => cutImage(1)}>1</div>
              <div onClick={() => cutImage(2)}>2</div>
              <div onClick={() => cutImage(3)}>3</div>
              <div onClick={() => cutImage(4)}>4</div>
            </div>
          )}

          <Image src={categoryImage} as="a" href={categoryImage} target="_blank" size="large" />
        </div>

        <div className="update-btn">
          <Form.Field
            id="form-button-control-update-btn"
            control={Button}
            content="Update Comparison Page Data"
            disabled={id === -1}
            onClick={() => updateComparisonPageData()}
          />
        </div>
      </Form>

      <div className="edit-products-container">
        <div className="title">
          <div>Edit Comparison Page Products:</div>{' '}
          <Button icon="trash" content="Remove Comparison Page" onClick={() => deleteTheEntireComparisonPage()} />
        </div>
        <div className="remove-product-by-asin">
          <div className="title-inputs">Remove Product by ASIN: </div>
          <Input
            placeholder="ASIN"
            value={asinToRemove}
            onChange={(e) => {
              setAsinToRemove(e.target.value);
            }}
          >
            <input />
          </Input>
          <Button icon="trash" content="Remove" onClick={() => removeAsinFromComparisonPage()} />
        </div>

        <div className="add-product-by-asin">
          <div className="title-inputs">Add Product by ASIN: </div>
          <Input
            placeholder="ASIN"
            value={asinToAdd}
            onChange={(e) => {
              setAsinToAdd(e.target.value);
            }}
          >
            <input />
          </Input>
          <Button icon="edit" content="Add New" onClick={() => addAsinToComparisonPage()} />
        </div>

        <div className="more-affiliation-products" onClick={() => getAffiliateProductsFromDB()}>
          More Affiliation Products
        </div>

        <DraggableList
          items={originalProducts}
          headersOrderChanged={headersOrderChanged}
          websiteId={props.selectedWebsiteId}
          comparisonPageId={id}
        ></DraggableList>

        <Button icon="save" content="Save New Order" onClick={() => saveNewProductsOrder()} />
      </div>

      {notes && notes !== '' && (
        <div
          style={{
            border: '0.5px solid lightgray',
            padding: '20px',
            margin: '20px',
            backgroundColor: '#ffb70130',
          }}
        >
          Suggested Products to Add:
          {notes?.split(',')?.map((asin: string, index) => (
            <div key={index}>
              {' '}
              <a href={`https://www.amazon.com/dp/${asin.trim()}`} target="_blank">
                https://www.amazon.com/dp/{asin.trim()}
              </a>
            </div>
          ))}
        </div>
      )}

      <Modal
        centered={true}
        open={affiliationModalOpen}
        onClose={() => setAffiliationModalOpen(false)}
        onOpen={() => setAffiliationModalOpen(true)}
      >
        <ModalHeader>More Affiliation Product</ModalHeader>
        <ModalContent>
          <ModalDescription
            style={{
              padding: '10px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '30px',
            }}
          >
            {additionalAffiliationProducts &&
              additionalAffiliationProducts.map((item, i) => (
                <div
                  key={i}
                  style={{
                    width: '170px',
                    height: 'auto',
                    border: '0.5px solid gray',
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <a href={`https://www.amazon.com/dp/${item.asin}`} target="_blank">
                    <Image src={item.image} size="small" />
                  </a>

                  {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) &&
                    userAccessLevel === ACCESS_LEVEL.ADMIN && (
                      <div
                        style={{
                          color: 'gray',
                          fontWeight: 'bold',
                          paddingTop: '10px',
                        }}
                      >
                        {item.affiliateType}
                      </div>
                    )}
                  <div
                    style={{
                      color: 'dodgerblue',
                      fontWeight: 'bold',
                      paddingTop: '10px',
                    }}
                  >
                    {item.brand}
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      paddingTop: '1px',
                    }}
                    onClick={() => copyToClipboard(item.asin)}
                  >
                    {item.asin}
                  </div>
                  <div style={{ color: 'gray', fontWeight: 'bold' }}>
                    ★{item.rating} ({item.reviews})
                  </div>
                  <div style={{ color: '#f2711c', fontWeight: 'bold' }}>Price: ${item.price}</div>
                  {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
                    <div style={{ color: 'purple', fontWeight: 'bold' }}>Percentage: {item.percentage}</div>
                  )}
                  {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
                    <div style={{ color: 'green', fontWeight: 'bold' }}>Profit: ${item.potentialProfit.toFixed(2)}</div>
                  )}
                </div>
              ))}
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => setAffiliationModalOpen(false)}>Close</Button>
        </ModalActions>
      </Modal>
    </div>
  );
};

export default JpostComparisonEditComponent;
