import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Input, Image, Form, TextArea, Table } from 'semantic-ui-react';
import './BrProductEditComponent.scss';
import { appContext } from '../../App';
import {
  addNewTaskToQueue,
  generateBrProductDescription,
  generateBrProductTitle,
  getComparisonPagesByAsinExistence,
  getProductByAsin,
  getProductInfoByAsins,
  updateProduct,
  updateProductInformationAndProducts,
} from '../../Helpers/api';
import { AmazonProduct, Product } from '../../Modals/ContextObj';
import { getCommissionString } from '../../Helpers/commissionCalc';

interface JpostProductEditComponentProps {
  selectedWebsiteId: number;
  domain: string;
}

const BrProductEditComponent = (props: JpostProductEditComponentProps) => {
  const { setIsLoading } = useContext(appContext);

  // const [enableGenerateAsinBtn, setEnableGenerateAsinBtn] = useState(false);
  const [enableDownloadZip, setEnableDownloadZip] = useState(false);
  const [asinToEdit, setAsinToEdit] = useState('');

  const [comparisonPagesArr, setComparisonPagesArr] = useState<{ keyword: string; productionPageUrl: string }[]>([]);

  const [title, setTitle] = useState('');
  const [originalTitle, setOriginalTitle] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [originalProductDescription, setOriginalProductDescription] = useState('');
  const [item, setItem] = useState<any>({});

  // const [amazonProductId, setAmazonProductId] = useState(-1);
  const [name, setName] = useState('');
  const [asin, setAsin] = useState('');
  const [affiliateType, setAffiliateType] = useState('');
  const [affiliateLink, setAffiliateLink] = useState('');
  const [affiliateMerchant, setAffiliateMerchant] = useState('');
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [price, setPrice] = useState(0);
  const [rating, setRating] = useState(0);
  const [brand, setBrand] = useState('');

  const getData = async () => {
    await getProductInfo();
    await getAsinFromDB();
  };
  const getAsinFromDB = async () => {
    const res: {
      product: Product;
      amazonData: AmazonProduct;
      keyword?: string;
    } = await getProductByAsin(asinToEdit, props.selectedWebsiteId);
    if (res.product !== null) {
      // setProductId(res.product.id);
      setAsin(res.product.asin || '');
      setAffiliateType(res.amazonData.affiliateType);
      setAffiliateLink(res.amazonData.affiliateLink);
      setName(res.amazonData.name || '');
      setImage1(res.amazonData.image1 || '');
      setImage2(res.amazonData.image2 || '');
      setPrice(res.amazonData.price || 0);
      setRating(res.amazonData.rating || 0);
      setBrand(res.amazonData.brand || '');
      setItem(res.amazonData);

      setEnableDownloadZip(true);

      const compPages: { keyword: string; productionPageUrl: string }[] = await getComparisonPagesByAsinExistence({
        asin: asinToEdit,
        websiteId: props.selectedWebsiteId,
      });
      setComparisonPagesArr(compPages);

      // setComparisonPageCategoryPath(`${res.amazonData.category1.replace(/\s+/g, '').replace(/[^\w\s]/g, '-').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}/${res.amazonData.category2.replace(/\s+/g, '').replace(/[^\w\s]/g, '-').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}/${res.amazonData.category3.replace(/\s+/g, '').replace(/[^\w\s]/g, '-').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}`);
    } else {
      toast.error('ASIN not found!');
      // setEnableGenerateAsinBtn(true);
      setEnableDownloadZip(false);

      setAsin('');
      setAffiliateType('');
      setAffiliateLink('');
      setAffiliateMerchant('');
      setTitle('');
      setProductDescription('');
      setName('');
      setImage1('');
      setImage2('');
      setPrice(0);
      setRating(0);
      setBrand('');
    }
  };

  const getProductInfo = async () => {
    const res: { description: string; title: string }[] = await getProductInfoByAsins(
      asinToEdit,
      props.selectedWebsiteId,
    );
    if (res.length > 0) {
      setTitle(res[0].title);
      setOriginalTitle(res[0].title);
      setProductDescription(res[0].description);
      setOriginalProductDescription(res[0].description);
    }
  };

  const createSinglePage = async () => {
    try {
      // const response = await createSingleComparisonPageByAsin({ asin: asinToEdit });
      await addNewTaskToQueue({
        action: 'createSingleComparisonPageByAsin',
        body: { websiteId: props.selectedWebsiteId, asin: asinToEdit },
      });

      toast.success('Single Page Created Successfully!');
    } catch (error) {
      console.error('Error:', error);
      // Handle error if the API call fails
    }
  };

  const updateProductData = async () => {
    if (props.selectedWebsiteId !== -1) {
      const payload = {
        // id: productId,
        asin,
        name,
        image1,
        image2,
        price,
        rating,
        affiliateType,
        affiliateLink,
        affiliateMerchant,
        websiteId: props.selectedWebsiteId,
      };

      const res = await updateProduct(payload);

      const updateTitle = title !== originalTitle ? title : undefined;
      const updateDescription = productDescription !== originalProductDescription ? productDescription : undefined;
      await updateProductInformationAndProducts(
        asin,
        props.selectedWebsiteId,
        updateTitle,
        updateDescription,
      );

      if (res === true) {
        toast.success('Updated Successfully!');
      } else {
        toast.error('Something went wrong..');
      }
    } else {
      toast.error('Must select website before saving data.');
    }
  };

  const regenerateProductTitle = async () => {
    setIsLoading(true);
    const res = await generateBrProductTitle(title, brand);
    setTitle(res);
    setIsLoading(false);
  };

  const regenerateProductDescription = async () => {
    setIsLoading(true);
    const res = await generateBrProductDescription(title);
    setProductDescription(res);
    setIsLoading(false);
  };

  return (
    <div className="product-edit-container">
      <div className="get-product-by-asin">
        <div>Edit ASIN: </div>
        <Input
          placeholder="ASIN"
          value={asinToEdit}
          onChange={(e) => {
            setAsinToEdit(e.target.value);
          }}
        >
          <input />
        </Input>
        <Button icon="edit" content="Load" onClick={() => getData()} />

        <Button
          icon="download"
          content="Create Single Page"
          disabled={!enableDownloadZip}
          onClick={() => createSinglePage()}
        />
      </div>

      {/* <Button
                icon='add'
                style={enableGenerateAsinBtn ? { visibility: 'visible' } : { visibility: 'hidden' }}
                content='Generate ASIN Data'
                onClick={() => generateAsinData()} /> */}

      {comparisonPagesArr && comparisonPagesArr.length > 0 && (
        <div
          style={{
            marginTop: '-30px',
            marginBottom: '20px',
            border: '6px double #9cc8ff',
            padding: '15px',
          }}
        >
          <div>
            <b>This ASIN appears in the following articles:</b>
          </div>

          <Table sortable celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>Keyword</Table.HeaderCell>
                <Table.HeaderCell width={2}>Jpost URL</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {comparisonPagesArr.map((c, i) => (
                <Table.Row key={i}>
                  <Table.Cell>{c.keyword}</Table.Cell>
                  <Table.Cell>
                    <a href={c.productionPageUrl} target="_blank">
                      {c.productionPageUrl}
                    </a>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            id="form-input-control-affiliate-type"
            control={Input}
            label="Affiliate Type"
            placeholder="Affiliate Type"
            value={affiliateType}
            onChange={(e: any) => {
              setAffiliateType(e.target.value);
            }}
          />
          <div
            style={{
              width: '100%',
              marginLeft: '10px',
              justifyContent: 'stretch',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <b>Commission: </b>
            <div>{getCommissionString(item)}</div>
          </div>
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field
            id="form-input-control-name"
            control={Input}
            label="Name"
            placeholder="Name"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
          />
          <Form.Field
            id="form-input-control-star-ranking"
            control={Input}
            label="Star Ranking"
            placeholder="Star Ranking"
            value={rating}
            onChange={(e: any) => {
              setRating(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field
            id="form-input-control-asin"
            control={Input}
            label="ASIN"
            placeholder="ASIN"
            value={asin}
            onChange={(e: any) => {
              setAsin(e.target.value);
            }}
          />
          <Form.Field
            id="form-input-control-price"
            control={Input}
            label="Price"
            placeholder="Price"
            value={price}
            onChange={(e: any) => {
              setPrice(e.target.value);
            }}
          />
        </Form.Group>

        <div className="divider-dotted" />
        <div className="generate-part">
          <Button icon="refresh" content="Re-Generate Title" onClick={() => regenerateProductTitle()} />
        </div>

        <Form.Field
          id="form-textarea-control-product-title"
          control={TextArea}
          label="Title"
          placeholder="Title"
          value={title}
          onChange={(e: any) => {
            setTitle(e.target.value);
          }}
        />

        <div className="generate-part">
          <Button icon="refresh" content="Re-Generate Description" onClick={() => regenerateProductDescription()} />
        </div>

        <Form.Field
          id="form-textarea-control-product-description"
          control={TextArea}
          label="Description"
          placeholder="Description"
          value={productDescription}
          onChange={(e: any) => {
            setProductDescription(e.target.value);
          }}
        />

        <div className="divider-dotted" />

        <div className="img-container-image-url">
          <Form.Field
            id="form-input-control-image-url"
            control={Input}
            label="Image Url"
            placeholder="Image Url"
            value={image1}
            onChange={(e: any) => {
              setImage1(e.target.value);
            }}
          />
          <Image src={image1} as="a" href={image1} target="_blank" />
        </div>

        <div className="update-btn">
          <Form.Field
            id="form-button-control-update-btn"
            control={Button}
            content="Update Product Data"
            disabled={asin === ''}
            onClick={() => updateProductData()}
          />
        </div>
      </Form>
    </div>
  );
};

export default BrProductEditComponent;
