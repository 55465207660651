import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Dropdown, DropdownProps, Input, Form, TextArea } from 'semantic-ui-react';
import './JpostManualEditorComponent.scss';
import { appContext } from '../../App';
import { getComparisonPageByKeyword, updateComparisonPage, updateProduct } from '../../Helpers/api';
import { AmazonProduct, ComparisonPage, ComparisonPageResp, Product } from '../../Modals/ContextObj';
import { DropDownObj } from '../../Helpers/customTypes';
import { getPathFromCategories, getVersionStringByComparisonPage } from '../../Helpers/consts';

// TOO OLD FILE

interface JpostManualEditorComponentProps {
    domain: string,
    selectedWebsiteId: number,
    urlsDropDown: DropDownObj[],
    comparisonPagesByWebsite: ComparisonPage[]
}

const JpostManualEditorComponent = (props: JpostManualEditorComponentProps) => {

    const { setIsLoading } = useContext(appContext);

    const [id, setId] = useState(-1);
    const [keyword, setKeyword] = useState('');
    const [relatedKeywords, setRelatedKeywords] = useState('');
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [writterName, setWritterName] = useState('');
    const [introContent, setIntroContent] = useState('');
    const [conclusion, setConclusion] = useState('');
    const [buyersGuideAndFaq, setBuyersGuideAndFaq] = useState('');
    const [faq, setFaq] = useState('');
    const [teaser, setTeaser] = useState('');
    const [notes, setNotes] = useState('');
    const [categoryImage, setCategoryImage] = useState('');
    const [productionPageUrl, setProductionPageUrl] = useState('');
    const [category3, setCategory3] = useState('');
    const [verified, setVerified] = useState(false);

    const [urlsDropDown, setUrlsDropDown] = useState<DropDownObj[]>(props.urlsDropDown.filter(o => o.text.indexOf('Manual Editor') !== -1));
    const [selectedUrl, setSelectedUrl] = useState('');
    const [originalProducts, setOriginalProducts] = useState<{ product: Product; amazonData: AmazonProduct; keyword?: string | undefined; }[]>([]);


    const [product_1_displayName, setProduct_1_displayName] = useState('');
    const [product_1_pros, setProduct_1_pros] = useState('');
    const [product_1_cons, setProduct_1_cons] = useState('');
    const [product_1_miniDescription, setProduct_1_miniDescription] = useState('');

    const [product_2_displayName, setProduct_2_displayName] = useState('');
    const [product_2_pros, setProduct_2_pros] = useState('');
    const [product_2_cons, setProduct_2_cons] = useState('');
    const [product_2_miniDescription, setProduct_2_miniDescription] = useState('');

    const [product_3_displayName, setProduct_3_displayName] = useState('');
    const [product_3_pros, setProduct_3_pros] = useState('');
    const [product_3_cons, setProduct_3_cons] = useState('');
    const [product_3_miniDescription, setProduct_3_miniDescription] = useState('');

    const [product_4_displayName, setProduct_4_displayName] = useState('');
    const [product_4_pros, setProduct_4_pros] = useState('');
    const [product_4_cons, setProduct_4_cons] = useState('');
    const [product_4_miniDescription, setProduct_4_miniDescription] = useState('');

    const [product_5_displayName, setProduct_5_displayName] = useState('');
    const [product_5_pros, setProduct_5_pros] = useState('');
    const [product_5_cons, setProduct_5_cons] = useState('');
    const [product_5_miniDescription, setProduct_5_miniDescription] = useState('');

    const [product_6_displayName, setProduct_6_displayName] = useState('');
    const [product_6_pros, setProduct_6_pros] = useState('');
    const [product_6_cons, setProduct_6_cons] = useState('');
    const [product_6_miniDescription, setProduct_6_miniDescription] = useState('');

    const [product_7_displayName, setProduct_7_displayName] = useState('');
    const [product_7_pros, setProduct_7_pros] = useState('');
    const [product_7_cons, setProduct_7_cons] = useState('');
    const [product_7_miniDescription, setProduct_7_miniDescription] = useState('');

    const [product_8_displayName, setProduct_8_displayName] = useState('');
    const [product_8_pros, setProduct_8_pros] = useState('');
    const [product_8_cons, setProduct_8_cons] = useState('');
    const [product_8_miniDescription, setProduct_8_miniDescription] = useState('');

    const [product_9_displayName, setProduct_9_displayName] = useState('');
    const [product_9_pros, setProduct_9_pros] = useState('');
    const [product_9_cons, setProduct_9_cons] = useState('');
    const [product_9_miniDescription, setProduct_9_miniDescription] = useState('');

    const [product_10_displayName, setProduct_10_displayName] = useState('');
    const [product_10_pros, setProduct_10_pros] = useState('');
    const [product_10_cons, setProduct_10_cons] = useState('');
    const [product_10_miniDescription, setProduct_10_miniDescription] = useState('');


    const getComparisonDataFromDB = async (keyword: string, version: number) => {
        setIsLoading(true);
        const res: ComparisonPageResp = await getComparisonPageByKeyword(keyword, props.selectedWebsiteId, version);
        if (res?.comparisonPage) {
            setId(res.comparisonPage.id);
            setKeyword(res.comparisonPage.keyword);
            setRelatedKeywords(res.comparisonPage.relatedKeywords);
            setTitle(res.comparisonPage.title);
            setDate(res.comparisonPage.date);
            setWritterName(res.comparisonPage.writterName);
            setIntroContent(res.comparisonPage.introContent);
            setConclusion(res.comparisonPage.conclusion);
            setBuyersGuideAndFaq(res.comparisonPage.buyersGuideAndFaq);
            setFaq(res.comparisonPage.faq);
            setTeaser(res.comparisonPage.teaser);
            setNotes(res.comparisonPage.notes);
            setProductionPageUrl(res.comparisonPage.productionPageUrl || '');
            setCategoryImage(res.comparisonPage.categoryImage);
            setCategory3(res.comparisonPage.category3);
            setVerified(res.comparisonPage.verified || false);


            setOriginalProducts(res?.products.filter(p => p.product.itemType === 'REGULAR').sort((a, b) => a.product.position - b.product.position));


            const sortedProducts = res?.products.sort((a, b) => a.product.position - b.product.position);

            setProduct_1_displayName(sortedProducts[0]?.product?.displayName);
            setProduct_1_pros(sortedProducts[0]?.product?.pros);
            setProduct_1_cons(sortedProducts[0]?.product?.cons);
            setProduct_1_miniDescription(sortedProducts[0]?.product?.miniProductDescription);

            setProduct_2_displayName(sortedProducts[1]?.product?.displayName);
            setProduct_2_pros(sortedProducts[1]?.product?.pros);
            setProduct_2_cons(sortedProducts[1]?.product?.cons);
            setProduct_2_miniDescription(sortedProducts[1]?.product?.miniProductDescription);

            setProduct_3_displayName(sortedProducts[2]?.product?.displayName);
            setProduct_3_pros(sortedProducts[2]?.product?.pros);
            setProduct_3_cons(sortedProducts[2]?.product?.cons);
            setProduct_3_miniDescription(sortedProducts[2]?.product?.miniProductDescription);

            setProduct_4_displayName(sortedProducts[3]?.product?.displayName);
            setProduct_4_pros(sortedProducts[3]?.product?.pros);
            setProduct_4_cons(sortedProducts[3]?.product?.cons);
            setProduct_4_miniDescription(sortedProducts[3]?.product?.miniProductDescription);

            setProduct_5_displayName(sortedProducts[4]?.product?.displayName);
            setProduct_5_pros(sortedProducts[4]?.product?.pros);
            setProduct_5_cons(sortedProducts[4]?.product?.cons);
            setProduct_5_miniDescription(sortedProducts[4]?.product?.miniProductDescription);

            setProduct_6_displayName(sortedProducts[5]?.product?.displayName);
            setProduct_6_pros(sortedProducts[5]?.product?.pros);
            setProduct_6_cons(sortedProducts[5]?.product?.cons);
            setProduct_6_miniDescription(sortedProducts[5]?.product?.miniProductDescription);

            setProduct_7_displayName(sortedProducts[6]?.product?.displayName);
            setProduct_7_pros(sortedProducts[6]?.product?.pros);
            setProduct_7_cons(sortedProducts[6]?.product?.cons);
            setProduct_7_miniDescription(sortedProducts[6]?.product?.miniProductDescription);

            setProduct_8_displayName(sortedProducts[7]?.product?.displayName);
            setProduct_8_pros(sortedProducts[7]?.product?.pros);
            setProduct_8_cons(sortedProducts[7]?.product?.cons);
            setProduct_8_miniDescription(sortedProducts[7]?.product?.miniProductDescription);

            setProduct_9_displayName(sortedProducts[8]?.product?.displayName);
            setProduct_9_pros(sortedProducts[8]?.product?.pros);
            setProduct_9_cons(sortedProducts[8]?.product?.cons);
            setProduct_9_miniDescription(sortedProducts[8]?.product?.miniProductDescription);

            setProduct_10_displayName(sortedProducts[9]?.product?.displayName);
            setProduct_10_pros(sortedProducts[9]?.product?.pros);
            setProduct_10_cons(sortedProducts[9]?.product?.cons);
            setProduct_10_miniDescription(sortedProducts[9]?.product?.miniProductDescription);

        } else {
            toast.error('Comparison Data not found for this category!');
        }
        setIsLoading(false);
    }

    const updateComparisonPageData = async () => {

        const payload = {
            id,
            title,
            date,
            writterName,
            introContent,
            conclusion,
            categoryImage,
            buyersGuideAndFaq,
            faq,
            teaser,
            notes,
            productionPageUrl,
            relatedKeywords
        }

        const res = await updateComparisonPage(payload);

        if (originalProducts.length > 0) {
            await updateProduct({
                asin: originalProducts[0].product.asin,
                displayName: product_1_displayName,
                pros: product_1_pros,
                cons: product_1_cons,
                miniProductDescription: product_1_miniDescription,
                websiteId: props.selectedWebsiteId
            });
        }

        if (originalProducts.length > 1) {
            await updateProduct({
                asin: originalProducts[1].product.asin,
                displayName: product_2_displayName,
                pros: product_2_pros,
                cons: product_2_cons,
                miniProductDescription: product_2_miniDescription,
                websiteId: props.selectedWebsiteId
            });
        }

        if (originalProducts.length > 2) {
            await updateProduct({
                asin: originalProducts[2].product.asin,
                displayName: product_3_displayName,
                pros: product_3_pros,
                cons: product_3_cons,
                miniProductDescription: product_3_miniDescription,
                websiteId: props.selectedWebsiteId
            });
        }

        if (originalProducts.length > 3) {
            await updateProduct({
                asin: originalProducts[3].product.asin,
                displayName: product_4_displayName,
                pros: product_4_pros,
                cons: product_4_cons,
                miniProductDescription: product_4_miniDescription,
                websiteId: props.selectedWebsiteId
            });
        }

        if (originalProducts.length > 4) {
            await updateProduct({
                asin: originalProducts[4].product.asin,
                displayName: product_5_displayName,
                pros: product_5_pros,
                cons: product_5_cons,
                miniProductDescription: product_5_miniDescription,
                websiteId: props.selectedWebsiteId
            });
        }

        if (originalProducts.length > 5) {
            await updateProduct({
                asin: originalProducts[5].product.asin,
                displayName: product_6_displayName,
                pros: product_6_pros,
                cons: product_6_cons,
                miniProductDescription: product_6_miniDescription,
                websiteId: props.selectedWebsiteId
            });
        }

        if (originalProducts.length > 6) {
            await updateProduct({
                asin: originalProducts[6].product.asin,
                displayName: product_7_displayName,
                pros: product_7_pros,
                cons: product_7_cons,
                miniProductDescription: product_7_miniDescription,
                websiteId: props.selectedWebsiteId
            });
        }

        if (originalProducts.length > 7) {
            await updateProduct({
                asin: originalProducts[7].product.asin,
                displayName: product_8_displayName,
                pros: product_8_pros,
                cons: product_8_cons,
                miniProductDescription: product_8_miniDescription,
                websiteId: props.selectedWebsiteId
            });
        }

        if (originalProducts.length > 8) {
            await updateProduct({
                asin: originalProducts[8].product.asin,
                displayName: product_9_displayName,
                pros: product_9_pros,
                cons: product_9_cons,
                miniProductDescription: product_9_miniDescription,
                websiteId: props.selectedWebsiteId
            });
        }

        if (originalProducts.length > 9) {
            await updateProduct({
                asin: originalProducts[9].product.asin,
                displayName: product_10_displayName,
                pros: product_10_pros,
                cons: product_10_cons,
                miniProductDescription: product_10_miniDescription,
                websiteId: props.selectedWebsiteId
            });
        }


        if (res?.length > 0) {
            toast.success('Updated Successfully!');
        } else {
            toast.error('Something went wrong..')
        }
    }

    const handleUrlChange = async (event: React.SyntheticEvent<HTMLElement, Event> | null, data: DropdownProps) => {
        const value = urlsDropDown.find((o: DropDownObj) => o.value === data.value)?.value;

        if (value) {
            const obj = props.comparisonPagesByWebsite.find(cp => getPathFromCategories(cp) + getVersionStringByComparisonPage(cp) === value);
            if (obj) {
                setSelectedUrl(value);
                await getComparisonDataFromDB(obj.keyword, obj.version);
            }
        }
    }

    return (
        <div className='comparison-edit-container'>

            <div className='title'>Select Comparison Page Url: </div>
            <Dropdown
                search
                selection
                options={urlsDropDown}
                onChange={handleUrlChange}
                value={selectedUrl}
            />

            <Form>

                <div className='divider-dotted' />

                <Form.Field
                    id='form-input-control-title'
                    control={Input}
                    label='Title'
                    placeholder='Title'
                    value={title}
                    onChange={(e: any) => { setTitle(e.target.value) }}
                />

                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-intro-content'
                    control={TextArea}
                    label='Intro Content'
                    placeholder='Intro Content'
                    value={introContent}
                    onChange={(e: any) => { setIntroContent(e.target.value) }}
                />

                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-conclusion'
                    control={TextArea}
                    label='Conclusion'
                    placeholder='Conclusion'
                    value={conclusion}
                    onChange={(e: any) => { setConclusion(e.target.value) }}
                />

                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-buyersGuide'
                    control={TextArea}
                    label='Buyers Guide'
                    placeholder='Buyers Guide'
                    value={buyersGuideAndFaq}
                    onChange={(e: any) => { setBuyersGuideAndFaq(e.target.value) }}
                />

                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-teaser'
                    control={TextArea}
                    label='Teaser'
                    placeholder='Teaser'
                    value={teaser}
                    onChange={(e: any) => { setTeaser(e.target.value) }}
                />

                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-Faq'
                    control={TextArea}
                    label='FAQ'
                    placeholder='FAQ'
                    value={faq}
                    onChange={(e: any) => { setFaq(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue' }}>1.[{originalProducts?.[0]?.product?.asin}] {product_1_displayName}</div>
                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-product-display-name-1'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={product_1_displayName}
                    onChange={(e: any) => { setProduct_1_displayName(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-1'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={product_1_pros}
                    onChange={(e: any) => { setProduct_1_pros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-1'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={product_1_cons}
                    onChange={(e: any) => { setProduct_1_cons(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-1'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={product_1_miniDescription}
                    onChange={(e: any) => { setProduct_1_miniDescription(e.target.value) }}
                />



                <div className='divider-dotted' />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue' }}>2.[{originalProducts?.[1]?.product?.asin}] {product_2_displayName}</div>
                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-product-display-name-2'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={product_2_displayName}
                    onChange={(e: any) => { setProduct_2_displayName(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-2'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={product_2_pros}
                    onChange={(e: any) => { setProduct_2_pros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-2'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={product_2_cons}
                    onChange={(e: any) => { setProduct_2_cons(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-2'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={product_2_miniDescription}
                    onChange={(e: any) => { setProduct_2_miniDescription(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue' }}>3.[{originalProducts?.[2]?.product?.asin}] {product_3_displayName}</div>
                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-product-display-name-3'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={product_3_displayName}
                    onChange={(e: any) => { setProduct_3_displayName(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-3'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={product_3_pros}
                    onChange={(e: any) => { setProduct_3_pros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-3'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={product_3_cons}
                    onChange={(e: any) => { setProduct_3_cons(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-3'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={product_3_miniDescription}
                    onChange={(e: any) => { setProduct_3_miniDescription(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue' }}>4.[{originalProducts?.[3]?.product?.asin}] {product_4_displayName}</div>
                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-product-display-name-4'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={product_4_displayName}
                    onChange={(e: any) => { setProduct_4_displayName(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-4'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={product_4_pros}
                    onChange={(e: any) => { setProduct_4_pros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-4'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={product_4_cons}
                    onChange={(e: any) => { setProduct_4_cons(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-4'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={product_4_miniDescription}
                    onChange={(e: any) => { setProduct_4_miniDescription(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue' }}>5.[{originalProducts?.[4]?.product?.asin}] {product_5_displayName}</div>
                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-product-display-name-5'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={product_5_displayName}
                    onChange={(e: any) => { setProduct_5_displayName(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-5'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={product_5_pros}
                    onChange={(e: any) => { setProduct_5_pros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-5'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={product_5_cons}
                    onChange={(e: any) => { setProduct_5_cons(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-5'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={product_5_miniDescription}
                    onChange={(e: any) => { setProduct_5_miniDescription(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue' }}>6.[{originalProducts?.[5]?.product?.asin}] {product_6_displayName}</div>
                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-product-display-name-6'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={product_6_displayName}
                    onChange={(e: any) => { setProduct_6_displayName(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-6'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={product_6_pros}
                    onChange={(e: any) => { setProduct_6_pros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-6'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={product_6_cons}
                    onChange={(e: any) => { setProduct_6_cons(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-6'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={product_6_miniDescription}
                    onChange={(e: any) => { setProduct_6_miniDescription(e.target.value) }}
                />


                <div className='divider-dotted' />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue' }}>7.[{originalProducts?.[6]?.product?.asin}] {product_7_displayName}</div>
                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-product-display-name-7'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={product_7_displayName}
                    onChange={(e: any) => { setProduct_7_displayName(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-7'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={product_7_pros}
                    onChange={(e: any) => { setProduct_7_pros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-7'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={product_7_cons}
                    onChange={(e: any) => { setProduct_7_cons(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-7'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={product_7_miniDescription}
                    onChange={(e: any) => { setProduct_7_miniDescription(e.target.value) }}
                />


                <div className='divider-dotted' />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue' }}>8.[{originalProducts?.[7]?.product?.asin}] {product_8_displayName}</div>
                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-product-display-name-8'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={product_8_displayName}
                    onChange={(e: any) => { setProduct_8_displayName(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-8'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={product_8_pros}
                    onChange={(e: any) => { setProduct_8_pros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-8'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={product_8_cons}
                    onChange={(e: any) => { setProduct_8_cons(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-8'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={product_8_miniDescription}
                    onChange={(e: any) => { setProduct_8_miniDescription(e.target.value) }}
                />


                <div className='divider-dotted' />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue' }}>9.[{originalProducts?.[8]?.product?.asin}] {product_9_displayName}</div>
                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-product-display-name-9'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={product_9_displayName}
                    onChange={(e: any) => { setProduct_9_displayName(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-9'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={product_9_pros}
                    onChange={(e: any) => { setProduct_9_pros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-9'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={product_9_cons}
                    onChange={(e: any) => { setProduct_9_cons(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-9'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={product_9_miniDescription}
                    onChange={(e: any) => { setProduct_9_miniDescription(e.target.value) }}
                />


                <div className='divider-dotted' />
                <div style={{ fontSize: '16px', fontWeight: 'bold', color: 'darkblue' }}>10.[{originalProducts?.[9]?.product?.asin}] {product_10_displayName}</div>
                <div className='divider-dotted' />

                <Form.Field
                    id='form-textarea-control-product-display-name-10'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={product_10_displayName}
                    onChange={(e: any) => { setProduct_10_displayName(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-pros-10'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={product_10_pros}
                    onChange={(e: any) => { setProduct_10_pros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons-10'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={product_10_cons}
                    onChange={(e: any) => { setProduct_10_cons(e.target.value) }}
                />

                <Form.Field
                    id='form-textarea-control-product-mini-description-10'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={product_10_miniDescription}
                    onChange={(e: any) => { setProduct_10_miniDescription(e.target.value) }}
                />

                <div className='update-btn' style={{ marginTop: '100px' }}>
                    <Form.Field
                        id='form-button-control-update-btn'
                        control={Button}
                        content='Update Comparison Page Data'
                        disabled={id === -1}
                        onClick={() => updateComparisonPageData()}
                    />
                </div>
            </Form>

        </div>
    );

}

export default JpostManualEditorComponent;
