import { useContext, useEffect, useState } from 'react';
import {
  AmazonProduct,
  AmazonReport,
  AmazonReportWithAdditions,
  ComparisonPage,
  Keyword,
  Product,
} from '../../Modals/ContextObj';
import { Button, Checkbox, Icon, Input, InputOnChangeData, Statistic, Table } from 'semantic-ui-react';
import _ from 'lodash';
import consts, { getPathFromCategories } from '../../Helpers/consts';
import './JpostAmazonReportsComponent.scss';
import { toast } from 'react-hot-toast';
import { appContext } from '../../App';
import { downloadUnicodeCSV } from '../../Helpers/csvHelper';
import readXlsxFile from 'read-excel-file';
import { cellDataType } from '../../Helpers/customTypes';
import { addAmazonReportItems, generateOptimizeArticlesReport, getAmazonReportByDates } from '../../Helpers/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface JpostAmazonReportsComponentProps {
  selectedWebsiteId: number;
}

const JpostAmazonReportsComponent = (props: JpostAmazonReportsComponentProps) => {
  const { setIsLoading } = useContext(appContext);
  const [uploadedFile, setUploadedFile] = useState<string | null>();
  const [originalFileHeaders, setOriginalFileHeaders] = useState<cellDataType[]>([]);
  const [originalFileRows, setOriginalFileRows] = useState<cellDataType[][]>([]);

  const [fromDate, setFromDate] = useState(new Date(2023, 5, 1));
  const [toDate, setToDate] = useState(new Date());

  const [originalAmazonReports, setOriginalAmazonReports] = useState<AmazonReport[]>([]);
  const [amazonReports, setAmazonReports] = useState<AmazonReport[]>([]);

  const [catalogOrders, setCatalogOrders] = useState(0);
  const [catalogTotalRevenue, setCatalogTotalRevenue] = useState(0);
  const [catalogEarnings, setCatalogEarnings] = useState(0);

  const [affiliatorOrders, setAffiliatorOrders] = useState(0);
  const [affiliatorTotalRevenue, setAffiliatorTotalRevenue] = useState(0);
  const [affiliatorEarnings, setAffiliatorEarnings] = useState(0);

  const [levantaOrders, setLevantaOrders] = useState(0);
  const [levantaTotalRevenue, setLevantaTotalRevenue] = useState(0);
  const [levantaEarnings, setLevantaEarnings] = useState(0);

  const [costingtonsOrders, setCostingtonsOrders] = useState(0);
  const [costingtonsTotalRevenue, setCostingtonsTotalRevenue] = useState(0);
  const [costingtonsEarnings, setCostingtonsEarnings] = useState(0);

  const [otherOrders, setOtherOrders] = useState(0);
  const [otherTotalRevenue, setOtherTotalRevenue] = useState(0);
  const [otherEarnings, setOtherEarnings] = useState(0);

  const [cookiesOrders, setCookiesOrders] = useState(0);
  const [cookiesTotalRevenue, setCookiesTotalRevenue] = useState(0);
  const [cookiesEarnings, setCookiesEarnings] = useState(0);

  const [asinsArr, setAsinsArr] = useState<{ asin: string; affiliateType: string; count: number }[]>([]);
  const [comparisonPagesArr, setComparisonPagesArr] = useState<
    { keyword: string; productionPageUrl: string; count: number }[]
  >([]);

  useEffect(() => {
    getAllReportsData();
  }, []);

  useEffect(() => {
    const datesAmazonReport = originalAmazonReports.filter(
      (a) => new Date(a.dateShipped) >= fromDate && new Date(a.dateShipped) <= toDate,
    );

    setAmazonReports(datesAmazonReport);

    const datesAsinsArr: {
      asin: string;
      affiliateType: string;
      count: number;
    }[] = [];

    datesAmazonReport.forEach((row) => {
      const idx = datesAsinsArr.findIndex((a) => a.asin === row.asin);
      if (idx !== -1) {
        datesAsinsArr[idx].count += 1;
      } else {
        datesAsinsArr.push({
          asin: row.asin,
          affiliateType: row.affiliateType,
          count: 1,
        });
      }
    });

    setAsinsArr(datesAsinsArr.sort((a, b) => b.count - a.count));

    const comparisonPagesArr: {
      keyword: string;
      productionPageUrl: string;
      count: number;
    }[] = [];

    /* datesAmazonReport.forEach(row => {

            if (JSON.parse(row.comparisonPagesData).length > 0) {
                JSON.parse(row.comparisonPagesData).forEach((obj: { keyword: string, productionPageUrl: string }) => {

                    const idx = comparisonPagesArr.findIndex(a => a.keyword === obj.keyword);
                    if (idx !== -1) {
                        comparisonPagesArr[idx].count += 1;
                    } else {
                        comparisonPagesArr.push({ keyword: obj.keyword, productionPageUrl: obj.productionPageUrl, count: 1 });
                    }
                })
            }
        });

        setComparisonPagesArr(comparisonPagesArr.sort((a, b) => b.count - a.count)); */

    // ##### CATALOG DATA ##### //

    setCatalogOrders(datesAmazonReport.filter((a) => a.affiliateType === 'CATALOG').length || 0);
    setCatalogTotalRevenue(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'CATALOG')
        ?.reduce((accumulator, object) => {
          return accumulator + object.revenue;
        }, 0) || 0,
    );
    setCatalogEarnings(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'CATALOG')
        ?.reduce((accumulator, object) => {
          return accumulator + object.adFees + object.revenue * 0.08;
        }, 0) || 0,
    );

    // ##### AFFILIATOR DATA ##### //

    setAffiliatorOrders(datesAmazonReport.filter((a) => a.affiliateType === 'AFFILIATOR').length || 0);
    setAffiliatorTotalRevenue(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'AFFILIATOR')
        ?.reduce((accumulator, object) => {
          return accumulator + object.revenue;
        }, 0) || 0,
    );
    setAffiliatorEarnings(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'AFFILIATOR')
        ?.reduce((accumulator, object) => {
          return accumulator + object.adFees + object.revenue * 0.1;
        }, 0) || 0,
    );

    // ##### LEVANTA DATA ##### //

    setLevantaOrders(datesAmazonReport.filter((a) => a.affiliateType === 'LEVANTA').length || 0);
    setLevantaTotalRevenue(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'LEVANTA')
        ?.reduce((accumulator, object) => {
          return accumulator + object.revenue;
        }, 0) || 0,
    );
    setLevantaEarnings(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'LEVANTA')
        ?.reduce((accumulator, object) => {
          return accumulator + object.adFees + object.revenue * 0.15;
        }, 0) || 0,
    );

    setCostingtonsOrders(datesAmazonReport.filter((a) => a.affiliateType === 'COSTINGTONS').length || 0);
    setCostingtonsTotalRevenue(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'COSTINGTONS')
        ?.reduce((accumulator, object) => {
          return accumulator + object.revenue;
        }, 0) || 0,
    );
    setCostingtonsEarnings(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'COSTINGTONS')
        ?.reduce((accumulator, object) => {
          return accumulator + object.adFees + object.revenue * 0.15;
        }, 0) || 0,
    );

    // ##### OTHER DATA ##### //

    setOtherOrders(datesAmazonReport.filter((a) => a.affiliateType === 'OTHER').length || 0);
    setOtherTotalRevenue(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'OTHER')
        ?.reduce((accumulator, object) => {
          return accumulator + object.revenue;
        }, 0) || 0,
    );
    setOtherEarnings(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'OTHER')
        ?.reduce((accumulator, object) => {
          return accumulator + object.adFees;
        }, 0) || 0,
    );

    // ##### COOKIES DATA ##### //

    setCookiesOrders(datesAmazonReport.filter((a) => a.affiliateType === 'COOKIES').length || 0);
    setCookiesTotalRevenue(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'COOKIES')
        ?.reduce((accumulator, object) => {
          return accumulator + object.revenue;
        }, 0) || 0,
    );
    setCookiesEarnings(
      datesAmazonReport
        .filter((a) => a.affiliateType === 'COOKIES')
        ?.reduce((accumulator, object) => {
          return accumulator + object.adFees;
        }, 0) || 0,
    );
  }, [fromDate, toDate, originalAmazonReports]);

  const getAllReportsData = async () => {
    setIsLoading(true);
    const res: AmazonReport[] = await getAmazonReportByDates({
      websiteId: props.selectedWebsiteId,
      fromDate,
      toDate,
    });
    setOriginalAmazonReports(res);
    setAmazonReports(res);
    setIsLoading(false);
  };

  const handleGenerateBtnClicked = async () => {
    let finalRows: any[] = [];
    for (let i = 0; i < originalFileRows.length; i++) {
      const objToPush: any = {};
      for (let j = 0; j < originalFileHeaders.length; j++) {
        objToPush[originalFileHeaders[j].toString()] = originalFileRows[i][j];
      }
      finalRows.push(objToPush);
    }

    toast.success('Starting the process, it will take a while..');

    setIsLoading(true);

    await addAmazonReportItems({
      websiteId: props.selectedWebsiteId,
      items: finalRows,
    });

    setIsLoading(false);

    toast.success(`Added Successfully ${finalRows.length} report rows!`);
  };

  const handleFileUploaded = async (input: any) => {
    setUploadedFile(input.name);
    const rowsWithHeaders: cellDataType[][] = await readXlsxFile(input);
    const headers = rowsWithHeaders?.[0];
    const rows = rowsWithHeaders.slice(1, rowsWithHeaders.length);
    setOriginalFileHeaders(headers);
    setOriginalFileRows(rows);
  };

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getOptimizeReportByDates = async () => {
    try {
      setIsLoading(true);
      // const res: { pageUrl: string, sum: number, totalOrders: number, totalEarnings: number, epc: number, items: { asin: string, affiliateType: string, clicks: number, productEPC: number, amazonOrders: number, earnings: number, articlePosition: number, rcmndPosition: number }[] }[]

      const res: {
        pageUrl: string;
        articleTotalClicks: number;
        articleTotalOrders: number;
        articleTotalOrdersByParentAsin: number;
        totalClicksByParentAsin: number;
        articleTotalEarnings: number;
        articleTotalEarningsByParentAsin: number;
        articleEPC: number;
        articleEPCByParentAsin: number;
        items: {
          asin: string;
          affiliateType: string;
          productClicks: number;
          productClicksByParentAsin: number;
          productEPC: number;
          productEarnings: number;
          productEPCByParentAsin: number;
          productAmazonOrders: number;
          productAmazonOrdersByParentAsin: number;
          productEarningsByParentAsin: number;
          articlePosition: number;
          rcmndPosition: number;
        }[];
      }[] = await generateOptimizeArticlesReport({
        websiteId: props.selectedWebsiteId,
        fromDate,
        toDate,
        //amazonOrders: amazonReports
      });

      toast.success('In progress..');
      /*         let finalList = [];

            const columnsWithAdditionals = ['Comparison Pages', 
            'Article Total Clicks', 'Article Total Orders', 'Article Total Earnings', 'Article EPC', 
            'Article Total Clicks By Parent ASIN', 'Article Total Orders By Parent ASIN', 'Article Total Earnings By Parent ASIN', 'Article EPC By Parent ASIN', 
            'ASIN', 'Affiliate Type', 'Product Clicks', 'Product Amazon Orders', 'Product Earnings', 'Product EPC', 
            'Product ParentASIN Clicks', 'Product ParentASIN Amazon Orders', 'Product ParentASIN Earnings', 'Product ParentASIN EPC',
            'Article Position', 'Recommended Position'];
            finalList.push(columnsWithAdditionals);
            res.forEach((mainItem) => {
                mainItem.items.forEach((item) => {
                    finalList.push([`https://www.jpost.com${mainItem.pageUrl}`, 
                    mainItem.articleTotalClicks, mainItem.articleTotalOrders, mainItem.articleTotalEarnings, mainItem.articleEPC, 
                    mainItem.totalClicksByParentAsin, mainItem.articleTotalOrdersByParentAsin, mainItem.articleTotalEarningsByParentAsin, mainItem.articleEPCByParentAsin,
                    item.asin, item.affiliateType, item.productClicks, item.productAmazonOrders, item.productEarnings, item.productEPC, 
                    item.productClicksByParentAsin, item.productAmazonOrdersByParentAsin, item.productEarningsByParentAsin, item.productEPCByParentAsin,
                    item.articlePosition, item.rcmndPosition]);
                })

                finalList.push(['', '', '', '', '', '', '', '', '']);
            });

            downloadUnicodeCSV(`OptimizationReport-${formatDate(fromDate)}-TO-${formatDate(toDate)}.csv`, finalList);
 */
      setIsLoading(false);
      toast.success('Downloaded successfully!');
    } catch (e) {
      setIsLoading(false);
      toast.error('Failed to download AmazonReportData data');
    }
  };

  return (
    <div className="amazon-report-container">
      <div className="import-csv-container">
        <div className="title">
          Upload new Report Data into DB:
          <Button
            icon="download"
            content="Download Import File Template"
            onClick={() =>
              downloadUnicodeCSV('Input_File_For_Bulk_Amazon_Report.csv', [consts.inputXlsxHeadersAddAmazonReport])
            }
          />
        </div>

        <div className="file-upload-container">
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={(e) => handleFileUploaded(e?.target?.files?.[0])}
            title={' '}
          />

          {uploadedFile && (
            <>
              <span>
                {' '}
                File Name: <b>{uploadedFile}</b> <br />
                {`${originalFileHeaders.length} Columns & ${originalFileRows.length} Rows`}
              </span>

              <Button
                content="Save Report Data to DB"
                icon="upload"
                labelPosition="left"
                onClick={() => handleGenerateBtnClicked()}
              />
            </>
          )}
        </div>
      </div>

      <div className="dates-container">
        <div>From Date:</div>
        <DatePicker selected={fromDate} onChange={(date: any) => setFromDate(date)} />
        <div>To Date:</div>
        <DatePicker selected={toDate} onChange={(date: any) => setToDate(date)} />
      </div>

      <Button
        style={{ alignSelf: 'center', backgroundColor: 'darksalmon' }}
        icon="graph"
        content="Generate Optimize Report"
        onClick={() => getOptimizeReportByDates()}
      />

      <div className="statistics">
        <div className="row1">
          <div className="title">
            <span>Catalog:</span>
            <span style={{ fontSize: '12px', color: 'gray' }}>Ad Fees + 8%</span>
          </div>
          <Statistic size="tiny" color="blue">
            <Statistic.Value>{catalogOrders}</Statistic.Value>
            <Statistic.Label>Orders</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="teal">
            <Statistic.Value>${catalogTotalRevenue.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Total Revenue</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="green">
            <Statistic.Value>${catalogEarnings.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Earnings</Statistic.Label>
          </Statistic>
        </div>

        <div className="row1">
          <div className="title">
            <span>Affiliator:</span>
            <span style={{ fontSize: '12px', color: 'gray' }}>Ad Fees + 10%</span>
          </div>
          <Statistic size="tiny" color="blue">
            <Statistic.Value>{affiliatorOrders}</Statistic.Value>
            <Statistic.Label>Orders</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="teal">
            <Statistic.Value>${affiliatorTotalRevenue.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Total Revenue</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="green">
            <Statistic.Value>${affiliatorEarnings.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Earnings</Statistic.Label>
          </Statistic>
        </div>

        <div className="row1">
          <div className="title">
            <span>Levanta:</span>
            <span style={{ fontSize: '12px', color: 'gray' }}>Ad Fees + 15%</span>
          </div>
          <Statistic size="tiny" color="blue">
            <Statistic.Value>{levantaOrders}</Statistic.Value>
            <Statistic.Label>Orders</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="teal">
            <Statistic.Value>${levantaTotalRevenue.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Total Revenue</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="green">
            <Statistic.Value>${levantaEarnings.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Earnings</Statistic.Label>
          </Statistic>
        </div>

        <div className="row1">
          <div className="title">
            <span>Costingtons:</span>
            <span style={{ fontSize: '12px', color: 'gray' }}>Ad Fees + 15%</span>
          </div>
          <Statistic size="tiny" color="blue">
            <Statistic.Value>{costingtonsOrders}</Statistic.Value>
            <Statistic.Label>Orders</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="teal">
            <Statistic.Value>${costingtonsTotalRevenue.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Total Revenue</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="green">
            <Statistic.Value>${costingtonsEarnings.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Earnings</Statistic.Label>
          </Statistic>
        </div>

        <div className="row1">
          <div className="title">
            <span>Other:</span>
            <span style={{ fontSize: '12px', color: 'gray' }}>Ad Fees</span>
          </div>
          <Statistic size="tiny" color="blue">
            <Statistic.Value>{otherOrders}</Statistic.Value>
            <Statistic.Label>Orders</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="teal">
            <Statistic.Value>${otherTotalRevenue.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Total Revenue</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="green">
            <Statistic.Value>${otherEarnings.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Earnings</Statistic.Label>
          </Statistic>
        </div>

        <div className="row1">
          <div className="title">
            <span>Cookies:</span>
            <span style={{ fontSize: '12px', color: 'gray' }}>Ad Fees</span>
          </div>
          <Statistic size="tiny" color="blue">
            <Statistic.Value>{cookiesOrders}</Statistic.Value>
            <Statistic.Label>Orders</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="teal">
            <Statistic.Value>${cookiesTotalRevenue.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Total Revenue</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="green">
            <Statistic.Value>${cookiesEarnings.toLocaleString()}</Statistic.Value>
            <Statistic.Label>Earnings</Statistic.Label>
          </Statistic>
        </div>

        <div className="row1" style={{ borderTop: '2.5px dashed lightgray' }}>
          <div className="title">
            <span>Total:</span>
          </div>
          <Statistic size="tiny" color="blue">
            <Statistic.Value>
              {(
                catalogOrders +
                affiliatorOrders +
                levantaOrders +
                costingtonsOrders +
                otherOrders +
                cookiesOrders
              ).toLocaleString()}
            </Statistic.Value>
            <Statistic.Label>Orders</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="teal">
            <Statistic.Value>
              $
              {(
                catalogTotalRevenue +
                affiliatorTotalRevenue +
                levantaTotalRevenue +
                costingtonsTotalRevenue +
                otherTotalRevenue +
                cookiesTotalRevenue
              ).toLocaleString()}
            </Statistic.Value>
            <Statistic.Label>Total Revenue</Statistic.Label>
          </Statistic>

          <Statistic size="tiny" color="green">
            <Statistic.Value>
              $
              {(
                catalogEarnings +
                affiliatorEarnings +
                levantaEarnings +
                costingtonsEarnings +
                otherEarnings +
                cookiesEarnings
              ).toLocaleString()}
            </Statistic.Value>
            <Statistic.Label>Earnings</Statistic.Label>
          </Statistic>
        </div>
      </div>

      <div className="table-title-top-container">
        <div className="table-title">Top Selling ASINs</div>
        <div className="table-container">
          <Table sortable celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>ASIN</Table.HeaderCell>
                <Table.HeaderCell width={2}>Affiliate Type</Table.HeaderCell>
                <Table.HeaderCell width={1}>Count</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {asinsArr.map((k) => (
                <Table.Row key={k.asin}>
                  <Table.Cell>
                    <a href={`https://www.amazon.com/dp/${k.asin}`} target="_blank">
                      {k.asin}
                    </a>
                  </Table.Cell>
                  <Table.Cell>{k.affiliateType}</Table.Cell>
                  <Table.Cell>{k.count}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>

      <div className="table-title-top-container">
        <div className="table-title">Top Articles</div>
        <div className="table-container" style={{ width: '800px' }}>
          <Table sortable celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>Keyword</Table.HeaderCell>
                <Table.HeaderCell width={2}>Jpost URL</Table.HeaderCell>
                <Table.HeaderCell width={1}>Count</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {comparisonPagesArr.map((k) => (
                <Table.Row key={k.keyword}>
                  <Table.Cell>{k.keyword}</Table.Cell>
                  <Table.Cell>
                    <a href={k.productionPageUrl} target="_blank">
                      {k.productionPageUrl}
                    </a>
                  </Table.Cell>
                  <Table.Cell>{k.count}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default JpostAmazonReportsComponent;
