import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Form, TextArea } from 'semantic-ui-react';
import './KoOperationsComponent.scss';
import { appContext } from '../../App';
import { addNewTaskToQueue, getAllPromptsByTagHandler, regenerateForAll, updatePromptByTagAndName } from '../../Helpers/api';
import { Website } from '../../Modals/ContextObj';

interface KoOperationsComponentProps {
  selectedWebsiteId: number;
  originalWebsites: Website[];
}

const KoOperationsComponent = (props: KoOperationsComponentProps) => {
  const { setIsLoading } = useContext(appContext);
  const [asinsForKoArticle, setAsinsForKoArticle] = useState<string>('');


  const createNewKoArticleFromAsins = async () => {
    const asins = asinsForKoArticle
      .split('\n')
      .map((a) => a.trim())
      .filter((a) => a !== '');
  
    if (asins.length === 0) {
      toast.error('No Asins found.');
      return;
    }
    
    const payload = {
      asins,
    };
    
    await addNewTaskToQueue({
      action: 'createNewKoArticleFromAsinsProcess',
      body: payload,
    });

    setAsinsForKoArticle('')
    toast.success('Process started! it will take a while. you will get an email when it is done.');
  
  };


  return (
    <div className="ko-operations-container">
      
      <div className="import-csv-container">
        <div className="title">Add ASINs for new KO article:</div>

        <Form style={{ width: '100%', textAlign: 'left', paddingTop: '20px' }}>
          <Form.Group widths={'equal'}>
            <Form.Field
              style={{ width: '250px' }}
              id="form-input-in-ko-article-asins"
              control={TextArea}
              label="ASINS"
              placeholder={`asin 1
asin 2
asin 3
...`}
              value={asinsForKoArticle}
              onChange={(e: any) => {
                setAsinsForKoArticle(e.target.value);
              }}
            />
          </Form.Group>
        </Form>
        <Button icon="add" content="Add" onClick={() => createNewKoArticleFromAsins()} />
      </div>

    </div>
  );
};

export default KoOperationsComponent;
