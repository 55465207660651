const domain = process.env.REACT_APP_DOMAIN;
const config = {
  development: {
    url: `.dev${domain}`,
  },
  staging: {
    url: `.staging${domain}`,
  },
  production: {
    url: `.prod${domain}`,
  },
  localhost: {
    url: '.ambz.dev',
  },
};

export function getDomain(url: string) {
  if (url.includes('.dev.')) {
    return config['development'].url;
  }
  if (url.includes('.stage.')) {
    return config['staging'].url;
  }
  if (url.includes('.prod.')) {
    return config['production'].url;
  }
  return '.ambz.dev';
}
