import { useState } from 'react';
import { Radio } from 'semantic-ui-react';
import { updateProduct } from '../../Helpers/api';
import toast from 'react-hot-toast';

export interface PropItem {
  partnerType: string;
  asin: string;
  websiteId: number;
  comparisonPageId: number;
}

export default function PartnerTypeSelection(props: PropItem) {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
  const setSelectedOptionAction = async (value: string) => {
    setSelectedOption(value);
    await updateProduct({
      asin: props.asin,
      partnerType: value,
      websiteId: props.websiteId,
      comparisonPageId: props.comparisonPageId,
    });
    props.partnerType = value;
    toast.success('Updated!');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
      <Radio
        onChange={(e, data) => setSelectedOptionAction(data.value as string)}
        onClick={(e) => e.stopPropagation()}
        label="Attribution"
        type="checkbox"
        value="ATTRIBUTION"
        checked={(selectedOption ?? props.partnerType) === 'ATTRIBUTION'}
      ></Radio>
      <Radio
        onChange={(e, data) => setSelectedOptionAction(data.value as string)}
        onClick={(e) => e.stopPropagation()}
        label="Associate"
        type="checkbox"
        value="ASSOCIATE"
        checked={(selectedOption ?? props.partnerType) === 'ASSOCIATE'}
      ></Radio>
      <Radio
        onChange={(e, data) => setSelectedOptionAction(data.value as string)}
        onClick={(e) => e.stopPropagation()}
        label="Auto"
        type="checkbox"
        value="AUTO"
        checked={(selectedOption ?? props.partnerType) === 'AUTO'}
      ></Radio>
    </div>
  );
}
