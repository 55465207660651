import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Accordion, AccordionContent, AccordionTitle, Button, Dropdown, Form, Icon, Input, TextArea } from 'semantic-ui-react';
import './JpostHomeComponent.scss';
import { appContext } from '../../App';
import { addTaskToQueueJob, generateJpostCompleteComparisonPagesInBulk, generateJpostCompleteComparisonPagesInBulkFromKeywords, generateJpostCompleteComparisonPagesInBulkFromLongTailKeywords, generateTrendingCompleteComparisonPagesInBulk, getAllTrendingProducts, getComparisonPagesByWebsiteId, getCtechYnetFilesByComparisonPage, getJpostDealsHtmlFileByComparisonPageId, getJpostHtmlFilesByComparisonPage, getJpostSingleProductHtmlFileByComparisonPageId, publishNewOrUpdateComparisonPageToINN, publishNewOrUpdateComparisonPageToNewsDirect, publishNewOrUpdateComparisonPageToWordpress, publishNewOrUpdateComparisonPageToWpCms, publishOrUpdateComparisonPage, scrapeAndAddNewAsinsToTrendingProduct, updateCoverImageInWP, validateArticleBeforePublish, verifyUser } from '../../Helpers/api';
import { DropDownObj, cellDataType } from '../../Helpers/customTypes';
import AppHeaderComponent from '../AppHeaderComponent/AppHeaderComponent';
import { AmazonProduct, ComparisonPage, MENU_TABS, TrendingProduct } from '../../Modals/ContextObj';
import readXlsxFile from 'read-excel-file';
import { getPathFromCategories, affiliateLinksForJpost, getVersionStringByComparisonPage, WEBSITE_DOMAIN_NAMES, ACCESS_LEVEL, WEBSITE_ID } from '../../Helpers/consts';
import JpostPromptsComponent from '../JpostPromptsComponent/JpostPromptsComponent';
import JpostProductEditComponent from '../JpostProductEditComponent/JpostProductEditComponent';
import JpostComparisonEditComponent from '../JpostComparisonEditComponent/JpostComparisonEditComponent';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import ComparisonPagesTableComponent from '../ComparisonPagesTableComponent/ComparisonPagesTableComponent';
import LoginComponent from '../LoginComponent/LoginComponent';
import { useNavigate } from 'react-router-dom';
import JpostAmazonReportsComponent from '../JpostAmazonReportsComponent/JpostAmazonReportsComponent';
import DuplicateArticleComponent from '../DuplicateArticleComponent/DuplicateArticleComponent';
import JpostManualEditorComponent from '../JpostManualEditorComponent/JpostManualEditorComponent';
import { downloadUnicodeCSV } from '../../Helpers/csvHelper';
import CampaignReportsComponent from '../CampaignReportsComponent/CampaignReportsComponent';
import VideoEditorComponent from '../ComparisonEditComponent copy/VideoEditorComponent';
import axios from 'axios';
import EditArticleComponent from '../EditArticleComponent/EditArticleComponent';


interface JpostHomeComponentProps {
  websiteId: number,
  domain: string
}


export const publishComparisonPageToWordpress = async (cp: ComparisonPage | undefined, selectedLimit: string, setIsLoading: any) => {

  try {

    setIsLoading(true);

    const validationResp = await validateArticleBeforePublish({ keyword: cp?.keyword, websiteId: cp?.websiteId, version: cp?.version });
    if (validationResp !== '') {
      setIsLoading(false);
      toast.error(validationResp, { duration: 12000 });
      return
    }

    const response = await publishNewOrUpdateComparisonPageToWordpress({ comparisonPage: cp, limit: parseInt(selectedLimit) });
    setIsLoading(false);
    toast.success('Uploaded Successfully!');
    window.location.reload();
  } catch (error: any) {
    console.error('Error:', error?.message);
    toast.success('Upload Failed!');
  }

}

export const publishComparisonPageToWpCms = async (cp: ComparisonPage | undefined, selectedLimit: string, setIsLoading: any) => {

  try {

    setIsLoading(true);

    const validationResp = await validateArticleBeforePublish({ keyword: cp?.keyword, websiteId: cp?.websiteId, version: cp?.version });
    if (validationResp !== '') {
      setIsLoading(false);
      toast.error(validationResp, { duration: 12000 });
      return;
    }

    const response = await publishNewOrUpdateComparisonPageToWpCms({ comparisonPage: cp, limit: parseInt(selectedLimit) });
    setIsLoading(false);
    toast.success('Uploaded Successfully!');
    window.location.reload();
  } catch (error: any) {
    console.error('Error:', error?.message);
    toast.success('Upload Failed!');
  }

}

export const parentPublishComparisonPageINN = async (cp: ComparisonPage | undefined, selectedLimit: string, setIsLoading: any) => {

  try {
    setIsLoading(true);
    const validationResp = await validateArticleBeforePublish({ keyword: cp?.keyword, websiteId: cp?.websiteId, version: cp?.version });
    if (validationResp !== '') {
      setIsLoading(false);
      toast.error(validationResp, { duration: 12000 });
      return
    }

    const response = await publishNewOrUpdateComparisonPageToINN({ comparisonPage: cp, limit: parseInt(selectedLimit) });
    setIsLoading(false);
    toast.success('Uploaded Successfully!');
    window.location.reload();
  } catch (error: any) {
    console.error('Error:', error?.message);
    toast.success('Upload Failed!');
  }

}

export const updateImageToWPCms = async (cp: ComparisonPage | undefined, setIsLoading: any) => {

  try {

    setIsLoading(true);
    const response = await updateCoverImageInWP({ websiteId: cp?.websiteId, url: cp?.categoryImage, pageId: cp?.wordpressPageId, title: cp?.title, teaser: cp?.teaser });
    setIsLoading(false);
    toast.success('updated Successfully!');
    window.location.reload();
  } catch (error: any) {
    console.error('Error:', error?.message);
    toast.success('Upload Failed!');
  }


}
export const parentPublishComparisonPageNewsDirect = async (cp: ComparisonPage | undefined, selectedLimit: string, setIsLoading: any) => {

  try {

    setIsLoading(true);

    const validationResp = await validateArticleBeforePublish({ keyword: cp?.keyword, websiteId: cp?.websiteId, version: cp?.version });
    if (validationResp !== '') {
      setIsLoading(false);
      toast.error(validationResp, { duration: 12000 });
      return
    }

    const response = await publishNewOrUpdateComparisonPageToNewsDirect({ comparisonPage: cp, limit: parseInt(selectedLimit) });
    setIsLoading(false);
    toast.success('Uploaded Successfully!');
    window.location.reload();
  } catch (error: any) {
    console.error('Error:', error?.message);
    toast.success('Upload Failed!');
  }

}

export const parentCtechYnetDownloadZipComparisonPage = async (cp: ComparisonPage | undefined, setIsLoading: any) => {
  try {

    setIsLoading(true);

    const validationResp = await validateArticleBeforePublish({ keyword: cp?.keyword, websiteId: cp?.websiteId, version: cp?.version });
    if (validationResp !== '') {
      setIsLoading(false);
      toast.error(validationResp, { duration: 12000 });
      return
    }

    const response: any = await getCtechYnetFilesByComparisonPage({ comparisonPage: cp });
    const blob = new Blob([response.data], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'files.zip');

    // Simulate a click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);

    setIsLoading(false);

  } catch (error) {
    console.error('Error:', error);
    // Handle error if the API call fails
    setIsLoading(false);
  }
}


export const downloadLayoutsFiles = async (cp: ComparisonPage | undefined, selectedAffiliateLink: string, selectedLimit: string, userAccLevel: string, setIsLoading: any) => {

  if (userAccLevel === ACCESS_LEVEL.ADMIN) {
    setIsLoading(true);

    if (cp?.comparisonPageType === 'COMPARISON' || cp?.comparisonPageType === 'BLOG') {
      const validationResp = await validateArticleBeforePublish({ keyword: cp?.keyword, websiteId: cp?.websiteId, version: cp?.version });
      if (validationResp !== '') {
        setIsLoading(false);
        toast.error(validationResp, { duration: 12000 });
        return
      }
    }

    const response = await publishOrUpdateComparisonPage({ comparisonPage: cp, limit: parseInt(selectedLimit) });
    setIsLoading(false);
    return;
  }



  if (cp?.comparisonPageType === 'COMPARISON' || cp?.comparisonPageType === 'BLOG') {

    try {

      const validationResp = await validateArticleBeforePublish({ keyword: cp.keyword, websiteId: cp.websiteId, version: cp.version });
      if (validationResp !== '') {
        toast.error(validationResp, { duration: 12000 });
        return
      }

      const response = await getJpostHtmlFilesByComparisonPage({ comparisonPage: cp, affiliateLink: selectedAffiliateLink, limit: parseInt(selectedLimit) });

      //const response = await publishOrUpdateComparisonPage({ comparisonPage: cp, limit: parseInt(selectedLimit) });

      // Create a zip file
      const zip = new JSZip();
      zip.file('multiTemplate1.html', response.multiTemplate1);
      zip.file('multiTemplate2.html', response.multiTemplate2);
      zip.file('multiTemplate3.html', response.multiTemplate3);
      zip.file('multiTemplate4.html', response.multiTemplate4);

      response.productsTemplates.forEach((productContent: string, idx: number) => {
        zip.file(`singleTemplate${idx + 1}.html`, productContent);
      })

      try {

        const imageBlob = await fetch(cp.categoryImage).then(response => response.blob());
        zip.file(`${cp.keyword}_cover_image.jpg`, imageBlob, { binary: true });
      } catch (e: any) {
        console.log(e.message);
      }


      // Generate the zip file
      const content = await zip.generateAsync({ type: 'blob' });

      // Save the zip file
      FileSaver.saveAs(content, `${cp.keyword}.zip`);
    } catch (error) {
      console.error('Error:', error);
      // Handle error if the API call fails
    }
  } else if (cp?.comparisonPageType === 'SINGLE') {

    const response = await getJpostSingleProductHtmlFileByComparisonPageId({ comparisonPageId: cp.id, version: cp.version });

    // Create a zip file
    const zip = new JSZip();
    zip.file('singlePage.html', response.productsTemplate);

    // Generate the zip file
    const content = await zip.generateAsync({ type: 'blob' });

    // Save the zip file
    FileSaver.saveAs(content, `${cp.keyword}.zip`);
  } else if (cp?.comparisonPageType === 'TRENDING') {

    const response = await getJpostDealsHtmlFileByComparisonPageId({ comparisonPageId: cp.id, version: cp.version });

    // Create a zip file
    const zip = new JSZip();
    zip.file('dealsPage.html', response.dealsTemplate);

    // Generate the zip file
    const content = await zip.generateAsync({ type: 'blob' });

    // Save the zip file
    FileSaver.saveAs(content, `${cp.keyword}.zip`);
  }


}


const JpostHomeComponent = (props: JpostHomeComponentProps) => {

  const navigate = useNavigate();
  const { currentTab, setIsLoading } = useContext(appContext);

  const [accordionIndex, setAccordionIndex] = useState(-1);


  const { userAccessLevel, updateUserAccessLevel } = useContext(appContext);
  const { userLoggedIn, updateUserLoggedIn } = useContext(appContext);
  const [firstInitial, setFirstInitial] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [selectedWebsite, setSelectedWebsite] = useState<string>(props.domain);
  const [selectedWebsiteId, setSelectedWebsiteId] = useState<number>(props.websiteId);

  const [uploadedFile, setUploadedFile] = useState<string | null>();
  const [originalFileRows, setOriginalFileRows] = useState<cellDataType[][]>([]);
  const [uploadedFileLongTail, setUploadedFileLongTail] = useState<string | null>();
  const [uploadedFileBulkArticles, setUploadedFileBulkArticles] = useState<string | null>();
  const [originalFileRowsBulkArticles, setOriginalFileRowsBulkArticles] = useState<cellDataType[][]>([]);
  const [originalFileRowsLongTail, setOriginalFileRowsLongTail] = useState<cellDataType[][]>([]);
  const [uploadedFileTrending, setUploadedFileTrending] = useState<string | null>();
  const [originalFileRowsTrending, setOriginalFileRowsTrending] = useState<cellDataType[][]>([]);

  const [comparisonPagesByWebsite, setComparisonPagesByWebsite] = useState<ComparisonPage[]>([]);
  const [urlsDropDown, setUrlsDropDown] = useState<DropDownObj[]>([]);

  const [selectedAffiliateLink, setSelectedAffiliateLink] = useState<string>('');
  const [selectedLimit, setSelectedLimit] = useState<string>('8');
  const [keywordList, setKeywordList] = useState<string>('');
  const [keywordLongTailList, setKeywordLongTailList] = useState<string>('');
  const [keywordDealsList, setKeywordDealsList] = useState<string>('');

  const [selectedDealsLimit, setSelectedDealsLimit] = useState<string>('15');
  const [newAsinsList, setNewAsinsList] = useState<string>('');

  const [bulkActionUrls, setBulkActionUrls] = useState<string>('');
  const [asin1, setAsin1] = useState('');
  const [asin2, setAsin2] = useState('');
  const [excludedAsins, setExcludedAsins] = useState('');
  const [excludedWords, setExcludedWords] = useState('');
  const [amazonCategoryId, setAmazonCategoryId] = useState('');

  const [dsaMainKw, setDsaMainKw] = useState('');
  const [dsaCampaignName, setDsaCampaignName] = useState('');
  const [dsaRoas, setDsaRoas] = useState(0.6);
  const [dsaBudget, setDsaBudget] = useState(30);


  useEffect(() => {

    initPage();

  }, [])

  const initPage = async () => {
    setIsLoading(true);
    const logged = await checkIfUserLoggedIn();
    updateUserLoggedIn(logged);
    setFirstInitial(false)
    await loadComparisonPages(250);
    setIsLoading(false);

  }

  const loadComparisonPages = async (limit?: number) => {
    setIsLoading(true);
    const comparisonPagesReps: ComparisonPage[] = await getComparisonPagesByWebsiteId(props.websiteId, limit);
    setComparisonPagesByWebsite(comparisonPagesReps);
    setIsLoading(false);
  }

  useEffect(() => {
    let tmpUrls = comparisonPagesByWebsite.map(c => ({
      key: c.id,
      text: getPathFromCategories(c) + getVersionStringByComparisonPage(c),
      value: getPathFromCategories(c) + getVersionStringByComparisonPage(c),
    }));

    setUrlsDropDown(tmpUrls);

  }, [comparisonPagesByWebsite]);

  const checkIfUserLoggedIn = async () => {

    const res = await verifyUser();
    console.log(res);
    if (res?.success && res?.verified) {
      //login success
      updateUserAccessLevel(res.accessLevel);

      if (props.domain === WEBSITE_DOMAIN_NAMES.JPOST && res.accessLevel.indexOf('JPOST') !== -1) {
        window.history.pushState(null, '', '/jpost');
        navigate('/jpost');
      } else if (props.domain === WEBSITE_DOMAIN_NAMES.GUIDE_TOP_REVIEW && res.accessLevel.indexOf('GUIDETOPREVIEW') !== -1) {
        window.history.pushState(null, '', '/guidetopreview');
        navigate('/guidetopreview');
      } else if (props.domain === WEBSITE_DOMAIN_NAMES.BUYEREVIEWS && res.accessLevel.indexOf('BUYEREVIEWS') !== -1) {
        window.history.pushState(null, '', '/buyereviews');
        navigate('/buyereviews');
      }
      else {
        if (res.accessLevel.indexOf('AMOBEEZ') !== -1) {
          window.history.pushState(null, '', '/amobeez');
          navigate('/amobeez');
        }
      }


      return true;
    }
    else {
      if (res?.success === false) {
        toast.error(`Error login - ${res.message}`);
        return false;
      }
    }

    toast.error(`Session expired, need to login again`);

    return false;
  }

  const handleFileUploaded = async (input: any) => {
    setUploadedFile(input.name);
    const rows: cellDataType[][] = await readXlsxFile(input);
    setOriginalFileRows(rows);
  }

  const handleLongTailFileUploaded = async (input: any) => {
    setUploadedFileLongTail(input.name);
    const rows: cellDataType[][] = await readXlsxFile(input);
    setOriginalFileRowsLongTail(rows);
  }

  const handleBulkArticlesFileUploaded = async (input: any) => {
    setUploadedFileBulkArticles(input.name);
    const rows: cellDataType[][] = await readXlsxFile(input);
    setOriginalFileRowsBulkArticles(rows);
  }

  const handleTrendingFileUploaded = async (input: any) => {
    setUploadedFileTrending(input.name);
    const rows: cellDataType[][] = await readXlsxFile(input);
    setOriginalFileRowsTrending(rows);
  }

  const handleImportAsinsClicked = async () => {
    let prepareComparisonRows: { asins: cellDataType[] }[] = [];
    for (let i = 0; i < originalFileRows.length; i++) {

      prepareComparisonRows.push({ asins: originalFileRows[i] });
    }

    const payload = {
      websiteId: selectedWebsiteId,
      prepareComparisonRows
    }

    toast.success('Starting the process, it will take a while..');
    // await generateJpostCompleteComparisonPagesInBulk(payload);
    await addTaskToQueueJob({
      websiteId: selectedWebsiteId,
      action: 'generateJpostCompleteComparisonPagesInBulk',
      params: payload
    });

    toast.success('Updated Successfully!');
  }

  const handleTrendingImportAsinsClicked = async () => {
    let prepareComparisonRows: { asins: cellDataType[] }[] = [];
    for (let i = 0; i < originalFileRowsTrending.length; i++) {

      prepareComparisonRows.push({ asins: originalFileRowsTrending[i] });
    }

    const payload = {
      websiteId: selectedWebsiteId,
      prepareComparisonRows
    }

    toast.success('Starting the process, it will take a while..');
    // await generateTrendingCompleteComparisonPagesInBulk(payload);
    await addTaskToQueueJob({
      websiteId: selectedWebsiteId,
      action: 'generateTrendingCompleteComparisonPagesInBulk',
      params: payload
    });

    toast.success('Updated Successfully!');
  }

  const handleImportAsinsLongTailtkClicked = async () => {
    let prepareComparisonRows: { asins: cellDataType[] }[] = [];
    for (let i = 0; i < originalFileRowsLongTail.length; i++) {

      prepareComparisonRows.push({ asins: originalFileRowsLongTail[i] });
    }

    const payload = {
      websiteId: selectedWebsiteId,
      prepareComparisonRows,
      includeLongtailKeywords: true
    }

    toast.success('Starting the process, it will take a while..');
    // await generateJpostCompleteComparisonPagesInBulk(payload);
    await addTaskToQueueJob({
      websiteId: selectedWebsiteId,
      action: 'generateJpostCompleteComparisonPagesInBulk',
      params: payload
    });

    toast.success('Updated Successfully!');
  }

  const handleImportBulkArticlesClicked = async () => {
    let keywordAsinsList: { keyword: string, asins: string[] }[] = [];
    for (let i = 0; i < originalFileRowsBulkArticles.length; i++) {
      const keyword = originalFileRowsBulkArticles[i].shift();

      keywordAsinsList.push({ keyword: keyword ? keyword?.toString() : '', asins: originalFileRowsBulkArticles[i].map(String) });
    }
    
    const payload = {
      websiteId: selectedWebsiteId,
      keywordAsinsList,
    }

    toast.success('Starting the process, it will take a while..');
    await addTaskToQueueJob({
      websiteId: selectedWebsiteId,
      action: 'generatePaidComparisonPagesInBulkByKwAndAsins',
      params: payload
    });

    toast.success('Updated Successfully!');
  }

  /*   const handleCreateComparisonPagesFromKeywordsClicked = async () => {
  
      if (keywordList === '') {
        toast.error('keyword list is empty');
        return;
      }
  
      const payload = {
        websiteId: selectedWebsiteId,
        keywordList: keywordList.split('\n')
      }
  
  
      toast.success('Starting the process, it will take a while..');
      // await generateJpostCompleteComparisonPagesInBulkFromKeywords(payload);
      await addTaskToQueueJob({
        websiteId: selectedWebsiteId,
        action: 'generateJpostCompleteComparisonPagesInBulkFromKeywords',
        params: payload
      });
  
      toast.success('Updated Successfully!');
    } */

  const handleCreateComparisonPagesFromLongTailKeywordsClicked = async () => {

    if (keywordLongTailList === '') {
      toast.error('keyword list is empty');
      return;
    }

    const payload = {
      websiteId: selectedWebsiteId,
      keywordList: keywordLongTailList.split('\n')
    }


    toast.success('Starting the process, it will take a while..');
    await addTaskToQueueJob({
      websiteId: selectedWebsiteId,
      action: 'generateJpostCompleteComparisonPagesInBulkFromLongTailKeywords',
      params: payload
    });
    toast.success('Updated Successfully!');
    setKeywordLongTailList('');
  }

  const handleRunBulkActionsClicked = async () => {

    if (bulkActionUrls === '') {
      toast.error('URL list is empty');
      return;
    }

    const payload = {
      websiteId: selectedWebsiteId,
      bulkActionUrls: bulkActionUrls.split('\n'),
      asin1,
      asin2,
      excludedAsins,
      excludedWords,
      amazonCategoryId
    }

    toast.success('Starting the process, it will take a while..');
    await addTaskToQueueJob({
      websiteId: selectedWebsiteId,
      action: 'paidBulkActionsByUrlsAndProps',
      params: payload
    });

    setBulkActionUrls('');
  }

  const handleCreateDsaCampaignClicked = async () => {

    if (dsaMainKw === '' || dsaCampaignName === '' || !dsaRoas || !dsaBudget) {
      toast.error('Missing values..');
      return;
    }

    const payload = {
      websiteId: selectedWebsiteId,
      dsaMainKw,
      dsaCampaignName,
      dsaRoas,
      dsaBudget
    }

    toast.success('Starting the process, it will take a while..');
    await addTaskToQueueJob({
      websiteId: selectedWebsiteId,
      action: 'createDsaCampaignProcessFromMainKeyword',
      params: payload
    });

    setBulkActionUrls('');
  }

  const handleCreateTrendingDealsArticlesByKeywordsClicked = async () => {

    if (keywordDealsList === '') {
      toast.error('keyword list is empty');
      return;
    }

    const payload = {
      websiteId: selectedWebsiteId,
      keywordList: keywordDealsList.split('\n'),
      numOfItems: selectedDealsLimit
    }


    toast.success('Starting the process, it will take a while..');
    await addTaskToQueueJob({
      websiteId: selectedWebsiteId,
      action: 'generateJpostTrendingDealsArticlesByKeywords',
      params: payload
    });
    toast.success('Updated Successfully!');
    setKeywordDealsList('');
  }

  const downloadTrendingAsins = async () => {
    try {
      setIsLoading(true);

      const res = await getAllTrendingProducts();
      let finalList = [];
      finalList.push(['asin', 'link', 'rating', 'reviews', 'category1', 'category2', 'lastSubCategory', 'categoriesFlat', 'bsrNumber', 'image1', 'affiliateType']);

      res.forEach((item: AmazonProduct) => {
        let newObj = [item.asin, item.link, item.rating, item.reviews, item.category1, item.category2, item.lastSubCategory, item.categoriesFlat, item.bsrNumber, item.image1, item.affiliateType]
        finalList.push(newObj);
      });

      downloadUnicodeCSV('TrendingProducts.csv', finalList);

      setIsLoading(false);
      toast.success('Downloaded successfully!')
    } catch (e) {
      setIsLoading(false);
      toast.error('Failed to download keywords data');
    }

  }

  const handleAddNewAsinsToTrendingListClicked = async () => {
    if (newAsinsList === '') {
      toast.error('asins list is empty');
      return;
    }

    const payload = {
      asins: newAsinsList.split('\n')
    }


    toast.success('Starting the process, it will take a while..');

    await scrapeAndAddNewAsinsToTrendingProduct(payload);

    toast.success('Updated Successfully!');
    setNewAsinsList('');
  }


  const handleAccordionChange = (idx: number) => {
    if (accordionIndex === idx) {
      setAccordionIndex(-1);
    } else {
      setAccordionIndex(idx);
    }
  }


  const getPublisherNameByWebsiteId = (websiteId: number) => {
    if (websiteId === WEBSITE_ID.JPOST) {
      return 'JPOST'
    }
    if (websiteId === WEBSITE_ID.GUIDE_TOP_REVIEW) {
      return 'GUIDE TOP REVIEW';
    }
    if (websiteId === WEBSITE_ID.LENSVID) {
      return 'LENSVID';
    }
    if (websiteId === WEBSITE_ID.CTECH) {
      return 'CTECH';
    }
    if (websiteId === WEBSITE_ID.INN) {
      return 'INN';
    }
    if (websiteId === WEBSITE_ID.NEWS_DIRECT) {
      return 'NEWS_DIRECT';
    }
    if (websiteId === WEBSITE_ID.YNET) {
      return 'YNET';
    }
    if (websiteId === WEBSITE_ID.AMOBEEZ_DEMO) {
      return 'AMOBEEZ DEMO';
    }
    if (websiteId === WEBSITE_ID.YNET_NEWS) {
      return 'YNET NEWS';
    }
    if (websiteId === WEBSITE_ID.BUYEREVIEWS) {
      return 'BUYEREVIEWS';
    }
    if (websiteId === WEBSITE_ID.ISRAEL_HAYOM) {
      return 'ISRAEL HAYOM';
    }

    return 'UNKNOWN';
  }

  const downloadAllGtrUrlsFromDB = async () => {
    try {
      setIsLoading(true);

      const res: ComparisonPage[] = await getComparisonPagesByWebsiteId(props.websiteId);
      let finalList = [];
      finalList.push(['url'])
      res.forEach((item: any) => {

        let newObj = [item.productionPageUrl]

        finalList.push(newObj);
      });

      downloadUnicodeCSV('ALL_WEBSITE_URLs.csv', finalList);

      setIsLoading(false);
      toast.success('Downloaded successfully!')
    } catch (e) {
      setIsLoading(false);
      toast.error('Failed to download products data');
    }
  }


  if (firstInitial) {
    return <></>
  } else if (!userLoggedIn) {
    return <LoginComponent />
  }

  else {
    return (
      <div className='home-container'>
        <AppHeaderComponent publisherName={getPublisherNameByWebsiteId(selectedWebsiteId)} />

        <div className='home-content-container'>

          {currentTab === MENU_TABS.CREATE_COMPARISON_PAGE &&
            <>

              {userAccessLevel === ACCESS_LEVEL.ADMIN && <Accordion fluid styled>

                <AccordionTitle active={accordionIndex === 0} index={0} onClick={() => handleAccordionChange(0)}>
                  <Icon name='dropdown' /> Create Trending Article (Excel)
                </AccordionTitle>

                <AccordionContent active={accordionIndex === 0}>
                  {selectedWebsite && <div className='import-asins-csv-container'>
                    <b style={{ textAlign: 'left', width: '100%', color: 'gray' }}>Input file format: ["keyword", "asins" .....]</b>

                    <div className='file-upload-container'>
                      <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => handleTrendingFileUploaded(e?.target?.files?.[0])} title={' '} />

                      {uploadedFileTrending &&
                        <>
                          <span> File Name: <b>{uploadedFileTrending}</b> <br />{`${originalFileRowsTrending.length} Rows`}
                          </span>

                          <Button
                            icon='cloud'
                            content='Generate Trending Pages from File'
                            onClick={() => handleTrendingImportAsinsClicked()} />
                        </>
                      }
                    </div>
                  </div>}
                </AccordionContent>

                {(props.domain === WEBSITE_DOMAIN_NAMES.GUIDE_TOP_REVIEW || props.domain === WEBSITE_DOMAIN_NAMES.BUYEREVIEWS)
                  ?
                  <AccordionTitle active={accordionIndex === 1} index={1} onClick={() => handleAccordionChange(1)}>
                    <Icon name='dropdown' /> Create Bulk Articles (Excel)
                  </AccordionTitle>
                  :
                  <AccordionTitle active={accordionIndex === 1} index={1} onClick={() => handleAccordionChange(1)}>
                    <Icon name='dropdown' /> Create Longtail Article (Excel)
                  </AccordionTitle>
                }
                {(props.domain === WEBSITE_DOMAIN_NAMES.GUIDE_TOP_REVIEW || props.domain === WEBSITE_DOMAIN_NAMES.BUYEREVIEWS)
                  ?
                  <AccordionContent active={accordionIndex === 1}>
                    {selectedWebsite && <div className='import-asins-csv-container'>
                      <b style={{ textAlign: 'left', width: '100%', color: 'gray' }}>Input file format: ["kw", "asin1", "asin2", "asin3" .....]</b>

                      <div className='file-upload-container'>
                        <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => handleBulkArticlesFileUploaded(e?.target?.files?.[0])} title={' '} />

                        {uploadedFileBulkArticles &&
                          <>
                            <span> File Name: <b>{uploadedFileBulkArticles}</b> <br />{`${originalFileRowsBulkArticles.length} Rows`}
                            </span>

                            <Button
                              icon='cloud'
                              content='Generate Comparison Pages from File'
                              onClick={() => handleImportBulkArticlesClicked()} />
                          </>
                        }
                      </div>
                    </div>}
                  </AccordionContent>
                  :
                  <AccordionContent active={accordionIndex === 1}>
                    {selectedWebsite && <div className='import-asins-csv-container'>
                      <b style={{ textAlign: 'left', width: '100%', color: 'gray' }}>Input file format: ["kw1", "kw2", "kw3", "asins" .....]</b>

                      <div className='file-upload-container'>
                        <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => handleLongTailFileUploaded(e?.target?.files?.[0])} title={' '} />

                        {uploadedFileLongTail &&
                          <>
                            <span> File Name: <b>{uploadedFileLongTail}</b> <br />{`${originalFileRowsLongTail.length} Rows`}
                            </span>

                            <Button
                              icon='cloud'
                              content='Generate Comparison Pages from File'
                              onClick={() => handleImportAsinsLongTailtkClicked()} />
                          </>
                        }
                      </div>
                    </div>}
                  </AccordionContent>}



                <AccordionTitle active={accordionIndex === 2} index={2} onClick={() => handleAccordionChange(2)}>
                  <Icon name='dropdown' /> Create Longtail Article (Keywords)
                </AccordionTitle>

                <AccordionContent active={accordionIndex === 2}>
                  {selectedWebsite && <div className='import-asins-csv-container'>
                    <div className='textarea-container'>

                      <TextArea
                        label='Long Tail Keyword List'
                        placeholder={`long tail keyword 1
long tail keyword 2
long tail keyword 3
...`}
                        value={keywordLongTailList}
                        onChange={(e: any) => { setKeywordLongTailList(e.target.value) }} />

                    </div>

                    <Button
                      icon='cloud'
                      style={{ backgroundColor: '#5ac7ae', width: '400px' }}
                      content='Generate Pages from Long Tail Keywords'
                      onClick={() => handleCreateComparisonPagesFromLongTailKeywordsClicked()} />

                  </div>}
                </AccordionContent>



                {(props.domain === WEBSITE_DOMAIN_NAMES.GUIDE_TOP_REVIEW || props.domain === WEBSITE_DOMAIN_NAMES.BUYEREVIEWS)
                  && <AccordionTitle active={accordionIndex === 3} index={3} onClick={() => handleAccordionChange(3)}>
                    <Icon name='dropdown' /> Bulk Actions (Paid Usage)
                  </AccordionTitle>}

                {(props.domain === WEBSITE_DOMAIN_NAMES.GUIDE_TOP_REVIEW || props.domain === WEBSITE_DOMAIN_NAMES.BUYEREVIEWS)
                  && <AccordionContent active={accordionIndex === 3}>
                    {selectedWebsite && (props.domain === WEBSITE_DOMAIN_NAMES.GUIDE_TOP_REVIEW || props.domain === WEBSITE_DOMAIN_NAMES.BUYEREVIEWS) && <div className='import-asins-csv-container'>
                      <div className='textarea-container'>

                        <Button
                          className='download-urls-btn'
                          icon='download'
                          content='Download Website Urls'
                          onClick={() => downloadAllGtrUrlsFromDB()} />


                        <Form className='form-container-elements'>

                          <Form.Field
                            id='form-textarea-control-urls'
                            control={TextArea}
                            label='URLs'
                            placeholder={`https://website.com/...
https://website.com/...
https://website.com/..`}
                            value={bulkActionUrls}
                            onChange={(e: any) => { setBulkActionUrls(e.target.value) }}
                          />

                          <Form.Field
                            id='form-input-control-asin1'
                            control={Input}
                            label='ASIN 1'
                            placeholder='ASIN'
                            value={asin1}
                            onChange={(e: any) => { setAsin1(e.target.value) }}
                          />

                          <Form.Field
                            id='form-input-control-asin2'
                            control={Input}
                            label='ASIN 2'
                            placeholder='ASIN'
                            value={asin2}
                            onChange={(e: any) => { setAsin2(e.target.value) }}
                          />

                          <Form.Field
                            id='form-input-control-excludedAsins'
                            control={Input}
                            label='Excluded ASINs - (List of ASINs that will not be part of this article)'
                            placeholder='B066351GHG,B068351GU7,B01835OP7H...'
                            value={excludedAsins}
                            onChange={(e: any) => { setExcludedAsins(e.target.value) }}
                          />

                          <Form.Field
                            id='form-input-control-excludedAsins'
                            control={Input}
                            label='Excluded Words - (List of words that will not be part of the products title)'
                            placeholder='word1,word2,word3...'
                            value={excludedWords}
                            onChange={(e: any) => { setExcludedWords(e.target.value) }}
                          />

                          <Form.Field
                            id='form-input-control-amazonCategoryId'
                            control={Input}
                            label='Amazon Category Id'
                            placeholder='Amazon Category Id'
                            value={amazonCategoryId}
                            onChange={(e: any) => { setAmazonCategoryId(e.target.value) }}
                          />
                        </Form>

                      </div>

                      <Button
                        icon='cloud'
                        style={{ backgroundColor: '#5ac7ae', width: '400px' }}
                        content='Run Bulk Actions'
                        onClick={() => handleRunBulkActionsClicked()} />

                    </div>}
                  </AccordionContent>}

                {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.websiteId) && <AccordionTitle active={accordionIndex === 5} index={5} onClick={() => handleAccordionChange(5)}>
                  <Icon name='dropdown' /> Create DSA Campaign
                </AccordionTitle>}

                {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.websiteId) && <AccordionContent active={accordionIndex === 5}>
                  {selectedWebsite && [WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.websiteId) && <div className='import-asins-csv-container'>
                    <div className='textarea-container'>

                      <Form className='form-container-elements'>

                        <Form.Field
                          id='form-input-control-dsaMainKw'
                          control={Input}
                          label='Main Keyword'
                          placeholder='Main Keyword'
                          value={dsaMainKw}
                          onChange={(e: any) => { setDsaMainKw(e.target.value); setDsaCampaignName(e.target.value); }}
                        />

                        <Form.Field
                          id='form-input-control-dsaCampaignName'
                          control={Input}
                          label='Campaign Name'
                          placeholder='Campaign Name'
                          value={dsaCampaignName}
                          onChange={(e: any) => { setDsaCampaignName(e.target.value) }}
                        />

                        <Form.Field
                          id='form-input-control-roas'
                          control={Input}
                          label='ROAS'
                          placeholder='ROAS'
                          value={dsaRoas}
                          onChange={(e: any) => { setDsaRoas(e.target.value) }}
                        />

                        <Form.Field
                          id='form-input-control-budget'
                          control={Input}
                          label='Budget'
                          placeholder='Budget'
                          value={dsaBudget}
                          onChange={(e: any) => { setDsaBudget(e.target.value) }}
                        />
                      </Form>

                    </div>

                    <Button
                      icon='cloud'
                      style={{ backgroundColor: '#5ac7ae', width: '400px' }}
                      content='Create DSA Campaign'
                      onClick={() => handleCreateDsaCampaignClicked()} />

                  </div>}
                </AccordionContent>}


                {false && <AccordionTitle active={accordionIndex === 4} index={4} onClick={() => handleAccordionChange(4)}>
                  <Icon name='dropdown' /> Upload Longtail Keywords for Bulk Pages Creation
                </AccordionTitle>}

                {false && <AccordionContent active={accordionIndex === 4}>
                  {selectedWebsite && false && <div className='create-trending-products-container'>
                    <div className='left-side-container'>
                      <div className='title'>
                        Create Deals / Trending Articles:
                      </div>

                      <div className='textarea-container'>

                        <TextArea
                          label='Keyword List'
                          placeholder={`amazon prime day
tiktok suggested
amazon must have
...`}
                          value={keywordDealsList}
                          onChange={(e: any) => { setKeywordDealsList(e.target.value) }} />

                      </div>


                      <div>Choose Number of Products Per Page: </div>
                      <Dropdown
                        search
                        selection
                        options={Array.from({ length: 30 }, (_, i) => ({ key: `${i + 1}`, text: `${i + 1}`, value: `${i + 1}` }))}
                        onChange={(e, data) => setSelectedDealsLimit(data.value + '')}
                        value={selectedDealsLimit}
                      />

                      <Button
                        icon='cloud'
                        style={{ backgroundColor: 'pink', width: '400px' }}
                        content='Generate Deals / Trending Articles'
                        onClick={() => handleCreateTrendingDealsArticlesByKeywordsClicked()} />
                    </div>




                    <div className='right-side-container'>
                      <div className='title'>
                        Add new Trending ASINs:
                      </div>

                      <Icon name='file excel' color='grey' style={{ position: 'absolute', top: '0px', right: '45px', cursor: 'pointer' }} onClick={() => downloadTrendingAsins()}>
                        <span style={{ paddingLeft: '10px' }}>Export</span>
                      </Icon>

                      <div className='textarea-container'>

                        <TextArea
                          label='Keyword List'
                          placeholder={`ASIN 1
ASIN 2
ASIN 3
...`}
                          value={newAsinsList}
                          onChange={(e: any) => { setNewAsinsList(e.target.value) }} />

                      </div>

                      <Button
                        icon='cloud'
                        style={{ backgroundColor: 'orange', width: '300px' }}
                        content='Add New Asins To Trending List'
                        onClick={() => handleAddNewAsinsToTrendingListClicked()} />
                    </div>

                  </div>}
                </AccordionContent>}

              </Accordion>}
              {/* {selectedWebsite && <div className='import-asins-csv-container'>
                <div className='title'>
                  Import ASINs For Bulk Comparison Pages Creation:
                </div>
                <b style={{ textAlign: 'left', width: '100%', color: 'gray' }}>( Input file format: ["flat categories path", "asins" .....])</b>

                <div className='file-upload-container'>
                  <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => handleFileUploaded(e?.target?.files?.[0])} title={' '} />

                  {uploadedFile &&
                    <>
                      <span> File Name: <b>{uploadedFile}</b> <br />{`${originalFileRows.length} Rows`}
                      </span>

                      <Button
                        icon='cloud'
                        content='Generate Comparison Pages from File'
                        onClick={() => handleImportAsinsClicked()} />
                    </>
                  }
                </div>
              </div>} */}



              {/*               {selectedWebsite && <div className='import-asins-csv-container'>
                <div className='title'>
                  Create Comparison Pages from Keywords:
                </div>

                <div className='textarea-container'>

                  <TextArea
                    label='Keyword List'
                    placeholder={`keyword1
keyword2
keyword3
...`}
                    value={keywordList}
                    onChange={(e: any) => { setKeywordList(e.target.value) }} />

                </div>

                <Button
                  icon='cloud'
                  content='Generate Pages from Keywords'
                  onClick={() => handleCreateComparisonPagesFromKeywordsClicked()} />

              </div>} */}


              {selectedWebsite && comparisonPagesByWebsite.length > 0 && <div className='list-of-comparison-pages-container'>
                <div className='title'>
                  {comparisonPagesByWebsite.length} Active Comparison Pages:
                  <button style={{ backgroundColor: 'black', color: 'white', marginLeft: '20px', cursor: 'pointer' }} onClick={() => loadComparisonPages()}>Load All</button>
                </div>

                {selectedWebsite === WEBSITE_DOMAIN_NAMES.JPOST && <div className='aff-dropdown-container'>
                  <div>Choose Affiliate Link Before Download: </div>
                  <Dropdown
                    search
                    selection
                    options={affiliateLinksForJpost}
                    onChange={(e, data) => setSelectedAffiliateLink(data.value + '')}
                    value={selectedAffiliateLink}
                  />
                </div>}

                {selectedWebsite === WEBSITE_DOMAIN_NAMES.JPOST && <div className='aff-dropdown-container'>
                  <div>Choose Number of Products Per Page: </div>
                  <Dropdown
                    search
                    selection
                    options={Array.from({ length: 15 }, (_, i) => ({ key: `${i + 1}`, text: `${i + 1}`, value: `${i + 1}` }))}
                    onChange={(e, data) => setSelectedLimit(data.value + '')}
                    value={selectedLimit}
                  />
                </div>}

                <ComparisonPagesTableComponent
                  comparisonPages={comparisonPagesByWebsite}
                  selectedWebsite={selectedWebsite}
                  selectedWebsiteId={selectedWebsiteId}
                  downloadLayoutsFiles={(cp) => downloadLayoutsFiles(cp, selectedAffiliateLink, selectedLimit, userAccessLevel, setIsLoading)}
                  publishComparisonPageToWordpress={(cp) => publishComparisonPageToWordpress(cp, selectedLimit, setIsLoading)}
                  parentCtechYnetDownloadZipComparisonPage={(cp) => parentCtechYnetDownloadZipComparisonPage(cp, setIsLoading)}
                  parentPublishComparisonPageINN={(cp) => parentPublishComparisonPageINN(cp, selectedLimit, setIsLoading)}
                  parentPublishComparisonPageNewsDirect={(cp) => parentPublishComparisonPageNewsDirect(cp, selectedLimit, setIsLoading)}
                  publishComparisonPageToWpCms={(cp) => publishComparisonPageToWpCms(cp, selectedLimit, setIsLoading)} />


              </div>}

            </>
          }

          {currentTab === MENU_TABS.JPOST_PRODUCTS &&
            <>
              <JpostProductEditComponent domain={selectedWebsite} selectedWebsiteId={selectedWebsiteId} />
            </>
          }

          {currentTab === MENU_TABS.JPOST_COMPARISON_PAGE &&
            <>
              <JpostComparisonEditComponent domain={selectedWebsite} selectedWebsiteId={selectedWebsiteId} comparisonPagesByWebsite={comparisonPagesByWebsite} urlsDropDown={urlsDropDown} />
            </>
          }

          {currentTab === MENU_TABS.JPOST_MANUAL_EDITOR &&
            <>
              <JpostManualEditorComponent domain={selectedWebsite} selectedWebsiteId={selectedWebsiteId} comparisonPagesByWebsite={comparisonPagesByWebsite} urlsDropDown={urlsDropDown} />
            </>
          }

          {currentTab === MENU_TABS.EDIT_ARTICLE &&
            <>
              <EditArticleComponent domain={selectedWebsite} selectedWebsiteId={selectedWebsiteId} comparisonPagesByWebsite={comparisonPagesByWebsite} urlsDropDown={urlsDropDown} />
            </>
          }

          {currentTab === MENU_TABS.JPOST_PROMPTS &&
            <>
              <JpostPromptsComponent selectedWebsiteId={selectedWebsiteId} />
            </>}

          {currentTab === MENU_TABS.JPOST_AMAZON_REPORTS &&
            <>
              <JpostAmazonReportsComponent selectedWebsiteId={selectedWebsiteId} />
            </>}

          {currentTab === MENU_TABS.GTR_CAMPAIGN_REPORTS &&
            <>
              <CampaignReportsComponent selectedWebsiteId={selectedWebsiteId} />
            </>}

          {currentTab === MENU_TABS.DUPLICATE_ARTICLE &&
            <>
              <DuplicateArticleComponent selectedWebsiteId={selectedWebsiteId} />
            </>}

          {currentTab === MENU_TABS.VIDEO_EDITOR &&
            <>
              <VideoEditorComponent domain={selectedWebsite} selectedWebsiteId={selectedWebsiteId} comparisonPagesByWebsite={comparisonPagesByWebsite} urlsDropDown={urlsDropDown} />
            </>}

        </div>
      </div >
    );
  }
}

export default JpostHomeComponent;