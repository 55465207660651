import { useContext, useEffect, useState } from "react";
import { AmazonProduct, AmazonReport, AmazonReportWithAdditions, ComparisonPage, Keyword, Product } from "../../Modals/ContextObj";
import { Button, Checkbox, Dropdown, Form, Icon, Input, InputOnChangeData, Statistic, Table } from "semantic-ui-react";
import _ from 'lodash'
import consts, { getPathFromCategories } from "../../Helpers/consts";
import './CampaignReportsComponent.scss';
import { toast } from "react-hot-toast";
import { appContext } from '../../App';
import { downloadUnicodeCSV } from "../../Helpers/csvHelper";
import readXlsxFile from "read-excel-file";
import { cellDataType } from "../../Helpers/customTypes";
import { generatePaidCampaignsReport } from "../../Helpers/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCampaignByStatus } from "../../Helpers/affMsGoogle.api";

interface CampaignReportsComponentProps {
    selectedWebsiteId: number,
}


const CampaignReportsComponent = (props: CampaignReportsComponentProps) => {
    const { setIsLoading } = useContext(appContext);

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [campaignId, setCampaignId] = useState('');
    const [reportData, setReportData] = useState<any[]>([]);
    const [orderDetailsData, setOrderDetailsData] = useState<any[]>([]);
    const [selectedCampaignData, setSelectedCampaignData] = useState<string>('');
    const [allCampaignData, setAllCampaignData] = useState<{
        key: string;
        text: string;
        value: string;
    }[]>([]);


    useEffect(() => {
        getCampaignsNameAndIdDataFromDB()
    }, []);

    const getCampaignsNameAndIdDataFromDB = async () => {
        setIsLoading(true);
        const res1 = await getCampaignByStatus({ status: 'ENABLED', websiteId: props.selectedWebsiteId });
        const res: { campaignId: string; campaignName: string; }[] = res1.map((c: any) => ({ campaignId: c.campaign.id, campaignName: c.campaign.name }));

        const data = res.filter(i => ![null, ''].includes(i.campaignId)).map(row => ({
            key: row.campaignId,
            text: `${row.campaignName} - ( ${row.campaignId} )`,
            value: `${row.campaignName} - ( ${row.campaignId} )`,
        }))

        setAllCampaignData(data);

        setIsLoading(false);
    }

    const downloadToCsv = async () => {

        let finalList = [];
        finalList.push(['Campaign Id', 'Device', 'Sessions', 'CPC', 'Unique Outbound Click', 'Unique Convertions', 'Direct Commission',
            'Indirect Commission', 'Additional Commission', 'Total Commission', 'EPC', 'PPC', 'Unique Outbound Click / Sessions - %',
            'Unique Convertions / Unique Outbound Click - %']);

        reportData.forEach((item: any) => {

            let newObj = [item.campaignId, item.device, item.sessions, item.cpc, item.uniqueOutboundClick, item.uniqueConvertions, item.directCommission,
            item.indirectCommission, item.additionalCommission, item.totalCommission, item.epc, item.ppc, item.uniqueOutboundClickPerSessionsPercentage,
            item.uniqueConvertionsPerUniqueOutboundClickPercentage
            ]

            finalList.push(newObj);
        });

        downloadUnicodeCSV(`${campaignId}-Report-${formatDate(fromDate)}-TO-${formatDate(toDate)}.csv`, finalList);

        let finalOrdersList = [];
        finalOrdersList.push(['Device', 'Asin', 'Indirect Sales', 'Price', 'Category Commission', 'Qty', 'Commission',
            'Additional Commission', 'Ad Group Id', 'Creative']);

        orderDetailsData.forEach((item: any) => {

            let newObj = [item.device, item.asin, item.indirectSales, item.price, item.categoryCommission, item.qty, item.commission,
            item.additionalCommission, item.adGroupId, item.creative]

            finalOrdersList.push(newObj);
        });

        downloadUnicodeCSV(`${campaignId}-Report-Orders-${formatDate(fromDate)}-TO-${formatDate(toDate)}.csv`, finalOrdersList);
    }

    const getCampaignDataByDatesHandler = async () => {
        setReportData([]);
        setOrderDetailsData([]);
        setIsLoading(true);
        const cId = allCampaignData.find(c => c.text === selectedCampaignData);
        const res: { returnedData: any[], orderDetailsData: any[] } = await generatePaidCampaignsReport({ websiteId: props.selectedWebsiteId, fromDate, toDate, campaignId: cId?.key });

        setReportData(res.returnedData);
        setOrderDetailsData(res.orderDetailsData);
        setIsLoading(false);
    }

    const formatDate = (date: Date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }


    return (
        <div className='campaign-report-container'>

            <div className="dates-container">
                <div>From Date:</div>
                <DatePicker selected={fromDate} onChange={(date: any) => setFromDate(date)} />
                <div>To Date:</div>
                <DatePicker selected={toDate} onChange={(date: any) => setToDate(date)} />
            </div>

            <div className="inputs-container">

                <Dropdown
                    search
                    selection
                    options={allCampaignData}
                    onChange={(e, data) => setSelectedCampaignData(data.value + '')}
                    value={selectedCampaignData}
                />

            </div>
            <Button
                style={{ alignSelf: 'center', backgroundColor: 'darksalmon', marginTop: '20px' }}
                icon='graph'
                content='Get Campaign Report'
                onClick={() => getCampaignDataByDatesHandler()} />

            <Button
                style={{ alignSelf: 'center', backgroundColor: 'darksalmon', marginTop: '-20px' }}
                icon='excel'
                content='Download Report to CSV'
                onClick={() => downloadToCsv()} />

            <div style={{ overflow: 'scroll', width: '100%' }}>
                Campaign Report:
                <Table sortable celled fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: '150px' }}>Campaign Id</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '100px' }}>Device</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '100px' }}>Sessions</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '100px' }}>CPC</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '170px' }}>Unique Outbound Click</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '150px' }}>Unique Convertions</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '150px' }}>Direct Commission</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '170px' }}>Indirect Commission</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '170px' }}>Additional Commission</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '150px' }}>Total Commission</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '100px' }}>EPC</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '100px' }}>PPC</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '270px' }}>Unique Outbound Click / Sessions - %</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '270px' }}>Unique Convertions / Unique Outbound Click - %</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {reportData.map((item, i) => (
                            <Table.Row key={i}>
                                <Table.Cell>{item.campaignId}</Table.Cell>
                                <Table.Cell>{item.device}</Table.Cell>
                                <Table.Cell>{item.sessions}</Table.Cell>
                                <Table.Cell>{parseFloat(item.cpc).toFixed(2)}</Table.Cell>
                                <Table.Cell>{item.uniqueOutboundClick}</Table.Cell>
                                <Table.Cell>{item.uniqueConvertions}</Table.Cell>
                                <Table.Cell>{parseFloat(item.directCommission).toFixed(2)}</Table.Cell>
                                <Table.Cell>{parseFloat(item.indirectCommission).toFixed(2)}</Table.Cell>
                                <Table.Cell>{item.additionalCommission}</Table.Cell>
                                <Table.Cell>{parseFloat(item.totalCommission).toFixed(2)}</Table.Cell>
                                <Table.Cell>{parseFloat(item.epc).toFixed(2)}</Table.Cell>
                                <Table.Cell>{parseFloat(item.ppc).toFixed(2)}</Table.Cell>
                                <Table.Cell>{item.uniqueOutboundClickPerSessionsPercentage}</Table.Cell>
                                <Table.Cell>{item.uniqueConvertionsPerUniqueOutboundClickPercentage}</Table.Cell>
                            </Table.Row>))}
                    </Table.Body>
                </Table>

            </div>

            <div style={{ overflow: 'scroll', width: '100%' }}>
                Orders Report:
                <Table sortable celled fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: '100px' }}>Device</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '150px' }}>Asin</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '150px' }}>Indirect Sales</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '100px' }}>Price</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '150px' }}>Category Commission</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '100px' }}>Qty</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '100px' }}>Commission</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '150px' }}>Additional Commission</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '150px' }}>Ad Group Id</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '150px' }}>Creative</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {orderDetailsData.map((item, i) => (
                            <Table.Row key={i}>
                                <Table.Cell>{item.device}</Table.Cell>
                                <Table.Cell>{item.asin}</Table.Cell>
                                <Table.Cell>{item.indirectSales}</Table.Cell>
                                <Table.Cell>{item.price}</Table.Cell>
                                <Table.Cell>{item.categoryCommission}</Table.Cell>
                                <Table.Cell>{item.qty}</Table.Cell>
                                <Table.Cell>{parseFloat(item.commission).toFixed(2)}</Table.Cell>
                                <Table.Cell>{parseFloat(item.additionalCommission).toFixed(2)}</Table.Cell>
                                <Table.Cell>{item.adGroupId}</Table.Cell>
                                <Table.Cell>{item.creative}</Table.Cell>
                            </Table.Row>))}
                    </Table.Body>
                </Table>

            </div>
        </div>
    );

}

export default CampaignReportsComponent;
