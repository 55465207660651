import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Input, Form, Dropdown, TextArea, Checkbox } from 'semantic-ui-react';
import './AddBulkProductsComponent.scss';
import { appContext } from '../../App';
import {
  getAllProductsDataFromDB,
  getSuggestPossibleComparisonPagesFromDB,
  getSuggestAffiliatorsPossibleComparisonPagesFromDB,
  addProductToCreatorConnection,
  getClicksCountPerBrandId,
  addNewTaskToQueue,
  addAsinsToCheckInStock,
} from '../../Helpers/api';
import { cellDataType } from '../../Modals/ContextObj';
import { downloadUnicodeCSV } from '../../Helpers/csvHelper';
import consts from '../../Helpers/consts';
import readXlsxFile from 'read-excel-file';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DropDownObj } from '../../Helpers/customTypes';
import { isNumber } from 'lodash';

interface AddBulkProductsComponentProps {}

const AddBulkProductsComponent = (props: AddBulkProductsComponentProps) => {
  const { setIsLoading } = useContext(appContext);
  const [uploadedFile, setUploadedFile] = useState<string | null>();
  const [originalFileHeaders, setOriginalFileHeaders] = useState<cellDataType[]>([]);
  const [originalFileRows, setOriginalFileRows] = useState<cellDataType[][]>([]);

  const [creatorConnectionAsinToAdd, setCreatorConnectionAsinToAdd] = useState('');
  const [creatorConnectionCommissionToAdd, setCreatorConnectionCommissionToAdd] = useState();
  const [creatorConnectionToDate, setCreatorConnectionToDate] = useState();

  const [brandIdsDropDown, setBrandIdsDropDown] = useState<DropDownObj[]>([]);
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [numberOfLinksToAdd, setNumberOfLinksToAdd] = useState(0);
  const [addToPriorityQueue, setAddToPriorityQueue] = useState(false);

  const [asinsForRFInStockCheck, setAsinsForRFInStockCheck] = useState<string>('');

  const getBrandsList = async () => {
    setIsLoading(true);
    const brandClicksResp = await getClicksCountPerBrandId(4);

    if (brandClicksResp?.length > 0) {
      const brandIdsMap = brandClicksResp.map((a: { brandId: string }) => ({
        key: a.brandId,
        text: a.brandId,
        value: a.brandId,
      }));

      setBrandIdsDropDown(brandIdsMap);
    }

    setIsLoading(false);
  };

  const sliceIntoChunks = (arr: any[], chunkSize: number) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  };

  const handleGenerateBtnClicked = async () => {
    const MAX_ITEMS_PER_BULK_TASK = 2500;
    let finalRows: any[] = [];
    for (let i = 0; i < originalFileRows.length; i++) {
      const objToPush: any = {};
      for (let j = 0; j < originalFileHeaders.length; j++) {
        objToPush[originalFileHeaders[j].toString()] = originalFileRows[i][j];
      }
      finalRows.push(objToPush);
    }

    const sliceToParts = sliceIntoChunks(finalRows, MAX_ITEMS_PER_BULK_TASK);

    for (let i = 0; i < sliceToParts.length; i++) {
      const partOfItems = sliceToParts[i];

      const payload = {
        products: partOfItems,
      };

      await addNewTaskToQueue({
        action: 'scrapeBulkProductsForNewAffiliatorItems',
        body: payload,
      });
    }

    toast.success('Starting the process, it will take a while..');
  };

  const handleFileUploaded = async (input: any) => {
    setUploadedFile(input.name);
    const rowsWithHeaders: cellDataType[][] = await readXlsxFile(input);
    const headers = rowsWithHeaders?.[0];
    const rows = rowsWithHeaders.slice(1, rowsWithHeaders.length);
    setOriginalFileHeaders(headers);
    setOriginalFileRows(rows);
  };

  const downloadAllProductsDataFromDB = async () => {
    try {
      setIsLoading(true);

      const res = await getAllProductsDataFromDB();
      let finalList = [];
      finalList.push([
        'sku',
        'merchant_code',
        'asin',
        'name',
        'merchant_name',
        'upc',
        'product_cost',
        'average_shipping_cost',
        'affiliate_type',
        'affiliate_link',
        'affiliate_merchant',
        'brand',
        'rating',
        'reviews',
        'price',
        'category1',
        'category2',
        'category3',
        'category4',
        'category5',
        'category6',
        'category7',
        'category8',
        'category9',
        'lastSubCategory',
        'categoriesFlat',
        'link',
        'title',
        'bsrFlat',
        'bsrCategory',
        'bsrNumber',
        'monthlySalesEstimation',
        'similarItems',
        'keywords',
        'parentASIN',
        'image1',
        'image2',
        'createdAt',
        'updatedAt',
      ]);
      res.forEach((item: any) => {
        let newObj = [
          item.sku,
          item.merchant_code,
          item.asin,
          item.name,
          item.merchant_name,
          item.upc,
          item.product_cost,
          item.average_shipping_cost,
          item.affiliate_type,
          item.affiliate_link,
          item.affiliate_merchant,
          item.brand,
          item.rating,
          item.reviews,
          item.price,
          item.category1,
          item.category2,
          item.category3,
          item.category4,
          item.category5,
          item.category6,
          item.category7,
          item.category8,
          item.category9,
          item.lastSubCategory,
          item.categoriesFlat,
          item.link,
          item.title,
          item.bsrFlat,
          item.bsrCategory,
          item.bsrNumber,
          item.monthlySalesEstimation,
          item.similarItems,
          item.keywords,
          item.parentASIN,
          item.image1,
          item.image2,
          item.createdAt,
          item.updatedAt,
        ];

        finalList.push(newObj);
      });

      downloadUnicodeCSV('AllProductsData.csv', finalList);

      setIsLoading(false);
      toast.success('Downloaded successfully!');
    } catch (e) {
      setIsLoading(false);
      toast.error('Failed to download products data');
    }
  };

  const downloadSuggestPossibleComparisonPages = async (types: string) => {
    try {
      setIsLoading(true);

      let finalList = [];
      const flatArray: any[] = [];
      if (types === 'catalog') {
        finalList = await getSuggestPossibleComparisonPagesFromDB();
        finalList.forEach((item: any) => {
          const row = [item.customPath, ...item.asins];
          flatArray.push(row);
        });
      } else if (types === 'affiliators') {
        finalList = await getSuggestAffiliatorsPossibleComparisonPagesFromDB();
        finalList.forEach((item: any) => {
          const row = [item.customPath, item.chatGptKw, item.bardKw1, item.bardKw2, item.bardKw3, ...item.asins];
          flatArray.push(row);
        });
      }

      downloadUnicodeCSV('Suggest_Possible_Comparison_Pages.csv', flatArray);

      setIsLoading(false);
      toast.success('Downloaded successfully!');
    } catch (e) {
      setIsLoading(false);
      toast.error('Failed to download data');
    }
  };

  const addLinksToClicker = async () => {
    if (isNumber(parseInt(numberOfLinksToAdd.toString())) && selectedBrandId !== '') {
      const payload = {
        numberOfLinksToAdd,
        selectedBrandId,
        isPriority: addToPriorityQueue
      };
      await addNewTaskToQueue({
        action: 'createMoreLinksForBrandId',
        body: payload,
      });

      toast.success('Added to Queue Successfully!');
    } else {
      toast.error('Number of links should be a number');
    }
  };

  const addCreatorConnectionProduct = async () => {
    if (creatorConnectionAsinToAdd === '') {
      toast.error('ASIN is missing!');
      return;
    }
    if (
      !creatorConnectionCommissionToAdd ||
      creatorConnectionCommissionToAdd === '' ||
      parseFloat(creatorConnectionCommissionToAdd) > 1
    ) {
      toast.error('Commission is missing! or not between 0 to 1');
      return;
    }
    if (!creatorConnectionToDate || creatorConnectionToDate === null) {
      toast.error('Experation Date is missing!');
      return;
    }

    const payload = {
      asin: creatorConnectionAsinToAdd,
      creatorConnectionCommission: parseFloat(creatorConnectionCommissionToAdd),
      creatorConnectionExpDate: creatorConnectionToDate,
    };

    const resp = await addProductToCreatorConnection(payload);

    if (resp.data === true) {
      toast.success('Product Added Successfully to Creator Connection Program!');
    } else {
      toast.error('Failed to add product to Creator Connection Program.');
    }
  };

  const checkIfNeedToLoadBrandIds = async () => {
    if (brandIdsDropDown?.length === 0) {
      await getBrandsList();
    }
  };

  const addAsinsToRFInStockCheck = async () => {
    const asins = asinsForRFInStockCheck
      .split('\n')
      .map((a) => a.trim())
      .filter((a) => a !== '');

    if (asins.length === 0) {
      toast.error('No Asins to add');
      return;
    }

    const payload = {
      asinData: asins.map((a) => ({ asin: a, vendor: 'Rainforest' })),
    };

    const { newAsins } = await addAsinsToCheckInStock(payload);

    if (newAsins?.length > 0) {
      setAsinsForRFInStockCheck('')
      toast.success('Asins updated Successfully!');
    } else {
      toast.error('Failed to update asins.');
    }
  };

  const handleAddition = (value: any) => {
    const newOption = { key: value, text: value, value };
    setBrandIdsDropDown((prevOptions) => [...prevOptions, newOption]);
  };

  return (
    <div className="add-bulk-products-container">
      <div className="import-csv-container">
        <div className="title">Import new Products into DB:</div>
        <Button
          icon="download"
          content="Download Import File Template"
          onClick={() => downloadUnicodeCSV('Input_File_For_Bulk_Products.csv', [consts.inputXlsxHeadersAddProducts])}
        />

        <div className="file-upload-container">
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={(e) => handleFileUploaded(e?.target?.files?.[0])}
            title={' '}
          />

          {uploadedFile && (
            <>
              <span>
                {' '}
                File Name: <b>{uploadedFile}</b> <br />
                {`${originalFileHeaders.length} Columns & ${originalFileRows.length} Rows`}
              </span>

              <Button
                content="Start Scrape Process"
                icon="upload"
                labelPosition="left"
                onClick={() => handleGenerateBtnClicked()}
              />
            </>
          )}
        </div>
      </div>

      <div className="import-csv-container">
        <div className="title">Add Creator Connection Product:</div>

        <div
          style={{
            width: '100%',
            textAlign: 'left',
            paddingTop: '20px',
            fontSize: '13px',
            color: 'gray',
          }}
        >
          <div>The Commission should be between 0 to 1. (0.15, 0.25, 0.3)</div>
        </div>

        <Form style={{ width: '100%', textAlign: 'left', paddingTop: '20px' }}>
          <Form.Group widths={'equal'}>
            <Form.Field
              style={{ width: '250px' }}
              id="form-input-creatorConnection-asin"
              control={Input}
              label="ASIN"
              placeholder="ASIN"
              value={creatorConnectionAsinToAdd}
              onChange={(e: any) => {
                setCreatorConnectionAsinToAdd(e.target.value);
              }}
            />

            <Form.Field
              style={{ width: '250px' }}
              id="form-input-creatorConnection-commission"
              control={Input}
              label="Commission"
              placeholder="Commission"
              value={creatorConnectionCommissionToAdd}
              onChange={(e: any) => {
                setCreatorConnectionCommissionToAdd(e.target.value);
              }}
            />
          </Form.Group>

          <span style={{ fontWeight: '800', marginRight: '15px' }}>Expiration Date</span>
          <DatePicker selected={creatorConnectionToDate} onChange={(date: any) => setCreatorConnectionToDate(date)} />
        </Form>
        <Button icon="add" content="Add" onClick={() => addCreatorConnectionProduct()} />
      </div>

      <div className="import-csv-container">
        <div className="title">Add Clicker Links For Brand:</div>

        <Form style={{ width: '100%', textAlign: 'left', paddingTop: '20px' }}>
          <Form.Group widths={'equal'}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontWeight: '800', marginRight: '15px' }}>Brand ID</span>
              <Dropdown
                style={{ width: '450px', height: '36px', marginTop: '3px' }}
                search
                selection
                options={brandIdsDropDown}
                onOpen={() => checkIfNeedToLoadBrandIds()}
                onChange={(e, data) => setSelectedBrandId(data?.value?.toString() || '')}
                allowAdditions
                value={selectedBrandId}
                onAddItem={(e, data) => handleAddition(data.value)}
              />
            </div>
            <Form.Field
              style={{ width: '250px' }}
              id="form-input-clicker-count"
              control={Input}
              label="Number of links to create"
              placeholder="100"
              value={numberOfLinksToAdd}
              onChange={(e: any) => {
                setNumberOfLinksToAdd(e.target.value);
              }}
            />

            <Form.Field>
              <Checkbox
                style={{ marginTop: '35px', marginLeft: '30px' }}
                label='Add to priority queue'
                checked={addToPriorityQueue}
                onChange={(e: any, data: any) => {
                  setAddToPriorityQueue(data.checked);
                }}
              />
            </Form.Field>
          </Form.Group>

       

        </Form>
        <Button icon="add" content="Add" onClick={() => addLinksToClicker()} />
      </div>

      <div className="import-csv-container">
        <div className="title">Add Asins For Rainforest stock check:</div>

        <div
          style={{
            width: '100%',
            textAlign: 'left',
            paddingTop: '20px',
            fontSize: '13px',
            color: 'gray',
          }}
        >
          <div>
            all asins will be checked for in stock using the rainforest api instead of paApi if it has affiliate link
          </div>
        </div>

        <Form style={{ width: '100%', textAlign: 'left', paddingTop: '20px' }}>
          <Form.Group widths={'equal'}>
            <Form.Field
              style={{ width: '250px' }}
              id="form-input-in-stock-check-asins"
              control={TextArea}
              label="ASINS"
              placeholder={`asin 1
asin 2
asin 3
...`}
              value={asinsForRFInStockCheck}
              onChange={(e: any) => {
                setAsinsForRFInStockCheck(e.target.value);
              }}
            />
          </Form.Group>
        </Form>
        <Button icon="add" content="Add" onClick={() => addAsinsToRFInStockCheck()} />
      </div>

      {/* <div className='possible-comparison-pages-container'>
                <div className='title'>
                    Suggest Possible Comparison Pages (Include Catalog Items):
                </div>
                <Button
                    icon='download'
                    content='Suggest Possible Comparison Pages (csv)'
                    onClick={() => downloadSuggestPossibleComparisonPages('catalog')} />

            </div> */}

      {/*  <div className='possible-comparison-pages-container'>
                <div className='title'>
                    Suggest Possible Comparison Pages (Affiliators Only):
                </div>
                <Button
                    icon='download'
                    content='Suggest Possible Comparison Pages (csv)'
                    onClick={() => downloadSuggestPossibleComparisonPages('affiliators')} />

            </div> */}
    </div>
  );
};

export default AddBulkProductsComponent;
