export const downloadUnicodeCSV = (filename: string, datasource: any) => {
  var content = '',
    newLine = '\r\n';
  for (var _i = 0, datasource_1 = datasource; _i < datasource_1.length; _i++) {
    var line = datasource_1[_i];
    var i = 0;
    for (var _a = 0, line_1 = line; _a < line_1.length; _a++) {
      var item = line_1[_a] == null ? '' : line_1[_a] + '';
      var it = item.replace(/"/g, '""');
      if (it.search(/("|,|\n)/g) >= 0) {
        it = '"' + it + '"';
      }
      content += (i > 0 ? ',' : '') + it;
      ++i;
    }
    content += newLine;
  }
  var link = document.createElement('a');
  link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(content));
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
