export const getCommissionString = (item: any) => {
    let commissionStr = '';

    if (item.affiliateCommission > 0) {
      commissionStr = 'Affiliate: ' + (item.affiliateCommission * 100).toFixed(2) + '%';
    }
    if (item.creatorConnectionCommission > 0) {
      if (commissionStr === '') {
        commissionStr = 'CC: ' + (item.creatorConnectionCommission * 100).toFixed(2) + '%';
      } else {
        commissionStr = commissionStr + ' + CC: ' + (item.creatorConnectionCommission * 100).toFixed(2) + '%';
      }
    }
    if (item.incentivesCommission > 0) {
      if (commissionStr === '') {
        commissionStr = 'LTI: ' + (item.incentivesCommission * 100).toFixed(2) + '%';
      } else {
        commissionStr = commissionStr + ' + LTI: ' + (item.incentivesCommission * 100).toFixed(2) + '%';
      }
    }

    return commissionStr === '' ? '-' : commissionStr;
  };