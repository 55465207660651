import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Dropdown, DropdownProps } from 'semantic-ui-react';
import './VideoEditorComponent.scss';
import { appContext } from '../../App';
import { crawlProductsMedia, getComparisonPageByKeyword, getComparisonPageByPath } from '../../Helpers/api';
import { AmazonProduct, ComparisonPage, ComparisonPageResp, Product } from '../../Modals/ContextObj';
import { DropDownObj } from '../../Helpers/customTypes';
import { getPathFromCategories, getVersionStringByComparisonPage } from '../../Helpers/consts';

interface VideoEditorComponentProps {
  domain: string;
  selectedWebsiteId: number;
  urlsDropDown: DropDownObj[];
  comparisonPagesByWebsite: ComparisonPage[];
}

const VideoEditorComponent = (props: VideoEditorComponentProps) => {
  const { setIsLoading } = useContext(appContext);
  const [stepToShow, setStepToShow] = useState(1);

  const [id, setId] = useState(-1);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [writterName, setWritterName] = useState('');
  const [introContent, setIntroContent] = useState('');
  const [conclusion, setConclusion] = useState('');
  const [buyersGuideAndFaq, setBuyersGuideAndFaq] = useState('');
  const [categoryImage, setCategoryImage] = useState('');
  const [category3, setCategory3] = useState('');

  const [urlsDropDown, setUrlsDropDown] = useState<DropDownObj[]>(props.urlsDropDown);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [linksForGenerate, setLinksForGenerate] = useState('');
  const [originalProducts, setOriginalProducts] = useState<
    {
      product: Product;
      amazonData: AmazonProduct;
      keyword?: string | undefined;
    }[]
  >([]);

  const getComparisonDataFromDB = async (keyword: string, version: number) => {
    setIsLoading(true);
    const res: ComparisonPageResp = await getComparisonPageByKeyword(keyword, props.selectedWebsiteId, version);
    if (res?.comparisonPage) {
      setId(res.comparisonPage.id);
      setTitle(res.comparisonPage.title);
      setDate(res.comparisonPage.date);
      setWritterName(res.comparisonPage.writterName);
      setIntroContent(res.comparisonPage.introContent);
      setConclusion(res.comparisonPage.conclusion);
      setBuyersGuideAndFaq(res.comparisonPage.buyersGuideAndFaq);
      setCategoryImage(res.comparisonPage.categoryImage);
      setCategory3(res.comparisonPage.category3);

      setLinksForGenerate(res.products.map((p: any) => p.amazonData.link).join(', '));

      setOriginalProducts(res?.products.sort((a, b) => a.product.position - b.product.position));
    } else {
      toast.error('Comparison Data not found for this category!');
    }
    setIsLoading(false);
  };

  const crawlProductsMediaFromDB = async () => {
    setIsLoading(true);
    const res: any = await crawlProductsMedia({
      products: originalProducts.slice(0, 10),
    });

    setIsLoading(false);
  };

  const handleUrlChange = async (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const value = urlsDropDown.find((o: DropDownObj) => o.value === data.value)?.value;

    if (value) {
      const obj = props.comparisonPagesByWebsite.find(
        (cp) => getPathFromCategories(cp) + getVersionStringByComparisonPage(cp) === value,
      );
      if (obj) {
        setSelectedUrl(value);
        await getComparisonDataFromDB(obj.keyword, obj.version);
        setStepToShow(2);
      }
    }
  };

  return (
    <div className="video-editor-container">
      <div className="title">STEP 1 - Select Comparison Page Url: </div>
      <Dropdown search selection options={urlsDropDown} onChange={handleUrlChange} value={selectedUrl} />

      {stepToShow > 1 && <div className="title">STEP 2 - Crawl Amazon Products: </div>}
      {stepToShow > 1 && (
        <Button
          style={{ width: '300px' }}
          icon="search"
          content={'Start Crawling Process'}
          onClick={() => crawlProductsMediaFromDB()}
        />
      )}
    </div>
  );
};

export default VideoEditorComponent;
