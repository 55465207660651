import { useContext, useEffect, useState } from "react";
import { AmazonProduct, ComparisonPage, MENU_TABS, Product } from "../../Modals/ContextObj";
import { Checkbox, Icon, Input, InputOnChangeData, Popup, Table } from "semantic-ui-react";
import _ from 'lodash'
import { WEBSITE_DOMAIN_NAMES, WEBSITE_ID, getPathFromCategories, getVersionStringByComparisonPage, publisherNameByWebsiteIdPosition } from "../../Helpers/consts";
import './ComparisonPagesTableComponent.scss';
import { appContext } from '../../App';

interface ComparisonPagesTableComponentProps {
    comparisonPages: ComparisonPage[],
    selectedWebsite: string,
    selectedWebsiteId: number,
    downloadLayoutsFiles?: (cp: ComparisonPage) => void,
    publishComparisonPageToWordpress?: (cp: ComparisonPage) => void,
    parentCtechYnetDownloadZipComparisonPage?: (cp: ComparisonPage) => void,
    parentPublishComparisonPageINN?: (cp: ComparisonPage) => void,
    parentPublishComparisonPageNewsDirect?: (cp: ComparisonPage) => void,
    publishComparisonPageToWpCms?: (cp: ComparisonPage) => void,
}


const ComparisonPagesTableComponent = (props: ComparisonPagesTableComponentProps) => {

    const { updateCurrentTab, updateCurrentEditComparisonPage } = useContext(appContext);

    const [column, setColumn] = useState<'id' | 'category1' | 'category2' | 'category3' | 'keyword' | 'catalogItemsCount' | 'affiliatorItemsCount' | 'otherItemsCount' | 'createdAt' | 'updatedAt'>('id');
    const [direction, setDirection] = useState<"ascending" | "descending" | undefined>('ascending');
    const [data, setData] = useState(props.comparisonPages || []);
    const [originalData, setOriginalData] = useState(props.comparisonPages || []);

    const [searchValue, setSearchValue] = useState('');
    const [catalogItemsCheckbox, setCatalogItemsCheckbox] = useState(true);
    const [otherItemsCheckbox, setOtherItemsCheckbox] = useState(true);
    const [showProductionPages, setShowProductionPages] = useState(true);

    useEffect(() => {
        setData(props.comparisonPages);
        setOriginalData(props.comparisonPages);
    }, [props.comparisonPages])

    useEffect(() => {
        // if (props.selectedWebsite === WEBSITE_DOMAIN_NAMES.JPOST || props.selectedWebsite === WEBSITE_DOMAIN_NAMES.LENSVID) {
        let data = originalData.filter((cp) => containsIgnoreCase(cp.keyword, searchValue));

        if (showProductionPages === false) {
            data = data.filter(cp => ['', undefined, null].includes(cp.productionPageUrl));
        }

        if (catalogItemsCheckbox === true && otherItemsCheckbox === false) {
            data = data.filter(cp => cp.catalogItemsCount != 0 || cp.affiliatorItemsCount != 0);
        } else if (catalogItemsCheckbox === false && otherItemsCheckbox === true) {
            data = data.filter(cp => cp.catalogItemsCount === 0 && cp.affiliatorItemsCount === 0);
        } else if (catalogItemsCheckbox === false && otherItemsCheckbox === false) {
            data = [];
        }

        setData(data);
        // } else {
        //     setData(originalData.filter((cp) => containsIgnoreCase(cp.category1, searchValue) || containsIgnoreCase(cp.category2, searchValue) || containsIgnoreCase(cp.category3, searchValue)));
        // }
    }, [searchValue, catalogItemsCheckbox, otherItemsCheckbox, showProductionPages, originalData]);

    const containsIgnoreCase = (mainString: string, searchString: string) => {
        const lowerMainString = _.toLower(mainString);
        const lowerSearchString = _.toLower(searchString);

        return _.includes(lowerMainString, lowerSearchString);
    }

    const changeSort = (columnName: 'id' | 'category1' | 'category2' | 'category3' | 'keyword' | 'catalogItemsCount' | 'affiliatorItemsCount' | 'otherItemsCount' | 'createdAt' | 'updatedAt') => {

        if (column === columnName) {
            setData(data.slice().reverse());
            setDirection(direction === 'ascending' ? 'descending' : 'ascending');
        } else {
            setColumn(columnName);
            setData(_.sortBy(data, [columnName]));
            setDirection('ascending');
        }

    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        });

        return formattedDate
    }

    const callParentDownloadLayoutsFiles = (cp: ComparisonPage) => {

        if (props.downloadLayoutsFiles) {
            props.downloadLayoutsFiles(cp);
        }
    }

    const callParentPublishComparisonPageNewsDirect = (cp: ComparisonPage) => {

        if (props.parentPublishComparisonPageNewsDirect) {
            props.parentPublishComparisonPageNewsDirect(cp);
        }
    }

    const callParentPublishComparisonPageINN = (cp: ComparisonPage) => {

        if (props.parentPublishComparisonPageINN) {
            props.parentPublishComparisonPageINN(cp);
        }
    }

    const callParentPublishComparisonPage = (cp: ComparisonPage) => {

        if (props.publishComparisonPageToWordpress) {
            props.publishComparisonPageToWordpress(cp);
        }
    }

    const callParentPublishToWpCms = (cp: ComparisonPage) => {

        if (props.publishComparisonPageToWpCms) {
            props.publishComparisonPageToWpCms(cp);
        }
    }

    const callParentCtechYnetDownloadZipComparisonPage = (cp: ComparisonPage) => {

        if (props.parentCtechYnetDownloadZipComparisonPage) {
            props.parentCtechYnetDownloadZipComparisonPage(cp);
        }
    }

    const movedToEditComparisonPage = (cp: ComparisonPage) => {
        const selected = {
            key: cp.id,
            text: getPathFromCategories(cp) + getVersionStringByComparisonPage(cp),
            value: getPathFromCategories(cp) + getVersionStringByComparisonPage(cp),
        };


        updateCurrentEditComparisonPage(selected);
        updateCurrentTab(MENU_TABS.EDIT_ARTICLE);

    }


    const getTableCellForNewCMSByPublisher = (cp: ComparisonPage) => {
        return (
            <Table.Cell>
                <Icon style={{ marginRight: '15px', cursor: 'pointer' }} name="pencil" onClick={() => movedToEditComparisonPage(cp)} />
                <Popup
                    trigger={
                        <a style={{ cursor: 'pointer' }} onClick={() => callParentPublishToWpCms(cp)}>
                            <Icon name="send" />
                            {getPathFromCategories(cp) + getVersionStringByComparisonPage(cp)}
                        </a>
                    }
                    content={`Upload to ${publisherNameByWebsiteIdPosition[cp.websiteId]}`}
                    inverted
                />

                {cp.productionPageUrl !== '' && cp.productionPageUrl !== null &&
                    <span
                        style={{ cursor: 'pointer', backgroundColor: '#b51200', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}
                        onClick={() => { window.open(cp.productionPageUrl, '_target') }}>
                        {publisherNameByWebsiteIdPosition[cp.websiteId]}
                    </span>}
                {cp.comparisonPageType === 'COMPARISON' &&
                    <span
                        style={{ cursor: 'pointer', backgroundColor: '#2185d0', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                        C
                    </span>}

                {cp.comparisonPageType === 'BLOG' &&
                    <span
                        style={{ cursor: 'pointer', backgroundColor: '#21d085', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                        B
                    </span>}

                {cp.comparisonPageType === 'TRENDING' &&
                    <span
                        style={{ cursor: 'pointer', backgroundColor: 'deeppink', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                        C
                    </span>}

                {cp.comparisonPageType === 'SINGLE' &&
                    <span
                        style={{ cursor: 'pointer', backgroundColor: '#6435c9', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                        S
                    </span>}

            </Table.Cell>
        )
    }


    const getTableCellByPublisher = (cp: ComparisonPage) => {

        const jpostOldVersion = cp.productionPageUrl?.includes('advisor') ? true : false;
        if (props.selectedWebsite === WEBSITE_DOMAIN_NAMES.JPOST) {
            return (
                <Table.Cell>
                    <Icon style={{ marginRight: '15px', cursor: 'pointer' }} name="pencil" onClick={() => movedToEditComparisonPage(cp)} />
                    <a style={{ cursor: 'pointer' }} onClick={() => callParentDownloadLayoutsFiles(cp)}>
                        <Icon name="download" />
                        {getPathFromCategories(cp) + getVersionStringByComparisonPage(cp)}
                    </a>
                    {cp.productionPageUrl !== '' && cp.productionPageUrl !== null &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: jpostOldVersion ? '#b5000059' : '#B50000', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}
                            onClick={() => { window.open(cp.productionPageUrl, '_target') }}>
                            {jpostOldVersion ? 'Jpost Old' : 'Jpost'}
                        </span>}
                    {cp.comparisonPageType === 'COMPARISON' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#2185d0', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'BLOG' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#21d085', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            B
                        </span>}

                    {cp.comparisonPageType === 'TRENDING' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: 'deeppink', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            D
                        </span>}

                    {cp.comparisonPageType === 'SINGLE' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#6435c9', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            S
                        </span>}

                </Table.Cell>
            )
        }
        else if (props.selectedWebsite === WEBSITE_DOMAIN_NAMES.LENSVID) {
            return (
                <Table.Cell>
                    <Icon style={{ marginRight: '15px', cursor: 'pointer' }} name="pencil" onClick={() => movedToEditComparisonPage(cp)} />
                    <Popup
                        trigger={
                            <a style={{ cursor: 'pointer' }} onClick={() => callParentPublishComparisonPage(cp)}>
                                <Icon name="wordpress" />
                                {getPathFromCategories(cp) + getVersionStringByComparisonPage(cp)}
                            </a>
                        }
                        content='Upload to Lensvid'
                        inverted
                    />

                    {cp.productionPageUrl !== '' && cp.productionPageUrl !== null &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#b51200', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}
                            onClick={() => { window.open(cp.productionPageUrl, '_target') }}>
                            Lensvid
                        </span>}
                    {cp.comparisonPageType === 'COMPARISON' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#2185d0', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'BLOG' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#21d085', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            B
                        </span>}

                    {cp.comparisonPageType === 'TRENDING' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: 'deeppink', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'SINGLE' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#6435c9', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            S
                        </span>}

                </Table.Cell>
            )
        } else if (props.selectedWebsite === WEBSITE_DOMAIN_NAMES.CTECH || props.selectedWebsite === WEBSITE_DOMAIN_NAMES.YNET) {
            return (
                <Table.Cell>
                    <Icon style={{ marginRight: '15px', cursor: 'pointer' }} name="pencil" onClick={() => movedToEditComparisonPage(cp)} />
                    <Popup
                        trigger={
                            <a style={{ cursor: 'pointer' }} onClick={() => callParentCtechYnetDownloadZipComparisonPage(cp)}>
                                <Icon name="download" />
                                {getPathFromCategories(cp) + getVersionStringByComparisonPage(cp)}
                            </a>
                        }
                        content='Download zip file'
                        inverted
                    />

                    {cp.productionPageUrl !== '' && cp.productionPageUrl !== null &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: props.selectedWebsite === WEBSITE_DOMAIN_NAMES.CTECH ? '#b51200' : '#de1a1a', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}
                            onClick={() => { window.open(cp.productionPageUrl, '_target') }}>
                            {props.selectedWebsite === WEBSITE_DOMAIN_NAMES.CTECH ? 'CTECH' : 'Ynet'}
                        </span>}
                    {cp.comparisonPageType === 'COMPARISON' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#2185d0', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'BLOG' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#21d085', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            B
                        </span>}

                    {cp.comparisonPageType === 'TRENDING' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: 'deeppink', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'SINGLE' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#6435c9', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            S
                        </span>}

                </Table.Cell>
            )
        } else if (props.selectedWebsite === WEBSITE_DOMAIN_NAMES.INN) {
            return (
                <Table.Cell>
                    <Icon style={{ marginRight: '15px', cursor: 'pointer' }} name="pencil" onClick={() => movedToEditComparisonPage(cp)} />
                    <Popup
                        trigger={
                            <a style={{ cursor: 'pointer' }} onClick={() => callParentPublishComparisonPageINN(cp)}>
                                <Icon name="send" />
                                {getPathFromCategories(cp) + getVersionStringByComparisonPage(cp)}
                            </a>
                        }
                        content='Upload to INN'
                        inverted
                    />

                    {cp.productionPageUrl !== '' && cp.productionPageUrl !== null &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#0069b3', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}
                            onClick={() => { window.open(cp.productionPageUrl, '_target') }}>
                            INN
                        </span>}
                    {cp.comparisonPageType === 'COMPARISON' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#2185d0', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'BLOG' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#21d085', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            B
                        </span>}

                    {cp.comparisonPageType === 'TRENDING' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: 'deeppink', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'SINGLE' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#6435c9', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            S
                        </span>}

                </Table.Cell>
            )
        } else if (props.selectedWebsite === WEBSITE_DOMAIN_NAMES.NEWS_DIRECT) {
            return (
                <Table.Cell>
                    <Icon style={{ marginRight: '15px', cursor: 'pointer' }} name="pencil" onClick={() => movedToEditComparisonPage(cp)} />
                    <Popup
                        trigger={
                            <a style={{ cursor: 'pointer' }} onClick={() => callParentPublishComparisonPageNewsDirect(cp)}>
                                <Icon name="send" />
                                {getPathFromCategories(cp) + getVersionStringByComparisonPage(cp)}
                            </a>
                        }
                        content='Upload to News Direct'
                        inverted
                    />

                    {cp.productionPageUrl !== '' && cp.productionPageUrl !== null &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#ed3424', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}
                            onClick={() => { window.open(cp.productionPageUrl, '_target') }}>
                            News Direct
                        </span>}
                    {cp.comparisonPageType === 'COMPARISON' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#2185d0', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'BLOG' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#21d085', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            B
                        </span>}

                    {cp.comparisonPageType === 'TRENDING' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: 'deeppink', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'SINGLE' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#6435c9', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            S
                        </span>}

                </Table.Cell>
            )
        } else if (props.selectedWebsite === WEBSITE_DOMAIN_NAMES.GUIDE_TOP_REVIEW || props.selectedWebsite === WEBSITE_DOMAIN_NAMES.BUYEREVIEWS) {
            return (
                <Table.Cell>
                    <Icon style={{ marginRight: '15px', cursor: 'pointer' }} name="pencil" onClick={() => movedToEditComparisonPage(cp)} />
                    <Popup
                        trigger={
                            <a style={{ cursor: 'pointer' }} href={`https://${props.selectedWebsite}/${cp.category1}/${cp.category2}/${cp.category3}`} target='_blank'>
                                <Icon name="arrow circle right" />
                                {getPathFromCategories(cp) + getVersionStringByComparisonPage(cp)}
                            </a>
                        }
                        content='Open URL'
                        inverted
                    />

                    {cp.productionPageUrl !== '' && cp.productionPageUrl !== null &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#b51200', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}
                            onClick={() => { window.open(cp.productionPageUrl, '_target') }}>
                            {props.selectedWebsite === WEBSITE_DOMAIN_NAMES.GUIDE_TOP_REVIEW ? 'GTR' : 'BestReviews'}
                        </span>}
                    {cp.comparisonPageType === 'COMPARISON' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#2185d0', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'BLOG' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#21d085', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            B
                        </span>}

                    {cp.comparisonPageType === 'TRENDING' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: 'deeppink', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            C
                        </span>}

                    {cp.comparisonPageType === 'SINGLE' &&
                        <span
                            style={{ cursor: 'pointer', backgroundColor: '#6435c9', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                            S
                        </span>}

                </Table.Cell>
            )
        }
        else if ([WEBSITE_ID.AMOBEEZ_DEMO, WEBSITE_ID.YNET_NEWS, WEBSITE_ID.ISRAEL_HAYOM].includes(props.selectedWebsiteId)) {
            return getTableCellForNewCMSByPublisher(cp)
        }
        else {
            return (
                <Table.Cell><a href={`https://${props.selectedWebsite}/${getPathFromCategories(cp)}`} target='_blank'><Icon name="share" />{getPathFromCategories(cp)}</a></Table.Cell>
            )
        }
    }


    const getTableElementsByComparisonPageType = (comparisonPageTypes: string[]) => {
        return (
            <Table sortable celled fixed>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell
                            width={5}
                            // sorted={column === 'id' ? direction : 'ascending'}
                            onClick={() => changeSort('id')}
                        >
                            ID
                        </Table.HeaderCell>
                        {props.selectedWebsite === WEBSITE_DOMAIN_NAMES.DECOR_HOME_BEAUTY && <Table.HeaderCell
                            sorted={column === 'category1' ? direction : 'ascending'}
                            onClick={() => changeSort('category1')}
                        >
                            Category1
                        </Table.HeaderCell>}
                        {props.selectedWebsite === WEBSITE_DOMAIN_NAMES.DECOR_HOME_BEAUTY && <Table.HeaderCell
                            sorted={column === 'category2' ? direction : 'ascending'}
                            onClick={() => changeSort('category2')}
                        >
                            Category2
                        </Table.HeaderCell>}
                        {props.selectedWebsite === WEBSITE_DOMAIN_NAMES.DECOR_HOME_BEAUTY && <Table.HeaderCell
                            sorted={column === 'category3' ? direction : 'ascending'}
                            onClick={() => changeSort('category3')}
                        >
                            Category3
                        </Table.HeaderCell>}

                        <Table.HeaderCell
                            width={1}
                            sorted={column === 'catalogItemsCount' ? direction : 'ascending'}
                            onClick={() => changeSort('catalogItemsCount')}
                        >
                            Ctlg
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            width={1}
                            sorted={column === 'affiliatorItemsCount' ? direction : 'ascending'}
                            onClick={() => changeSort('affiliatorItemsCount')}
                        >
                            Ctlg 2
                        </Table.HeaderCell>

                        <Table.HeaderCell
                            width={1}
                            sorted={column === 'otherItemsCount' ? direction : 'ascending'}
                            onClick={() => changeSort('otherItemsCount')}
                        >
                            Other
                        </Table.HeaderCell>


                        <Table.HeaderCell
                            width={2}
                            sorted={column === 'createdAt' ? direction : 'ascending'}
                            onClick={() => changeSort('createdAt')}
                        >
                            Created At
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            width={2}
                            sorted={column === 'updatedAt' ? direction : 'ascending'}
                            onClick={() => changeSort('updatedAt')}
                        >
                            Updated At
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.filter(c => comparisonPageTypes.includes(c.comparisonPageType)).map((cp) => (
                        <Table.Row key={cp.id}>
                            {getTableCellByPublisher(cp)}

                            {props.selectedWebsite === WEBSITE_DOMAIN_NAMES.DECOR_HOME_BEAUTY && <Table.Cell>{cp.category1}</Table.Cell>}
                            {props.selectedWebsite === WEBSITE_DOMAIN_NAMES.DECOR_HOME_BEAUTY && <Table.Cell>{cp.category2}</Table.Cell>}
                            {props.selectedWebsite === WEBSITE_DOMAIN_NAMES.DECOR_HOME_BEAUTY && <Table.Cell>{cp.category3}</Table.Cell>}
                            <Table.Cell>{cp.catalogItemsCount}</Table.Cell>
                            <Table.Cell>{cp.affiliatorItemsCount}</Table.Cell>
                            <Table.Cell>{cp.otherItemsCount}</Table.Cell>
                            <Table.Cell>{formatDate(cp.createdAt)}</Table.Cell>
                            <Table.Cell>{formatDate(cp.updatedAt)}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        )
    }

    return (
        <div>
            <div className="filters-container">
                <div className="titles">Search by Keyword: </div>
                <Input
                    value={searchValue}
                    placeholder='Filter...'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => setSearchValue(data.value)} />

                {props.selectedWebsite === WEBSITE_DOMAIN_NAMES.JPOST && <div className="titles"> Filter Types:
                    <Checkbox checked={catalogItemsCheckbox} onChange={() => { setCatalogItemsCheckbox(!catalogItemsCheckbox) }} label='Catalog Pages' />
                    <Checkbox checked={otherItemsCheckbox} onChange={() => { setOtherItemsCheckbox(!otherItemsCheckbox) }} label='Keyword Pages' />
                    <Checkbox checked={showProductionPages} onChange={() => { setShowProductionPages(!showProductionPages) }} label='Show' />
                    <span
                        style={{ cursor: 'pointer', backgroundColor: '#b51200', color: 'white', lineHeight: '20px', padding: '3px', paddingLeft: '7px', paddingBottom: '6px', marginLeft: '10px', paddingRight: '7px', borderRadius: '5px', fontWeight: 'bold', fontSize: '14px' }}>
                        Jpost
                    </span>
                </div>}
            </div>

            <div style={{ padding: '5px', backgroundColor: 'lightskyblue', borderTop: '3px solid #2185d0', borderBottom: '3px solid #2185d0', marginTop: '15px', fontSize: '16px', fontWeight: 'bold', color: 'black' }}>{data.filter(c => c.comparisonPageType === 'COMPARISON' || c.comparisonPageType === 'BLOG').length} Comparison Pages</div>
            {getTableElementsByComparisonPageType(['COMPARISON', 'BLOG'])}

            <div style={{ padding: '5px', backgroundColor: 'lightskyblue', borderTop: '3px solid #2185d0', borderBottom: '3px solid #2185d0', marginTop: '15px', fontSize: '16px', fontWeight: 'bold', color: 'black' }}>{data.filter(c => c.comparisonPageType === 'TRENDING').length} Deals / Trending Pages</div>
            {getTableElementsByComparisonPageType(['TRENDING'])}

            <div style={{ padding: '5px', backgroundColor: 'lightskyblue', borderTop: '3px solid #2185d0', borderBottom: '3px solid #2185d0', marginTop: '15px', fontSize: '16px', fontWeight: 'bold', color: 'black' }}>{data.filter(c => c.comparisonPageType === 'SINGLE').length} Single Pages</div>
            {getTableElementsByComparisonPageType(['SINGLE'])}
        </div>
    );

}

export default ComparisonPagesTableComponent;
