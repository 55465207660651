import React, { useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import HomeComponent from './Components/HomeComponent/HomeComponent';
import { ContextObj, MENU_TABS } from './Modals/ContextObj';
import { Toaster } from 'react-hot-toast';
import BeatLoader from "react-spinners/BeatLoader";
import JpostHomeComponent from './Components/JpostHomeComponent/JpostHomeComponent';
import { ACCESS_LEVEL, WEBSITE_DOMAIN_NAMES } from './Helpers/consts';
import { DropDownObj } from './Helpers/customTypes';

export const appContext = React.createContext<ContextObj>({
  isLoading: false,
  username: 'undefined',
  currentTab: MENU_TABS.CREATE_COMPARISON_PAGE,
  currentEditComparisonPage: { key: -1, text: '', value: '' },
  userAccessLevel: ACCESS_LEVEL.ADMIN,
  userLoggedIn: false,

  updateUsername: function (value: string): void {
    throw new Error('Function not implemented.');
  },
  setIsLoading: function (value: boolean): void {
    throw new Error('Function not implemented.');
  },
  updateCurrentTab: function (value: MENU_TABS): void {
    throw new Error('Function not implemented.');
  },
  updateCurrentEditComparisonPage: function (value: DropDownObj): void {
    throw new Error('Function not implemented.');
  },
  updateUserAccessLevel: function (value: string): void {
    throw new Error('Function not implemented.');
  },
  updateUserLoggedIn: function (value: boolean): void {
    throw new Error('Function not implemented.');
  },

});

function App() {
  const [username, setUsername] = useState('undefined');
  const [currentTab, setCurrentTab] = useState(MENU_TABS.CREATE_COMPARISON_PAGE);
  const [currentEditComparisonPage, setCurrentEditComparisonPage] = useState({ key: -1, text: '', value: '' });
  const [userAccessLevel, setUserAccessLevel] = useState('testssss');
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="App">
      <appContext.Provider value={{
        isLoading,
        username,
        currentTab,
        currentEditComparisonPage,
        userAccessLevel,
        userLoggedIn,

        updateUsername: (username: string) => {
          setUsername(username);
        },
        setIsLoading: (isLoading: boolean) => {
          setIsLoading(isLoading);
        },
        updateCurrentTab: (currTab: MENU_TABS) => {
          setCurrentTab(currTab);
        },
        updateCurrentEditComparisonPage: (curEditComparisonPage: DropDownObj) => {
          setCurrentEditComparisonPage(curEditComparisonPage);
        },
        updateUserAccessLevel: (level: string) => {
          setUserAccessLevel(level);
        },
        updateUserLoggedIn: (logged: boolean) => {
          setUserLoggedIn(logged);
        },
      }}>
        <BrowserRouter>
          <Routes>
            <Route path="/amobeez" element={<HomeComponent />} />
            <Route path="/jpost" element={<JpostHomeComponent websiteId={2} domain={WEBSITE_DOMAIN_NAMES.JPOST} />} />
            <Route path="/guidetopreview" element={<JpostHomeComponent websiteId={3} domain={WEBSITE_DOMAIN_NAMES.GUIDE_TOP_REVIEW} />} />
            <Route path="/lensvid" element={<JpostHomeComponent websiteId={4} domain={WEBSITE_DOMAIN_NAMES.LENSVID} />} />
            <Route path="/ctech" element={<JpostHomeComponent websiteId={5} domain={WEBSITE_DOMAIN_NAMES.CTECH} />} />
            <Route path="/inn" element={<JpostHomeComponent websiteId={6} domain={WEBSITE_DOMAIN_NAMES.INN} />} />
            <Route path="/newsdirect" element={<JpostHomeComponent websiteId={7} domain={WEBSITE_DOMAIN_NAMES.NEWS_DIRECT} />} />
            <Route path="/ynet" element={<JpostHomeComponent websiteId={8} domain={WEBSITE_DOMAIN_NAMES.YNET} />} />
            <Route path="/amobeezdemo" element={<JpostHomeComponent websiteId={9} domain={WEBSITE_DOMAIN_NAMES.AMOBEEZ_DEMO} />} />
            <Route path="/ynetnews" element={<JpostHomeComponent websiteId={10} domain={WEBSITE_DOMAIN_NAMES.YNET_NEWS} />} />
            <Route path="/buyereviews" element={<JpostHomeComponent websiteId={11} domain={WEBSITE_DOMAIN_NAMES.BUYEREVIEWS} />} />
            <Route path="/israelhayom" element={<JpostHomeComponent websiteId={12} domain={WEBSITE_DOMAIN_NAMES.ISRAEL_HAYOM} />} />
          </Routes>
        </BrowserRouter>

        {isLoading &&
          <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: '20000', backgroundColor: '#ebf5ff6b' }}>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
              <BeatLoader color='#548fff' loading={true} size={15} margin={2} />
            </div>
          </div>
        }
        <div><Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            className: '',
            style: {
              margin: '10px',
              padding: '10px',
              background: '#363636',
              color: '#fff',
              zIndex: 999999,
            },
            duration: 6000,
            success: {
              duration: 4000,
            },
            error: {
              duration: 6000,
            },
          }}

        /></div>
      </appContext.Provider>
    </div>
  );
}

export default App;
