import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Form, TextArea } from 'semantic-ui-react';
import './JpostPromptsComponent.scss';
import { appContext } from '../../App';
import { getAllPromptsByTagHandler, regenerateForAll, updatePromptByTagAndName } from '../../Helpers/api';
import { Prompt } from '../../Modals/ContextObj';

// NOT IN USE - HIDDEN FROM THE UI

interface JpostPromptsComponentProps {
  selectedWebsiteId: number;
}

const JpostPromptsComponent = (props: JpostPromptsComponentProps) => {
  const { setIsLoading } = useContext(appContext);

  // JPOST PRODUCT PROMPTS
  const [jpostProductDisplayName, setJpostProductDisplayName] = useState('');
  const [jpostProductTitle, setJpostProductTitle] = useState('');
  const [jpostProductProsAndCons, setJpostProductProsAndCons] = useState('');
  const [jpostProductBottomLineAndBestUse, setJpostProductBottomLineAndBestUse] = useState('');
  const [jpostProductDescription, setJpostProductDescription] = useState('');
  const [jpostMiniProductDescription, setJpostMiniProductDescription] = useState('');
  const [jpostProductWhatWeLike, setJpostProductWhatWeLike] = useState('');
  const [jpostProductWhatWeDidntLike, setJpostProductWhatWeDidntLike] = useState('');
  const [jpostProductConclusion, setJpostProductConclusion] = useState('');
  const [jpostProductMainSpecs, setJpostProductMainSpecs] = useState('');

  // JPOST COMPARISON PROMPTS
  const [jpostComparisonTitle, setJpostComparisonTitle] = useState('');
  const [jpostComparisonIntroduction, setJpostComparisonIntroduction] = useState('');
  const [jpostComparisonBuyersGuideAndFaq, setJpostComparisonBuyersGuideAndFaq] = useState('');
  const [jpostComparisonFaq, setJpostComparisonFaq] = useState('');
  const [jpostComparisonConclusion, setJpostComparisonConclusion] = useState('');
  const [jpostComparisonGenerateKeyword, setJpostComparisonGenerateKeyword] = useState('');

  // GENERAL PROMPTS
  const [humanizeText, setHumanizeText] = useState('');

  // GUIDE TOP REVIEW
  const [paidProductTrendingData, setPaidProductTrendingData] = useState('');
  const [trendingComparisonIntroduction, setTrendingComparisonIntroduction] = useState('');

  /*   JPOST_PRODUCT_DISPLAY_NAME = 'JPOST_PRODUCT_DISPLAY_NAME',
        JPOST_PRODUCT_TITLE = 'JPOST_PRODUCT_TITLE',
        JPOST_PRODUCT_PROS_CONS = 'JPOST_PRODUCT_PROS_CONS',
        JPOST_PRODUCT_BOTTOM_LINE_AND_BEST_USE = 'JPOST_PRODUCT_BOTTOM_LINE_AND_BEST_USE',
        JPOST_PRODUCT_DESCRIPTION = 'JPOST_PRODUCT_DESCRIPTION',
        JPOST_MINI_PRODUCT_DESCRIPTION = 'JPOST_MINI_PRODUCT_DESCRIPTION',
        JPOST_PRODUCT_WHAT_WE_LIKE = 'JPOST_PRODUCT_WHAT_WE_LIKE',
        JPOST_PRODUCT_WHAT_WE_DIDNT_LIKE = 'JPOST_PRODUCT_WHAT_WE_DIDNT_LIKE',
        JPOST_PRODUCT_CONCLUSION = 'JPOST_PRODUCT_CONCLUSION',
        
    
        JPOST_COMPARISON_TITLE = 'JPOST_COMPARISON_TITLE',
        JPOST_COMPARISON_INTRODUCTION ='JPOST_COMPARISON_INTRODUCTION',
        JPOST_COMPARISON_BUYERS_GUIDE = 'JPOST_COMPARISON_BUYERS_GUIDE',
        JPOST_COMPARISON_FAQ = 'JPOST_COMPARISON_FAQ',
        JPOST_COMPARISON_CONCLUSION = 'JPOST_COMPARISON_CONCLUSION', */

  const getPromptsFromDB = async () => {
    const res: Prompt[] = await getAllPromptsByTagHandler({ tag: 'jpost' });
    if (res) {
      // PRODUCT PROMPTS
      setJpostProductDisplayName(res.find((p) => p.name === 'JPOST_PRODUCT_DISPLAY_NAME')?.text || '');
      setJpostProductTitle(res.find((p) => p.name === 'JPOST_PRODUCT_TITLE')?.text || '');
      setJpostProductProsAndCons(res.find((p) => p.name === 'JPOST_PRODUCT_PROS_CONS')?.text || '');
      setJpostProductBottomLineAndBestUse(
        res.find((p) => p.name === 'JPOST_PRODUCT_BOTTOM_LINE_AND_BEST_USE')?.text || '',
      );
      setJpostProductDescription(res.find((p) => p.name === 'JPOST_PRODUCT_DESCRIPTION')?.text || '');
      setJpostMiniProductDescription(res.find((p) => p.name === 'JPOST_MINI_PRODUCT_DESCRIPTION')?.text || '');
      setJpostProductWhatWeLike(res.find((p) => p.name === 'JPOST_PRODUCT_WHAT_WE_LIKE')?.text || '');
      setJpostProductWhatWeDidntLike(res.find((p) => p.name === 'JPOST_PRODUCT_WHAT_WE_DIDNT_LIKE')?.text || '');
      setJpostProductConclusion(res.find((p) => p.name === 'JPOST_PRODUCT_CONCLUSION')?.text || '');
      setJpostProductMainSpecs(res.find((p) => p.name === 'JPOST_PRODUCT_MAIN_SPECS')?.text || '');

      // COMPARISON PROMPTS
      setJpostComparisonTitle(res.find((p) => p.name === 'JPOST_COMPARISON_TITLE')?.text || '');
      setJpostComparisonIntroduction(res.find((p) => p.name === 'JPOST_COMPARISON_INTRODUCTION')?.text || '');
      setJpostComparisonBuyersGuideAndFaq(res.find((p) => p.name === 'JPOST_COMPARISON_BUYERS_GUIDE')?.text || '');
      setJpostComparisonFaq(res.find((p) => p.name === 'JPOST_COMPARISON_FAQ')?.text || '');
      setJpostComparisonConclusion(res.find((p) => p.name === 'JPOST_COMPARISON_CONCLUSION')?.text || '');
      setJpostComparisonGenerateKeyword(res.find((p) => p.name === 'JPOST_COMPARISON_GENERATE_KEYWORD')?.text || '');
    } else {
      toast.error('PROMPTS not found!');
    }

    const res2: Prompt[] = await getAllPromptsByTagHandler({ tag: 'general' });
    if (res2) {
      // GENERAL PROMPTS
      setHumanizeText(res2.find((p) => p.name === 'GENERAL_HUMANIZE_TEXT')?.text || '');
    } else {
      toast.error('PROMPTS not found!');
    }

    const res3: Prompt[] = await getAllPromptsByTagHandler({
      tag: 'guide top review',
    });
    if (res3) {
      // GUIDE TOP REVIEW PROMPTS
      setPaidProductTrendingData(res3.find((p) => p.name === 'PAID_PRODUCT_TRENDING_DATA')?.text || '');
      setTrendingComparisonIntroduction(
        res3.find((p) => p.name === 'PAID_TRENDING_COMPARISON_INTRODUCTION')?.text || '',
      );
    } else {
      toast.error('PROMPTS not found!');
    }
  };

  const regenerateGenericForAllByTypeClicked = async (type: string) => {
    const payload = {
      type,
      tag: 'jpost',
      websiteId: props.selectedWebsiteId,
    };

    regenerateForAll(payload);
    toast.success('Working on it ! it will take time..');
  };

  const updatePromptByTagAndNameClicked = async (name: string, text: string, tag?: string) => {
    tag = tag ? tag : 'jpost';
    try {
      const payload = {
        tag,
        name,
        text,
      };

      const res = await updatePromptByTagAndName(payload);
      toast.success('Updated Successfully!');
    } catch (e) {
      toast.error('Something went wrong..');
    }
  };

  return (
    <div className="comparison-edit-container">
      <div className="get-product-by-asin">
        <Button icon="download" content="Load All Prompts" onClick={() => getPromptsFromDB()} />
      </div>

      <Form>
        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            className="regenerate-for-all"
            icon="lock"
            content="REGENERATE PRODUCT DISPLAY NAME FOR ALL"
            onClick={() => regenerateGenericForAllByTypeClicked('JPOST_PRODUCT_DISPLAY_NAME')}
          />
          <Button
            icon="refresh"
            content="Update Jpost Product Display Name Prompt"
            onClick={() => updatePromptByTagAndNameClicked('JPOST_PRODUCT_DISPLAY_NAME', jpostProductDisplayName)}
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_PRODUCT_DISPLAY_NAME"
          control={TextArea}
          label="Product Display Name Prompt"
          placeholder="Product Display Name Prompt"
          value={jpostProductDisplayName}
          onChange={(e: any) => {
            setJpostProductDisplayName(e.target.value);
          }}
        />

        {/*   <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button className='regenerate-for-all' icon='lock' content='REGENERATE PRODUCT TITLE FOR ALL' onClick={() => regenerateGenericForAllByTypeClicked('JPOST_PRODUCT_TITLE')} />
                    <Button icon='refresh' content='Update Jpost Product Display Name Prompt' onClick={() => updatePromptByTagAndNameClicked('JPOST_PRODUCT_TITLE', jpostProductTitle)} />
                </div>
                <Form.Field
                    id='form-textarea-control-JPOST_PRODUCT_TITLE'
                    control={TextArea}
                    label='Jpost Product Title Prompt'
                    placeholder='Jpost Product Display Name Prompt'
                    value={jpostProductTitle}
                    onChange={(e: any) => { setJpostProductTitle(e.target.value) }}
                /> */}

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Product Pros and Cons Prompt"
            onClick={() => updatePromptByTagAndNameClicked('JPOST_PRODUCT_PROS_CONS', jpostProductProsAndCons)}
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_PRODUCT_PROS_CONS"
          control={TextArea}
          label="Jpost Product Pros and Cons Prompt"
          placeholder="Jpost Product Pros and Cons Prompt"
          value={jpostProductProsAndCons}
          onChange={(e: any) => {
            setJpostProductProsAndCons(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Product Bottom Line and Best Use Prompt"
            onClick={() =>
              updatePromptByTagAndNameClicked(
                'JPOST_PRODUCT_BOTTOM_LINE_AND_BEST_USE',
                jpostProductBottomLineAndBestUse,
              )
            }
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_PRODUCT_BOTTOM_LINE_AND_BEST_USE"
          control={TextArea}
          label="Jpost Product Bottom Line and Best Use Prompt"
          placeholder="Jpost Product Bottom Line and Best Use Prompt"
          value={jpostProductBottomLineAndBestUse}
          onChange={(e: any) => {
            setJpostProductBottomLineAndBestUse(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Product Description Prompt"
            onClick={() => updatePromptByTagAndNameClicked('JPOST_PRODUCT_DESCRIPTION', jpostProductDescription)}
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_PRODUCT_DESCRIPTION"
          control={TextArea}
          label="Jpost Product Description Prompt"
          placeholder="Jpost Product Description Prompt"
          value={jpostProductDescription}
          onChange={(e: any) => {
            setJpostProductDescription(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Mini Product Description Prompt"
            onClick={() =>
              updatePromptByTagAndNameClicked('JPOST_MINI_PRODUCT_DESCRIPTION', jpostMiniProductDescription)
            }
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_MINI_PRODUCT_DESCRIPTION"
          control={TextArea}
          label="Jpost Mini Product Description Prompt"
          placeholder="Jpost Mini Product Description Prompt"
          value={jpostMiniProductDescription}
          onChange={(e: any) => {
            setJpostMiniProductDescription(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost What We Like Prompt"
            onClick={() => updatePromptByTagAndNameClicked('JPOST_PRODUCT_WHAT_WE_LIKE', jpostProductWhatWeLike)}
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_PRODUCT_WHAT_WE_LIKE"
          control={TextArea}
          label="Jpost What We Like Prompt"
          placeholder="Jpost What We Like Prompt"
          value={jpostProductWhatWeLike}
          onChange={(e: any) => {
            setJpostProductWhatWeLike(e.target.value);
          }}
        />

        {/*          <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Update Jpost What We Didnt Like Prompt' onClick={() => updatePromptByTagAndNameClicked('JPOST_PRODUCT_WHAT_WE_DIDNT_LIKE', jpostProductWhatWeDidntLike)} />
                </div>
                <Form.Field
                    id='form-textarea-control-JPOST_PRODUCT_WHAT_WE_DIDNT_LIKE'
                    control={TextArea}
                    label='Jpost What We Didnt Like Prompt'
                    placeholder='Jpost What We Didnt Like Prompt'
                    value={jpostProductWhatWeDidntLike}
                    onChange={(e: any) => { setJpostProductWhatWeDidntLike(e.target.value) }}
                /> */}

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Product Conclusion Prompt"
            onClick={() => updatePromptByTagAndNameClicked('JPOST_PRODUCT_CONCLUSION', jpostProductConclusion)}
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_PRODUCT_CONCLUSION"
          control={TextArea}
          label="Jpost Product Conclusion Prompt"
          placeholder="Jpost Product Conclusion Prompt"
          value={jpostProductConclusion}
          onChange={(e: any) => {
            setJpostProductConclusion(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Product Main Specs Prompt"
            onClick={() => updatePromptByTagAndNameClicked('JPOST_PRODUCT_MAIN_SPECS', jpostProductMainSpecs)}
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_PRODUCT_MAIN_SPECS"
          control={TextArea}
          label="Jpost Product Main Specs Prompt"
          placeholder="Jpost Product Main Specs Prompt"
          value={jpostProductMainSpecs}
          onChange={(e: any) => {
            setJpostProductMainSpecs(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Comparison Title Prompt"
            onClick={() => updatePromptByTagAndNameClicked('JPOST_COMPARISON_TITLE', jpostComparisonTitle)}
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_COMPARISON_TITLE"
          control={TextArea}
          label="Jpost Comparison Title Prompt"
          placeholder="Jpost Comparison Title Prompt"
          value={jpostComparisonTitle}
          onChange={(e: any) => {
            setJpostComparisonTitle(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Comparison Introduction Prompt"
            onClick={() =>
              updatePromptByTagAndNameClicked('JPOST_COMPARISON_INTRODUCTION', jpostComparisonIntroduction)
            }
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_COMPARISON_INTRODUCTION"
          control={TextArea}
          label="Jpost Comparison Introduction Prompt"
          placeholder="Jpost Comparison Introduction Prompt"
          value={jpostComparisonIntroduction}
          onChange={(e: any) => {
            setJpostComparisonIntroduction(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Comparison Buyers Guide Prompt"
            onClick={() =>
              updatePromptByTagAndNameClicked('JPOST_COMPARISON_BUYERS_GUIDE', jpostComparisonBuyersGuideAndFaq)
            }
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_COMPARISON_BUYERS_GUIDE"
          control={TextArea}
          label="Jpost Comparison Buyers Guide Prompt"
          placeholder="Jpost Comparison Buyers Guide Prompt"
          value={jpostComparisonBuyersGuideAndFaq}
          onChange={(e: any) => {
            setJpostComparisonBuyersGuideAndFaq(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Comparison FAQ Prompt"
            onClick={() => updatePromptByTagAndNameClicked('JPOST_COMPARISON_FAQ', jpostComparisonFaq)}
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_COMPARISON_FAQ"
          control={TextArea}
          label="Jpost Comparison FAQ Prompt"
          placeholder="Jpost Comparison FAQ Prompt"
          value={jpostComparisonFaq}
          onChange={(e: any) => {
            setJpostComparisonFaq(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Comparison Conclusion Prompt"
            onClick={() => updatePromptByTagAndNameClicked('JPOST_COMPARISON_CONCLUSION', jpostComparisonConclusion)}
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_COMPARISON_CONCLUSION"
          control={TextArea}
          label="Jpost Comparison Conclusion Prompt"
          placeholder="Jpost Comparison Conclusion Prompt"
          value={jpostComparisonConclusion}
          onChange={(e: any) => {
            setJpostComparisonConclusion(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Jpost Comparison Generate Keyword Prompt"
            onClick={() =>
              updatePromptByTagAndNameClicked('JPOST_COMPARISON_GENERATE_KEYWORD', jpostComparisonGenerateKeyword)
            }
          />
        </div>
        <Form.Field
          id="form-textarea-control-JPOST_COMPARISON_GENERATE_KEYWORD"
          control={TextArea}
          label="Jpost Comparison Generate Keyword"
          placeholder="Jpost Comparison Generate Keyword"
          value={jpostComparisonGenerateKeyword}
          onChange={(e: any) => {
            setJpostComparisonGenerateKeyword(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />

        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Humanize Text Prompt"
            onClick={() => updatePromptByTagAndNameClicked('GENERAL_HUMANIZE_TEXT', humanizeText, 'general')}
          />
        </div>
        <Form.Field
          id="form-textarea-control-GENERAL_HUMANIZE_TEXT"
          control={TextArea}
          label="Humanize Text"
          placeholder="Humanize Text"
          value={humanizeText}
          onChange={(e: any) => {
            setHumanizeText(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />

        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Paid Product Trending Data Prompt"
            onClick={() =>
              updatePromptByTagAndNameClicked('PAID_PRODUCT_TRENDING_DATA', paidProductTrendingData, 'guide top review')
            }
          />
        </div>
        <Form.Field
          id="form-textarea-control-PAID_PRODUCT_TRENDING_DATA"
          control={TextArea}
          label="Paid Product Trending Data"
          placeholder="Paid Product Trending Data"
          value={paidProductTrendingData}
          onChange={(e: any) => {
            setPaidProductTrendingData(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />

        <div className="generate-part">
          <Button
            icon="refresh"
            content="Update Trending Comparison Introduction Prompt"
            onClick={() =>
              updatePromptByTagAndNameClicked(
                'PAID_TRENDING_COMPARISON_INTRODUCTION',
                trendingComparisonIntroduction,
                'guide top review',
              )
            }
          />
        </div>
        <Form.Field
          id="form-textarea-control-PAID_TRENDING_COMPARISON_INTRODUCTION"
          control={TextArea}
          label="Trending Comparison Introduction"
          placeholder="Trending Comparison Introduction"
          value={trendingComparisonIntroduction}
          onChange={(e: any) => {
            setTrendingComparisonIntroduction(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="divider-dotted" />
      </Form>
    </div>
  );
};

export default JpostPromptsComponent;
