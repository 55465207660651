import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Form, TextArea } from 'semantic-ui-react';
import './AmobeezPromptsComponent.scss';
import { appContext } from '../../App';
import { getAllPromptsByTagHandler, regenerateForAll, updatePromptByTagAndName } from '../../Helpers/api';
import { Prompt, Website } from '../../Modals/ContextObj';

// NOT IN USE - HIDDEN FROM THE UI

interface AmobeezPromptsComponentProps {
    selectedWebsiteId: number,
    originalWebsites: Website[]

}

const AmobeezPromptsComponent = (props: AmobeezPromptsComponentProps) => {

    const { setIsLoading } = useContext(appContext);

    // PRODUCT PROMPTS
    const [productDisplayName, setProductDisplayName] = useState('');
    const [productProsAndCons, setProductProsAndCons] = useState('');
    const [productBottomLineAndBestUse, setProductBottomLineAndBestUse] = useState('');
    const [productDescription, setProductDescription] = useState('');

    // COMPARISON PROMPTS
    const [comparisonTitle, setComparisonTitle] = useState('');
    const [comparisonIntroduction, setComparisonIntroduction] = useState('');
    const [comparisonBuyersGuideAndFaq, setComparisonBuyersGuideAndFaq] = useState('');
    const [comparisonConclusion, setComparisonConclusion] = useState('');




    const getPromptsFromDB = async () => {

        const res: Prompt[] = await getAllPromptsByTagHandler({ tag: 'amobeez' });
        if (res) {
            // PRODUCT PROMPTS
            setProductDisplayName(res.find(p => p.name === 'PRODUCT_DISPLAY_NAME')?.text || '');
            setProductProsAndCons(res.find(p => p.name === 'PRODUCT_PROS_CONS')?.text || '');
            setProductBottomLineAndBestUse(res.find(p => p.name === 'PRODUCT_BOTTOM_LINE_AND_BEST_USE')?.text || '');
            setProductDescription(res.find(p => p.name === 'PRODUCT_DESCRIPTION')?.text || '');

            // COMPARISON PROMPTS
            setComparisonTitle(res.find(p => p.name === 'COMPARISON_TITLE')?.text || '');
            setComparisonIntroduction(res.find(p => p.name === 'COMPARISON_INTRODUCTION')?.text || '');
            setComparisonBuyersGuideAndFaq(res.find(p => p.name === 'COMPARISON_BUYERS_GUIDE_AND_FAQ')?.text || '');
            setComparisonConclusion(res.find(p => p.name === 'COMPARISON_CONCLUSION')?.text || '');


        } else {
            toast.error('PROMPTS not found!');
        }
    }

    const regenerateGenericForAllByTypeClicked = async (type: string) => {
        const payload= {
            type,
            tag: 'amobeez',
            websiteId: props.selectedWebsiteId
        }

        regenerateForAll(payload);
        toast.success('Working on it ! it will take time..');
    }

    const updatePromptByTagAndNameClicked = async (name: string, text: string) => {

        try {
            const payload = {
                tag: 'amobeez',
                name,
                text
            }

            const res = await updatePromptByTagAndName(payload);
            toast.success('Updated Successfully!');

        } catch (e) {
            toast.error('Something went wrong..')

        }
    }

    return (
        <div className='comparison-edit-container'>

            <div className='get-product-by-asin'>
                <Button
                    icon='download'
                    content='Load All Prompts'
                    onClick={() => getPromptsFromDB()} />
            </div>

            <Form>

                <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button className='regenerate-for-all' icon='lock' content='REGENERATE PRODUCT DISPLAY NAME FOR ALL' onClick={() => regenerateGenericForAllByTypeClicked('PRODUCT_DISPLAY_NAME')} />
                    <Button icon='refresh' content='Update Product Display Name Prompt' onClick={() => updatePromptByTagAndNameClicked('PRODUCT_DISPLAY_NAME', productDisplayName)} />
                </div>
                <Form.Field
                    id='form-textarea-control-PRODUCT_DISPLAY_NAME'
                    control={TextArea}
                    label='Product Display Name Prompt'
                    placeholder='Product Display Name Prompt'
                    value={productDisplayName}
                    onChange={(e: any) => { setProductDisplayName(e.target.value) }}
                />


                <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Update Product Pros and Cons Prompt' onClick={() => updatePromptByTagAndNameClicked('PRODUCT_PROS_CONS', productProsAndCons)} />
                </div>
                <Form.Field
                    id='form-textarea-control-PRODUCT_PROS_CONS'
                    control={TextArea}
                    label='Product Pros and Cons Prompt'
                    placeholder='Product Pros and Cons Prompt'
                    value={productProsAndCons}
                    onChange={(e: any) => { setProductProsAndCons(e.target.value) }}
                />


                <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Update Product Bottom Line and Best Use Prompt' onClick={() => updatePromptByTagAndNameClicked('PRODUCT_BOTTOM_LINE_AND_BEST_USE', productBottomLineAndBestUse)} />
                </div>
                <Form.Field
                    id='form-textarea-control-PRODUCT_BOTTOM_LINE_AND_BEST_USE'
                    control={TextArea}
                    label='Product Bottom Line and Best Use Prompt'
                    placeholder='Product Bottom Line and Best Use Prompt'
                    value={productBottomLineAndBestUse}
                    onChange={(e: any) => { setProductBottomLineAndBestUse(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Update Product Description Prompt' onClick={() => updatePromptByTagAndNameClicked('PRODUCT_DESCRIPTION', productDescription)} />
                </div>
                <Form.Field
                    id='form-textarea-control-PRODUCT_DESCRIPTION'
                    control={TextArea}
                    label='Product Description Prompt'
                    placeholder='Product Description Prompt'
                    value={productDescription}
                    onChange={(e: any) => { setProductDescription(e.target.value) }}
                />


                <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Update Comparison Title Prompt' onClick={() => updatePromptByTagAndNameClicked('COMPARISON_TITLE', comparisonTitle)} />
                </div>
                <Form.Field
                    id='form-textarea-control-COMPARISON_TITLE'
                    control={TextArea}
                    label='Comparison Title Prompt'
                    placeholder='Comparison Title Prompt'
                    value={comparisonTitle}
                    onChange={(e: any) => { setComparisonTitle(e.target.value) }}
                />



                <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Update Comparison Introduction Prompt' onClick={() => updatePromptByTagAndNameClicked('COMPARISON_INTRODUCTION', comparisonIntroduction)} />
                </div>
                <Form.Field
                    id='form-textarea-control-COMPARISON_INTRODUCTION'
                    control={TextArea}
                    label='Comparison Introduction Prompt'
                    placeholder='Comparison Introduction Prompt'
                    value={comparisonIntroduction}
                    onChange={(e: any) => { setComparisonIntroduction(e.target.value) }}
                />


                <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Update Comparison Buyers Guide and FAQ Prompt' onClick={() => updatePromptByTagAndNameClicked('COMPARISON_BUYERS_GUIDE_AND_FAQ', comparisonBuyersGuideAndFaq)} />
                </div>
                <Form.Field
                    id='form-textarea-control-COMPARISON_BUYERS_GUIDE_AND_FAQ'
                    control={TextArea}
                    label='Comparison Buyers Guide and FAQ Prompt'
                    placeholder='Comparison Buyers Guide and FAQ Prompt'
                    value={comparisonBuyersGuideAndFaq}
                    onChange={(e: any) => { setComparisonBuyersGuideAndFaq(e.target.value) }}
                />


                <div className='divider-dotted' />
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Update Comparison Conclusion Prompt' onClick={() => updatePromptByTagAndNameClicked('COMPARISON_CONCLUSION', comparisonConclusion)} />
                </div>
                <Form.Field
                    id='form-textarea-control-COMPARISON_CONCLUSION'
                    control={TextArea}
                    label='Comparison Conclusion Prompt'
                    placeholder='Comparison Conclusion Prompt'
                    value={comparisonConclusion}
                    onChange={(e: any) => { setComparisonConclusion(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div className='divider-dotted' />
            </Form>
        </div>
    );

}

export default AmobeezPromptsComponent;
