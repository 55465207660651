import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Input, Form, TextArea } from 'semantic-ui-react';
import './WebsiteSettingsEditComponent.scss';
import { appContext } from '../../App';
import {  updateWebsite } from '../../Helpers/api';
import {  Website } from '../../Modals/ContextObj';


interface WebsiteSettingsEditComponentProps {
    selectedWebsiteId: number,
    originalWebsites: Website[]

}

const WebsiteSettingsEditComponent = (props: WebsiteSettingsEditComponentProps) => {

    const { setIsLoading } = useContext(appContext);
    const [displayName, setDisplayName] = useState('');
    const [domain, setDomain] = useState('');
    const [settings, setSettings] = useState('');
    const [menuItems, setMenuItems] = useState('');
    const [affiliateLink, setAffiliateLink] = useState('');
    const [htmlBlock1, setHtmlBlock1] = useState('');
    const [htmlBlock2, setHtmlBlock2] = useState('');


    useEffect(() => {

        const tmpWebsite = props.originalWebsites.find(w => w.id === props.selectedWebsiteId);
        setDisplayName(tmpWebsite?.displayName ? tmpWebsite?.displayName : '');
        setDomain(tmpWebsite?.domain ? tmpWebsite?.domain : '');
        setSettings(tmpWebsite?.settings ? tmpWebsite?.settings : '');
        setMenuItems(tmpWebsite?.menuItems ? tmpWebsite?.menuItems : '');
        setAffiliateLink(tmpWebsite?.affiliateLink ? tmpWebsite?.affiliateLink : '');
        setHtmlBlock1(tmpWebsite?.htmlBlock1 ? tmpWebsite?.htmlBlock1 : '');
        setHtmlBlock2(tmpWebsite?.htmlBlock2 ? tmpWebsite?.htmlBlock2 : '');


    }, [props.selectedWebsiteId])

    const updateSettingsData = async () => {

        const payload = {
            id: props.selectedWebsiteId,
            domain,
            displayName,
            settings,
            menuItems,
            affiliateLink,
            htmlBlock1,
            htmlBlock2
        }

        const res = await updateWebsite(payload);
        // await removedCacheKeys({ cacheKeys: [`/api/website/getWebsiteByDomain::domain:${domain}`] });

        if (res?.length > 0) {
            toast.success('Updated Successfully!');
        } else {
            toast.error('Something went wrong..')
        }
    }

    return (
        <div className='comparison-edit-container'>

            <Form>

                <Form.Group widths='equal'>
                    <Form.Field
                        id='form-input-control-displayName'
                        control={Input}
                        label='DisplayName'
                        placeholder='DisplayName'
                        value={displayName}
                        onChange={(e: any) => { setDisplayName(e.target.value) }}
                    />

                    <Form.Field
                        id='form-input-control-domain'
                        control={Input}
                        label='Domain'
                        placeholder='Domain'
                        value={domain}
                        onChange={(e: any) => { setDomain(e.target.value) }}
                    />
                </Form.Group>

                <Form.Field
                    id='form-input-control-affiliate-link'
                    control={Input}
                    label='Affiliate Link'
                    placeholder='Affiliate Link'
                    value={affiliateLink}
                    onChange={(e: any) => { setAffiliateLink(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-html-block-1'
                    control={TextArea}
                    label='HTML Block 1'
                    placeholder='HTML Block 1'
                    value={htmlBlock1}
                    onChange={(e: any) => { setHtmlBlock1(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-html-block-2'
                    control={TextArea}
                    label='HTML Block 2'
                    placeholder='HTML Block 2'
                    value={htmlBlock2}
                    onChange={(e: any) => { setHtmlBlock2(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-settings'
                    control={TextArea}
                    label='Settings'
                    placeholder='Settings'
                    value={settings}
                    onChange={(e: any) => { setSettings(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-menuItems'
                    control={TextArea}
                    label='Menu Items'
                    placeholder='Menu Items'
                    value={menuItems}
                    onChange={(e: any) => { setMenuItems(e.target.value) }}
                />


                <div className='update-btn'>
                    <Form.Field
                        id='form-button-control-update-btn'
                        control={Button}
                        content='Update Settings Data'
                        disabled={props.selectedWebsiteId === -1}
                        onClick={() => updateSettingsData()}
                    />
                </div>
            </Form>
        </div>
    );

}

export default WebsiteSettingsEditComponent;
