import { useContext, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Dropdown, DropdownProps, Input, Icon, Image, Menu, Segment, Form } from 'semantic-ui-react';
import consts from '../../Helpers/consts';
import './LoginComponent.scss';
import { appContext } from '../../App';
import { MENU_TABS } from '../../Modals/ContextObj';
import { validateUser } from '../../Helpers/api';
import { redirect, useNavigate } from "react-router-dom";



interface LoginComponentProps {
}

const LoginComponent = (props: LoginComponentProps) => {

    const navigate = useNavigate();
    const { userAccessLevel ,updateUserAccessLevel } = useContext(appContext);
    const { currentTab, updateCurrentTab } = useContext(appContext);
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    //need to add here submit
    //if i have token in the local storage i need to call verifyToken,
    //if false, open login screen

    const handleLogin = async () => {

        const res = await validateUser({user: user, password: password});
        console.log(res);
        if(res?.success) {
            localStorage.setItem('data', res?.encryptedData);
            updateUserAccessLevel(res.accessLevel);

            /* if(res.accessLevel.indexOf('JPOST') !== -1){
                window.history.pushState(null, '', '/jpost');
            }
            else{
                if(res.accessLevel.indexOf('AMOBEEZ') !== -1){
                    window.history.pushState(null, '', '/amobeez');
                }
            } */
            
            window.location.reload();
            return true;
            //login success
        }
        else{
            if(res?.success === false){
              toast.error(`Error login - ${res.message}`);
              return false;
            }
          }
    }

    return (
        <div className='login-container'>
            <div className='login-box'>
                <div className='login-title'>Login</div>
                <Form>
                    <Form.Field>
                        <label>Username</label>
                        <input 
                            placeholder='Username' 
                            value={user} 
                            onChange={(e) => setUser(e.target.value)}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Password</label>
                        <input 
                            type='password' 
                            placeholder='Password' 
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Field>
                    <Button type='submit'  onClick={handleLogin} >Login</Button>
                </Form>
            </div>

        </div>
    );

}

export default LoginComponent;