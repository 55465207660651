import { ComparisonPage } from '../Modals/ContextObj';

const amazonProductUrl = 'https://www.amazon.com/dp/';

const inputXlsxHeadersAddProducts: string[] = [
  'sku',
  'merchant_code',
  'asin',
  'name',
  'merchant_name',
  'upc',
  'product_cost',
  'average_shipping_cost',
  'affiliate_type',
  'affiliate_link',
  'affiliate_merchant',
];

const inputXlsxHeadersAddKeywords: string[] = ['name', 'info', 'difficulty', 'searchVolume'];

const inputXlsxHeadersAddAmazonReport: string[] = [
  'category',
  'name',
  'asin',
  'seller',
  'trackingId',
  'dateShipped',
  'price',
  'itemsShipped',
  'returns',
  'revenue',
  'adFees',
  'device',
];

export const affiliateLinksForJpost = [
  {
    key: '?tag=jpost-tools-20',
    text: '?tag=jpost-tools-20',
    value: '?tag=jpost-tools-20',
  },
  {
    key: '?tag=jpost-gifts-20',
    text: '?tag=jpost-gifts-20',
    value: '?tag=jpost-gifts-20',
  },
  {
    key: '?tag=jpost-deals-20',
    text: '?tag=jpost-deals-20',
    value: '?tag=jpost-deals-20',
  },
  {
    key: '?tag=jpost-baby-20',
    text: '?tag=jpost-baby-20',
    value: '?tag=jpost-baby-20',
  },
  {
    key: '?tag=jpost-games-20',
    text: '?tag=jpost-games-20',
    value: '?tag=jpost-games-20',
  },
  {
    key: '?tag=jpost-office-20',
    text: '?tag=jpost-office-20',
    value: '?tag=jpost-office-20',
  },
  {
    key: '?tag=jpost-outdoors-20',
    text: '?tag=jpost-outdoors-20',
    value: '?tag=jpost-outdoors-20',
  },
  {
    key: '?tag=jpost-sport-20',
    text: '?tag=jpost-sport-20',
    value: '?tag=jpost-sport-20',
  },
  {
    key: '?tag=jpost-garden-20',
    text: '?tag=jpost-garden-20',
    value: '?tag=jpost-garden-20',
  },
  {
    key: '?tag=jpost-health-20',
    text: '?tag=jpost-health-20',
    value: '?tag=jpost-health-20',
  },
  {
    key: '?tag=jpost-beauty-20',
    text: '?tag=jpost-beauty-20',
    value: '?tag=jpost-beauty-20',
  },
  {
    key: '?tag=jpost-appl-20',
    text: '?tag=jpost-appl-20',
    value: '?tag=jpost-appl-20',
  },
  {
    key: '?tag=jpost-home-20',
    text: '?tag=jpost-home-20',
    value: '?tag=jpost-home-20',
  },
  {
    key: '?tag=jpost-fashion-20',
    text: '?tag=jpost-fashion-20',
    value: '?tag=jpost-fashion-20',
  },
  {
    key: '?tag=jpost-pets-20',
    text: '?tag=jpost-pets-20',
    value: '?tag=jpost-pets-20',
  },
  {
    key: '?tag=jpost-elect-20',
    text: '?tag=jpost-elect-20',
    value: '?tag=jpost-elect-20',
  },
];

export const getPathFromCategories = (cp: ComparisonPage) => {
  return cp.keyword;
  /* if ([2, 3, 4, 5, undefined].includes(cp.websiteId)) {
        return cp.keyword;
    }
    let path = cp.category1;
    if (cp.category2) {
        path = path + '/' + cp.category2;
    }
    if (cp.category3) {
        path = path + '/' + cp.category3;
    }
    return path; */
};

export default {
  amazonProductUrl,
  inputXlsxHeadersAddProducts,
  inputXlsxHeadersAddKeywords,
  inputXlsxHeadersAddAmazonReport,
};

export const capitalize = (str: string | undefined) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
};

export const getVersionStringByComparisonPage = (cp: ComparisonPage) => {
  let versionsString = '';
  if (cp.verified === true && cp.analystVersion === true) {
    versionsString = ' - Manual Editor + Analyst';
  } else if (cp.verified === true) {
    versionsString = ' - Manual Editor';
  } else if (cp.analystVersion === true) {
    versionsString = ' - Analyst';
  }

  return '(v' + cp.version + versionsString + ')';
};

export const enum ACCESS_LEVEL {
  ADMIN = 'ADMIN',
  JPOST = 'JPOST_EDITOR',
  EDITOR = 'AMOBEEZ_EDITOR',
}
export enum PUBLISHERS {
  GENERAL = 'general',
  AMOBEEZ = 'amobeez',
  JPOST = 'jpost',
  GUIDE_TOP_REVIEW = 'guide top review',
  LENSVID = 'lensvid',
  CTEC = 'ctech',
  INN = 'inn',
  NEWS_DIRECT = 'news direct',
  YNET = 'ynet',
  AMOBEEZ_DEMO = 'amobeez demo',
  YNET_NEWS = 'ynet news',
  BUYEREVIEWS = 'buyereviews',
  ISRAEL_HAYOM = 'israel hayom',
}

export enum WEBSITE_DOMAIN_NAMES {
  DECOR_HOME_BEAUTY = 'Decor Home Beauty',
  JPOST = 'jpost.com',
  GUIDE_TOP_REVIEW = 'guidetopreview.com',
  LENSVID = 'lensvid.com',
  CTECH = 'calcalistech.com',
  INN = 'israelnationalnews.com',
  NEWS_DIRECT = 'newsdirect.com',
  YNET = 'ynetnews.com',
  AMOBEEZ_DEMO = 'amobeez.com',
  YNET_NEWS = 'ynetnews.com/shopping',
  BUYEREVIEWS = 'buyereviews.com',
  ISRAEL_HAYOM = 'israelhayom.com',
}

export enum WEBSITE_ID {
  AMOBEEZ = 1,
  JPOST = 2,
  GUIDE_TOP_REVIEW = 3,
  LENSVID = 4,
  CTECH = 5,
  INN = 6,
  NEWS_DIRECT = 7,
  YNET = 8,
  AMOBEEZ_DEMO = 9,
  YNET_NEWS = 10,
  BUYEREVIEWS = 11,
  ISRAEL_HAYOM = 12,
}

export const publisherNameByWebsiteIdPosition = [
  '',
  'Amobeez',
  'Jpost',
  'Guide Top Review',
  'Lensvid',
  'Ctech',
  'Inn',
  'News Direct',
  'Ynet',
  'Amobeez Demo',
  'Ynet News',
  'Buyereviews',
  'Israel Hayom',
];

export const randomGradeByPosition = (position: number) => {
  if (position >= 9) {
    return 7.9;
  }
  const randomValues = [
    [9.9, 9.8],
    [9.7, 9.6],
    [9.5, 9.4],
    [9.3, 9.2],
    [9.1, 9.0],
    [8.9, 8.8],
    [8.7, 8.6],
    [8.5, 8.4],
    [8.3, 8.2],
    [8.1, 8.0],
  ];
  const rand = Math.floor(Math.random() * 2);
  return randomValues[position][rand];
};
