import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { cellDataType } from '../../Helpers/customTypes';
import { AmazonProduct, Product } from '../../Modals/ContextObj';
import { Icon, Image } from 'semantic-ui-react';
import toast from 'react-hot-toast';
import { WEBSITE_ID, capitalize } from '../../Helpers/consts';
import { updateProduct } from '../../Helpers/api';

// a little function to help us with reordering the result
const reorder = (
  list: {
    id: string;
    content: string;
    displayName: string;
    imageUrl: string;
    productType: string;
    affiliateCommission: number;
    creatorConnectionCommission: number;
    affiliateType: string;
    stock: boolean;
    price: string;
    link: string;
    nonReplacable: boolean;
    nonReplacableEpcTest: boolean;
  }[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 1,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? '#a8c7ff' : 'white',

  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? '#a8c7ff' : '#a8c7ff',
  padding: grid,
  width: 'auto',
});

interface DraggableListProps {
  items: {
    product: Product;
    amazonData: AmazonProduct;
    keyword?: string | undefined;
  }[];
  websiteId: number;
  comparisonPageId: number;
  headersOrderChanged: (list: string[]) => void;
}

const DraggableList = (props: DraggableListProps) => {
  const [localItems, setLocalItems] = useState<
    {
      id: string;
      content: string;
      displayName: string;
      imageUrl: string;
      affiliateType: string;
      affiliateCommission: number;
      creatorConnectionCommission: number;
      productType: string;
      stock: boolean;
      price: string;
      link: string;
      nonReplacable: boolean;
      nonReplacableEpcTest: boolean;
    }[]
  >([]);

  useEffect(() => {
    const res = props.items.map((i, k) => ({
      id: `${k}`,
      content: `${i.product.asin}`,
      displayName: `${i.product.displayName}`,
      imageUrl: `${i.amazonData.image1}`,
      affiliateType: i.amazonData.affiliateType,
      affiliateCommission: i.amazonData.affiliateCommission ?? 0,
      creatorConnectionCommission: i.amazonData.creatorConnectionCommission ?? 0,
      productType:
        i.amazonData.affiliateType === 'LEVANTA' ||
        i.amazonData.affiliateType === 'ARCHER' ||
        i.amazonData.affiliateType === 'SAIRUX' ||
        i.amazonData.affiliateType === 'COSTINGTONS' ||
        i.amazonData.affiliateType === 'PERCH' ||
        i.amazonData.affiliateType === 'MAVERICKX' ||
        i.amazonData.affiliateType === 'PARTNER_BOOST' ||
        i.amazonData.affiliateType === 'DIRECT'
          ? `AFFILIATOR${i.amazonData.creatorConnectionCommission > 0 ? ' + CC' : ''}`
          : `${i.amazonData.creatorConnectionCommission > 0 ? ' + CC' : i.amazonData.affiliateType}`,
      stock: i.amazonData.stock ? true : false,
      price: `${i.amazonData.price}`,
      link: generateLink(i.amazonData),
      nonReplacable: i.product.nonReplacable ? true : false,
      nonReplacableEpcTest: i.product.nonReplacableEpcTest === true ? true : false,
    }));

    setLocalItems(res);
  }, [props.items]);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(localItems, result.source.index, result.destination.index);

    setLocalItems(items);
    props.headersOrderChanged(items.map((i) => i.content));
  };

  const generateLink = (amazonData: AmazonProduct) => {
    let affiliateLink = amazonData.link?.split('?')[0];
    if (amazonData.affiliateMerchant && amazonData.affiliateMerchant !== null && amazonData.affiliateMerchant !== '') {
      affiliateLink = affiliateLink + '?m=' + amazonData.affiliateMerchant + '&th=1'; //'&m=AIVBLXQ29IJ98&th=1'
    }
    return affiliateLink;
  };

  const copyToClipboard = (text: string) => {
    const input = document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    toast.success('Copied to clipboard!');
  };

  const updateProductReplaceable = async (
    e: React.MouseEvent<HTMLButtonElement>,
    asin: string,
    nonReplacable: boolean,
  ) => {
    e.stopPropagation();

    await updateProduct({
      asin,
      nonReplacable: !nonReplacable,
      comparisonPageId: props.comparisonPageId,
      websiteId: props.websiteId,
    });

    setLocalItems((prevItems) =>
      prevItems.map((item) => (item.content === asin ? { ...item, nonReplacable: !nonReplacable } : item)),
    );

    toast.success('Updated Successfully');
  };

  const updateProductNonReplacableEpcTest = async (
    e: React.MouseEvent<HTMLButtonElement>,
    asin: string,
    nonReplacableEpcTest: boolean,
  ) => {
    e.stopPropagation();

    await updateProduct({
      asin,
      nonReplacableEpcTest: !nonReplacableEpcTest,
      comparisonPageId: props.comparisonPageId,
      websiteId: props.websiteId,
    });

    setLocalItems((prevItems) =>
      prevItems.map((item) =>
        item.content === asin ? { ...item, nonReplacableEpcTest: !nonReplacableEpcTest } : item,
      ),
    );

    toast.success('Updated Successfully');
  };

  const getProductType = (productType: string, affiliateType: string) => {
    if (props.websiteId !== WEBSITE_ID.BUYEREVIEWS) {
      return capitalize(productType).replace('cc', 'CC');
    }

    if (productType.toLocaleLowerCase().endsWith('cc')) {
      return affiliateType + ' + CC';
    }

    return affiliateType;
  };

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {localItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    onClick={() => {
                      copyToClipboard(item.content);
                    }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <div
                      style={{
                        marginTop: '-5px',
                        fontWeight: 'bold',
                        fontSize: '16px',
                      }}
                    >
                      {index + 1}.
                    </div>
                    <Image src={item.imageUrl} size="tiny" />
                    <div style={{ paddingLeft: '40px' }}>
                      <div>
                        <b>ASIN: </b>
                        {item.content}
                      </div>
                      <div>
                        <b>Display Name: </b>
                        {item.displayName}
                      </div>
                      <div>
                        <b>
                          Product Type:{' '}
                          <span
                            style={
                              ['AFFILIATOR', 'CATALOG', 'AFFILIATOR + CC'].includes(item.productType)
                                ? { color: 'green' }
                                : { color: 'red' }
                            }
                          >
                            {getProductType(item.productType, item.affiliateType)}
                          </span>
                        </b>
                      </div>
                      {(item.productType === 'CATALOG' ||
                        props.websiteId === WEBSITE_ID.GUIDE_TOP_REVIEW ||
                        props.websiteId === WEBSITE_ID.BUYEREVIEWS) && (
                        <div>
                          <b>
                            Stock:{' '}
                            <span style={item.stock ? { color: 'green' } : { color: 'red' }}>
                              {item.stock ? 'In Stock' : ' Out of Stock'}
                            </span>
                          </b>
                        </div>
                      )}
                      <div>
                        <b>Price: </b>${item.price}
                      </div>
                      {props.websiteId === WEBSITE_ID.BUYEREVIEWS && (
                        <div>
                          <b>Commission: </b>
                          {item.affiliateCommission * 100}%
                          {item.creatorConnectionCommission > 0
                            ? ` + CC: ${item.creatorConnectionCommission * 100}%`
                            : ''}
                        </div>
                      )}
                      {(props.websiteId === WEBSITE_ID.GUIDE_TOP_REVIEW ||
                        props.websiteId === WEBSITE_ID.BUYEREVIEWS) && (
                        <div style={{ zIndex: 10000 }}>
                          <b>Replaceable: </b>
                          <span>
                            {item.nonReplacable ? (
                              <Icon
                                name="lock"
                                color="yellow"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                  updateProductReplaceable(e, item.content, item.nonReplacable)
                                }
                              ></Icon>
                            ) : (
                              <Icon
                                name="lock open"
                                color="grey"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                  updateProductReplaceable(e, item.content, item.nonReplacable)
                                }
                              ></Icon>
                            )}
                          </span>
                        </div>
                      )}
                      {(props.websiteId === WEBSITE_ID.GUIDE_TOP_REVIEW ||
                        props.websiteId === WEBSITE_ID.BUYEREVIEWS) && (
                        <div style={{ zIndex: 10000 }}>
                          <b>EPC Test Mode: </b>
                          <span>
                            {item.nonReplacableEpcTest ? (
                              <Icon
                                name="lock"
                                color="blue"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                  updateProductNonReplacableEpcTest(e, item.content, item.nonReplacableEpcTest)
                                }
                              ></Icon>
                            ) : (
                              <Icon
                                name="lock open"
                                color="grey"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                  updateProductNonReplacableEpcTest(e, item.content, item.nonReplacableEpcTest)
                                }
                              ></Icon>
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
