import { useContext, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Dropdown, DropdownProps, Input, Icon, Image, Form, TextArea } from 'semantic-ui-react';
import consts from '../../Helpers/consts';
import './ProductEditComponent.scss';
import { appContext } from '../../App';
import { getAwardPathByName } from '../../Helpers/assetsHelper';
import {
  generateProductBottomLineAndBestUse,
  generateProductDescription,
  generateProductDisplayName,
  generateProductProsAndCons,
  getProductByAsin,
  removedCacheKeys,
  updateProduct,
} from '../../Helpers/api';
import { AmazonProduct, Product } from '../../Modals/ContextObj';

interface ProductEditComponentProps {
  selectedWebsiteId: number;
  domain: string;
}

const ProductEditComponent = (props: ProductEditComponentProps) => {
  const { setIsLoading } = useContext(appContext);
  const [asinToEdit, setAsinToEdit] = useState('');

  // const [productId, setProductId] = useState(-1);
  const [displayName, setDisplayName] = useState('');
  const [pros, setPros] = useState('');
  const [cons, setCons] = useState('');
  const [productDescription, setProductDescription] = useState('');

  // const [amazonProductId, setAmazonProductId] = useState(-1);
  const [name, setName] = useState('');
  const [asin, setAsin] = useState('');
  const [affiliateType, setAffiliateType] = useState('');
  const [affiliateLink, setAffiliateLink] = useState('');
  const [affiliateMerchant, setAffiliateMerchant] = useState('');

  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [price, setPrice] = useState(0);
  const [rating, setRating] = useState(0);
  const [link, setLink] = useState('');
  const [comparisonPageCategoryPath, setComparisonPageCategoryPath] = useState('');

  const getAsinFromDB = async () => {
    const res: { product: Product; amazonData: AmazonProduct } = await getProductByAsin(
      asinToEdit,
      props.selectedWebsiteId,
    );
    if (res) {
      // setProductId(res.product.id);
      setAsin(res.product.asin || '');
      setAffiliateType(res.amazonData.affiliateType);
      setAffiliateLink(res.amazonData.affiliateLink);
      setAffiliateMerchant(res.amazonData.affiliateMerchant);
      setDisplayName(res.product.displayName || '');
      setPros(res.product.pros || '');
      setCons(res.product.cons || '');
      setProductDescription(res.product.productDescription || '');

      // setAmazonProductId(res.amazonData.id);
      setName(res.amazonData.name || '');
      setImage1(res.amazonData.image1 || '');
      setImage2(res.amazonData.image2 || '');
      setPrice(res.amazonData.price || 0);
      setRating(res.amazonData.rating || 0);
      setLink(res.amazonData.link || '');

      setComparisonPageCategoryPath(
        `${res.amazonData.category1
          .replace(/\s+/g, '')
          .replace(/[^\w\s]/g, '-')
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .toLowerCase()}/${res.amazonData.category2
          .replace(/\s+/g, '')
          .replace(/[^\w\s]/g, '-')
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .toLowerCase()}/${res.amazonData.category3
          .replace(/\s+/g, '')
          .replace(/[^\w\s]/g, '-')
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .toLowerCase()}`,
      );
    } else {
      toast.error('ASIN not found!');
    }
  };

  const updateProductData = async () => {
    if (props.selectedWebsiteId !== -1) {
      const payload = {
        // id: productId,
        asin,
        displayName,
        pros,
        cons,
        productDescription,
        name,
        image1,
        image2,
        price,
        rating,
        affiliateType,
        affiliateLink,
        affiliateMerchant,
        websiteId: props.selectedWebsiteId,
      };

      const res = await updateProduct(payload);

      /*  await removedCacheKeys({
                 cacheKeys: [`/api/website/getWebsiteByDomain::domain:${props.domain}`,
                 `/api/categoriesPage/getCategoriesPageByPath::path:${comparisonPageCategoryPath}::websiteId${props.selectedWebsiteId}`,
                 `/api/comparisonPage/getComparisonPageByPath::path:${comparisonPageCategoryPath}::websiteId${props.selectedWebsiteId}`]
             }); */

      if (res === true) {
        toast.success('Updated Successfully!');
      } else {
        toast.error('Something went wrong..');
      }
    } else {
      toast.error('Must select website before saving data.');
    }
  };

  const regenerateProsAndCons = async () => {
    setIsLoading(true);
    const res = await generateProductProsAndCons({ link });
    setPros(res.pros);
    setCons(res.cons);
    setIsLoading(false);
  };

  const regenerateProductDescription = async () => {
    setIsLoading(true);
    const res = await generateProductDescription({ link });
    setProductDescription(res.productDescription);
    setIsLoading(false);
  };

  const regenerateProductDisplayName = async () => {
    setIsLoading(true);
    const res = await generateProductDisplayName({ link });
    setDisplayName(res.displayName);
    setIsLoading(false);
  };

  return (
    <div className="product-edit-container">
      <div className="get-product-by-asin">
        <div>Edit ASIN: </div>
        <Input
          placeholder="ASIN"
          value={asinToEdit}
          onChange={(e) => {
            setAsinToEdit(e.target.value);
          }}
        >
          <input />
        </Input>
        <Button icon="edit" content="Load" onClick={() => getAsinFromDB()} />
      </div>

      <Form>
        <Form.Group widths="equal">
          <Form.Field
            id="form-input-control-affiliate-type"
            control={Input}
            label="Affiliate Type"
            placeholder="Affiliate Type"
            value={affiliateType}
            onChange={(e: any) => {
              setAffiliateType(e.target.value);
            }}
          />
          <Form.Field
            id="form-input-control-affiliate-link"
            control={Input}
            label="Affiliate Link"
            placeholder="Affiliate Link"
            value={affiliateLink}
            onChange={(e: any) => {
              setAffiliateLink(e.target.value);
            }}
          />
          <Form.Field
            id="form-input-control-affiliate-merchant"
            control={Input}
            label="Affiliate Merchant"
            placeholder="Affiliate Merchant"
            value={affiliateMerchant}
            onChange={(e: any) => {
              setAffiliateMerchant(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field
            id="form-input-control-name"
            control={Input}
            label="Name"
            placeholder="Name"
            value={name}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
          />
          <Form.Field
            id="form-input-control-star-ranking"
            control={Input}
            label="Star Ranking"
            placeholder="Star Ranking"
            value={rating}
            onChange={(e: any) => {
              setRating(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field
            id="form-input-control-asin"
            control={Input}
            label="ASIN"
            placeholder="ASIN"
            value={asin}
            onChange={(e: any) => {
              setAsin(e.target.value);
            }}
          />
          <Form.Field
            id="form-input-control-price"
            control={Input}
            label="Price"
            placeholder="Price"
            value={price}
            onChange={(e: any) => {
              setPrice(e.target.value);
            }}
          />
        </Form.Group>

        <div className="divider-dotted" />
        <div className="generate-part">
          <Button icon="refresh" content="Re-Generate Display Name" onClick={() => regenerateProductDisplayName()} />
        </div>

        <Form.Field
          id="form-textarea-control-product-display-name"
          control={TextArea}
          label="Display Name"
          placeholder="Display Name"
          value={displayName}
          onChange={(e: any) => {
            setDisplayName(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="generate-part">
          <Button icon="refresh" content="Re-Generate Pros and Cons" onClick={() => regenerateProsAndCons()} />
        </div>

        <Form.Field
          id="form-input-control-pros"
          control={TextArea}
          label="Pros"
          placeholder="Pros"
          value={pros}
          onChange={(e: any) => {
            setPros(e.target.value);
          }}
        />

        <Form.Field
          id="form-input-control-cons"
          control={TextArea}
          label="Cons"
          placeholder="Cons"
          value={cons}
          onChange={(e: any) => {
            setCons(e.target.value);
          }}
        />

        <div className="divider-dotted" />
        <div className="generate-part">
          <Button
            icon="refresh"
            content="Re-Generate Product Description"
            onClick={() => regenerateProductDescription()}
          />
        </div>

        <Form.Field
          id="form-textarea-control-product-description"
          control={TextArea}
          label="Product Description"
          placeholder="Product Description"
          value={productDescription}
          onChange={(e: any) => {
            setProductDescription(e.target.value);
          }}
        />

        <div className="divider-dotted" />

        <div className="img-container-image-url">
          <Form.Field
            id="form-input-control-image-url"
            control={Input}
            label="Image Url"
            placeholder="Image Url"
            value={image1}
            onChange={(e: any) => {
              setImage1(e.target.value);
            }}
          />
          <Image src={image1} as="a" href={image1} target="_blank" />
        </div>

        <div className="img-container-image-url">
          <Form.Field
            id="form-input-control-image-url-hq"
            control={Input}
            label="Image Url 2"
            placeholder="Image Url 2"
            value={image2}
            onChange={(e: any) => {
              setImage2(e.target.value);
            }}
          />
          <Image src={image2} as="a" href={image2} target="_blank" />
        </div>
        <div className="update-btn">
          <Form.Field
            id="form-button-control-update-btn"
            control={Button}
            content="Update Product Data"
            disabled={asin === ''}
            onClick={() => updateProductData()}
          />
        </div>
      </Form>
    </div>
  );
};

export default ProductEditComponent;
