import axios from "axios";

const AffMsGoogleApi = axios.create({
    baseURL: 'https://aff-ms-google.oom-panel.com', //PROD
    withCredentials: true,
    timeout: 1200000
});

export const removeGoogleIndexByUrl = async (payload: any) => {
    try {
        const res = await AffMsGoogleApi.post('/aff-ms-google/googleIndexingApi/removeIndexByUrl', payload);
        if (res.status === 200) {
            return res?.data?.data;
        } else {
            return [];
        }
    }
    catch (error) {
        return [];
    }
}


export const createGoogleAdsCampaignForComparisonPage = async (payload: any) => {
    try {
        const res = await AffMsGoogleApi.post('/aff-ms-google/googleAds/createGoogleAdsCampaignByKwAndUrl', payload);
        if (res.status === 200) {
            return res?.data?.data;
        } else {
            return [];
        }
    }
    catch (error) {
        return [];
    }
}


export const getCampaignByUrl = async (payload: any) => {
    try {
        const res = await AffMsGoogleApi.post('/aff-ms-google/googleAds/getCampaignByUrl', payload);
        if (res.status === 200) {
            return res?.data?.data;
        } else {
            return [];
        }
    }
    catch (error) {
        return [];
    }
}


export const getCampaignByStatus = async (payload: any) => {
    try {
        const res = await AffMsGoogleApi.post('/aff-ms-google/googleAds/getCampaignByStatus', payload);
        if (res.status === 200) {
            return res?.data?.data;
        } else {
            return [];
        }
    }
    catch (error) {
        return [];
    }
}