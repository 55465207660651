import { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Form, Input, TextArea } from 'semantic-ui-react';
import './KeywordsManagementComponent.scss';
import { appContext } from '../../App';
import {
  addKeywordToWebsiteId,
  addTaskToQueueJob,
  filterKeywordsToRelevantItemsOnly,
  getAllKeywords,
  getAllKeywordsManagement,
  getAllUsedKeywordsByWebsiteId,
  updateKeywordsManagementByWebsiteId,
} from '../../Helpers/api';
import { Keyword, KeywordsManagement, cellDataType } from '../../Modals/ContextObj';
import { downloadUnicodeCSV } from '../../Helpers/csvHelper';
import consts from '../../Helpers/consts';
import readXlsxFile from 'read-excel-file';

interface KeywordsManagementComponentProps {
  selectedWebsiteId: number;
}

const KeywordsManagementComponent = (props: KeywordsManagementComponentProps) => {
  const { setIsLoading } = useContext(appContext);
  const [uploadedFile, setUploadedFile] = useState<string | null>();
  const [originalFileHeaders, setOriginalFileHeaders] = useState<cellDataType[]>([]);
  const [originalFileRows, setOriginalFileRows] = useState<cellDataType[][]>([]);
  const [allKeywordsManagement, setAllKeywordsManagement] = useState<KeywordsManagement[]>([]);
  const [totalKeywordsCount, setTotalKeywordsCount] = useState(0);
  const [totalKwByWebsiteId, setTotalKwByWebsiteId] = useState<{ websiteId: number; totalKw: number }[]>([]);
  const [selectedWebsiteIdKeywords, setSelectedWebsiteIdKeywords] = useState<string[]>([]);

  const [generalKwPerDay, setGeneralKwPerDay] = useState(0);
  const [affiliateKwPerDay, setAffiliateKwPerDay] = useState(0);
  const [topSaleKwPerDay, setTopSaleKwPerDay] = useState(0);
  const [totalComparisonPages, setTotalComparisonPages] = useState(0);

  const [addedKeywordDistribution, setAddedKeywordDistribution] = useState('');

  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    getAllKeywordsManagementData();
  }, []);

  useEffect(() => {
    getAllUsedKeywordsByWebsiteIdData();
    const found = allKeywordsManagement.find((i) => i.websiteId === props.selectedWebsiteId);
    if (found) {
      setGeneralKwPerDay(found?.generalKwPerDay);
      setAffiliateKwPerDay(found?.affiliateKwPerDay);
      setTopSaleKwPerDay(found?.topSaleKwPerDay);
    } else {
      setGeneralKwPerDay(0);
      setAffiliateKwPerDay(0);
      setTopSaleKwPerDay(0);
    }

    const foundWb = totalKwByWebsiteId.find((i) => i.websiteId === props.selectedWebsiteId);
    if (foundWb) {
      setTotalComparisonPages(foundWb?.totalKw);
    } else {
      setTotalComparisonPages(0);
    }
  }, [props.selectedWebsiteId]);

  const getAllUsedKeywordsByWebsiteIdData = async () => {
    setIsLoading(true);
    const res: string[] = await getAllUsedKeywordsByWebsiteId({
      websiteId: props.selectedWebsiteId,
    });
    setSelectedWebsiteIdKeywords(res);

    setIsLoading(false);
  };

  const getAllKeywordsManagementData = async () => {
    setIsLoading(true);
    const res: {
      keywordsManagementData: KeywordsManagement[];
      totalKeywords: number;
      totalKwByWebsiteId: { websiteId: number; totalKw: number }[];
    } = await getAllKeywordsManagement();

    setAllKeywordsManagement(res.keywordsManagementData);
    setTotalKeywordsCount(res.totalKeywords);
    setTotalKwByWebsiteId(res.totalKwByWebsiteId);

    setIsLoading(false);
  };

  const updateKeywordsManagementByWebsiteIdHanler = async () => {
    setIsLoading(true);
    await updateKeywordsManagementByWebsiteId({
      websiteId: props.selectedWebsiteId,
      generalKwPerDay,
      affiliateKwPerDay,
      topSaleKwPerDay,
    });
    toast.success('Updated Successfully!');
    setIsLoading(false);
  };

  const handleGenerateBtnClicked = async () => {
    setIsLoading(true);

    if (addedKeywordDistribution === '') {
      toast.error('keyword list is empty');
      return;
    }

    const payload = {
      keywords: addedKeywordDistribution.split('\n'),
    };

    await addTaskToQueueJob({
      action: 'addNewKeywordsToDistributionProcess',
      params: payload,
    });

    setIsLoading(false);

    toast.success('Starting the process, it will take a while..');
  };

  const handleFileUploaded = async (input: any) => {
    setUploadedFile(input.name);
    const rowsWithHeaders: cellDataType[][] = await readXlsxFile(input);
    const headers = rowsWithHeaders?.[0];
    const rows = rowsWithHeaders.slice(1, rowsWithHeaders.length);
    setOriginalFileHeaders(headers);
    setOriginalFileRows(rows);
  };

  return (
    <div className="keywords-managements-container">
      <div className="import-csv-container">
        <div className="title">Add new Keywords into DB:</div>

        <div className="textarea-container">
          <TextArea
            label="Keyword Distribution"
            placeholder={`keyword 1
keyword 2
keyword 3
...`}
            value={addedKeywordDistribution}
            onChange={(e: any) => {
              setAddedKeywordDistribution(e.target.value);
            }}
          />
        </div>
        <Button
          content="Save Keywords to DB"
          icon="save"
          labelPosition="left"
          onClick={() => handleGenerateBtnClicked()}
        />
      </div>

      {allKeywordsManagement?.length > 0 && generalKwPerDay > 0 && (
        <>
          <div className="kw-info-container">
            <span className="kw-title">
              <b>Current Comparison Pages:</b> {totalComparisonPages}
            </span>
            {/* <span className='kw-title'><b>General Keywords Availability: </b>{totalKeywordsCount}</span> */}
          </div>
          <div className="update-kw-form">
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  id="form-input-control-general-kw-per-day"
                  control={Input}
                  label="General Keywords Per Day"
                  placeholder="0"
                  value={generalKwPerDay}
                  onChange={(e: any) => {
                    setGeneralKwPerDay(e.target.value);
                  }}
                />
                {/*  <Form.Field
                                    id='form-input-control-affiliate-kw-per-day'
                                    control={Input}
                                    label='Affiliate Keywords Per Day'
                                    placeholder='0'
                                    value={affiliateKwPerDay}
                                    onChange={(e: any) => { setAffiliateKwPerDay(e.target.value) }}
                                />
                                <Form.Field
                                    id='form-input-control-top-sale-kw-per-day'
                                    control={Input}
                                    label='Top Sales Keywords Per Day'
                                    placeholder='0'
                                    value={topSaleKwPerDay}
                                    onChange={(e: any) => { setTopSaleKwPerDay(e.target.value) }}
                                /> */}

                <Button icon="save" content="Update" onClick={() => updateKeywordsManagementByWebsiteIdHanler()} />
              </Form.Group>
            </Form>
          </div>
        </>
      )}
    </div>
  );
};

export default KeywordsManagementComponent;
