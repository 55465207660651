import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Input, Image, Form, TextArea, Table } from 'semantic-ui-react';
import './JpostProductEditComponent.scss';
import { appContext } from '../../App';
import {
    addNewProductsToExistingJpostComparisonId, addTaskToQueueJob, generateJpostMiniProductDescription, generateJpostProductBottomLineAndBestUse, generateJpostProductConclusion,
    generateJpostProductDescription, generateJpostProductDisplayName, generateJpostProductMainSpecs, generateJpostProductProsAndCons,
    generateJpostProductTitle, generateJpostWhatWeDidntLike, generateJpostWhatWeLike, getComparisonPagesByAsinExistence, getProductByAsin, updateProduct
} from '../../Helpers/api';
import { AmazonProduct, Product } from '../../Modals/ContextObj';


interface JpostProductEditComponentProps {
    selectedWebsiteId: number,
    domain: string
}

const JpostProductEditComponent = (props: JpostProductEditComponentProps) => {

    const { setIsLoading } = useContext(appContext);

    // const [enableGenerateAsinBtn, setEnableGenerateAsinBtn] = useState(false);
    const [enableDownloadZip, setEnableDownloadZip] = useState(false);
    const [asinToEdit, setAsinToEdit] = useState('');

    const [comparisonPagesArr, setComparisonPagesArr] = useState<{ keyword: string, productionPageUrl: string }[]>([]);

    const [displayName, setDisplayName] = useState('');
    const [title, setTitle] = useState('');
    const [pros, setPros] = useState('');
    const [cons, setCons] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [miniProductDescription, setMiniProductDescription] = useState('');
    const [mainSpecs, setMainSpecs] = useState('');
    const [position, setPosition] = useState(10);


    // const [amazonProductId, setAmazonProductId] = useState(-1);  
    const [name, setName] = useState('');
    const [asin, setAsin] = useState('');
    const [affiliateType, setAffiliateType] = useState('');
    const [affiliateLink, setAffiliateLink] = useState('');
    const [affiliateMerchant, setAffiliateMerchant] = useState('');
    const [image1, setImage1] = useState('');
    const [image2, setImage2] = useState('');
    const [price, setPrice] = useState(0);
    const [rating, setRating] = useState(0);
    const [link, setLink] = useState('');
    const [amazonTitle, setAmazonTitle] = useState('');
    const [brand, setBrand] = useState('');
    const [lastSubCategory, setLastSubCategory] = useState('');
    // const [comparisonPageCategoryPath, setComparisonPageCategoryPath] = useState('');

    const [keyword, setKeyword] = useState('');
    const [specifications, setSpecifications] = useState('');


    const getAsinFromDB = async () => {

        const res: { product: Product, amazonData: AmazonProduct, keyword?: string } = await getProductByAsin(asinToEdit, props.selectedWebsiteId);
        if (res.product !== null) {
            // setProductId(res.product.id);
            setAsin(res.product.asin || '');
            setAffiliateType(res.amazonData.affiliateType);
            setAffiliateLink(res.amazonData.affiliateLink);
            setAffiliateMerchant(res.amazonData.affiliateMerchant);
            setDisplayName(res.product.displayName || '');
            setTitle(res.product.title || '');
            setPros(res.product.pros || '');
            setCons(res.product.cons || '');
            setProductDescription(res.product.productDescription || '');
            setMiniProductDescription(res.product.miniProductDescription || '');
            setMainSpecs(res.product.mainSpecs || '');
            setPosition( res.product.position >= 0 ? res.product.position :  10);

            // setAmazonProductId(res.amazonData.id);
            setName(res.amazonData.name || '');
            setImage1(res.amazonData.image1 || '');
            setImage2(res.amazonData.image2 || '');
            setPrice(res.amazonData.price || 0);
            setRating(res.amazonData.rating || 0);
            setLink(res.amazonData.link || '');
            setAmazonTitle(res.amazonData.title || '');
            setBrand(res.amazonData.brand || '');
            setLastSubCategory(res.amazonData.lastSubCategory || '');

            setKeyword(res.keyword || '');

            setSpecifications(res.amazonData.specifications || '');

            setEnableDownloadZip(true);

            const compPages: { keyword: string, productionPageUrl: string }[] = await getComparisonPagesByAsinExistence({ asin: asinToEdit, websiteId: props.selectedWebsiteId })
            setComparisonPagesArr(compPages);

            // setComparisonPageCategoryPath(`${res.amazonData.category1.replace(/\s+/g, '').replace(/[^\w\s]/g, '-').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}/${res.amazonData.category2.replace(/\s+/g, '').replace(/[^\w\s]/g, '-').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}/${res.amazonData.category3.replace(/\s+/g, '').replace(/[^\w\s]/g, '-').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}`);

        } else {
            toast.error('ASIN not found!');
            // setEnableGenerateAsinBtn(true);
            setEnableDownloadZip(false);

            setAsin('');
            setAffiliateType('');
            setAffiliateLink('');
            setAffiliateMerchant('');
            setDisplayName('');
            setTitle('');
            setPros('');
            setCons('');
            setProductDescription('');
            setMiniProductDescription('');
            setMainSpecs('');
            setPosition(10);
            setName('');
            setImage1('');
            setImage2('');
            setPrice(0);
            setRating(0);
            setLink('');
            setAmazonTitle('');
            setBrand('');
            setLastSubCategory('');
            setKeyword('');
            setSpecifications('');
        }
    }

    const generateAsinData = async () => {
        setIsLoading(true);

        const payload = {
            asins: [asinToEdit],
            websiteId: props.selectedWebsiteId,
            comparisonPageId: -1,
            comparisonPageType: 'COMPARISON'
        }

        await addNewProductsToExistingJpostComparisonId(payload);
        await getAsinFromDB();
        // setEnableGenerateAsinBtn(false);
        setEnableDownloadZip(true);
        setIsLoading(false);
        toast.success('Product Generated successfully!')
    }

    const createSinglePage = async () => {
        try {
            // const response = await createSingleComparisonPageByAsin({ asin: asinToEdit });
            await addTaskToQueueJob({
                websiteId: props.selectedWebsiteId,
                action: 'createSingleComparisonPageByAsin',
                params: { websiteId: props.selectedWebsiteId, asin: asinToEdit }
            });

            toast.success('Single Page Created Successfully!');
        } catch (error) {
            console.error('Error:', error);
            // Handle error if the API call fails
        }
    }

    const updateProductData = async () => {
        if (props.selectedWebsiteId !== -1) {

            const payload = {
                // id: productId, 
                asin,
                displayName,
                title,
                pros,
                cons,
                productDescription,
                miniProductDescription,
                mainSpecs,
                name,
                image1,
                image2,
                price,
                rating,
                affiliateType,
                affiliateLink,
                affiliateMerchant,
                websiteId: props.selectedWebsiteId
            }

            const res = await updateProduct(payload);

            /* await removedCacheKeys({
                cacheKeys: [`/api/website/getWebsiteByDomain::domain:${props.domain}`,
                `/api/categoriesPage/getCategoriesPageByPath::path:${comparisonPageCategoryPath}::websiteId${props.selectedWebsiteId}`,
                `/api/comparisonPage/getComparisonPageByPath::path:${comparisonPageCategoryPath}::websiteId${props.selectedWebsiteId}`]
            }); */

            if (res === true) {
                toast.success('Updated Successfully!');
            } else {
                toast.error('Something went wrong..')
            }
        } else {
            toast.error('Must select website before saving data.')
        }

    }


    const regenerateJpostProductDisplayName = async () => {
        setIsLoading(true);
        const res = await generateJpostProductDisplayName({ link, amazonTitle, brand });
        setDisplayName(res.displayName);
        setIsLoading(false);
    }

    const regenerateJpostProductTitle = async () => {
        setIsLoading(true);
        const res = await generateJpostProductTitle({ lastCategory: lastSubCategory, keyword, productName: displayName });
        setDisplayName(res.displayName);
        setIsLoading(false);
    }

    const regenerateJpostProductProsAndCons = async () => {
        setIsLoading(true);
        const res = await generateJpostProductProsAndCons({ link, amazonTitle });
        setPros(res.pros);
        setCons(res.cons);
        setIsLoading(false);
    }


    const regenerateJpostMiniProductDescription = async () => {
        setIsLoading(true);
        const res = await generateJpostMiniProductDescription({ link, amazonTitle, position, keyword });
        setMiniProductDescription(res.miniProductDescription);
        setIsLoading(false);
    }

    const regenerateJpostProductMainSpecs = async () => {
        setIsLoading(true);
        const res = await generateJpostProductMainSpecs({ specifications });
        setMainSpecs(res.mainSpecs ? res.mainSpecs : '');
        setIsLoading(false);
    }

    return (
        <div className='product-edit-container'>

            <div className='get-product-by-asin'>
                <div>Edit ASIN: </div>
                <Input
                    placeholder='ASIN'
                    value={asinToEdit}
                    onChange={(e) => { setAsinToEdit(e.target.value) }}>
                    <input />
                </Input>
                <Button
                    icon='edit'
                    content='Load'
                    onClick={() => getAsinFromDB()} />

                <Button
                    icon='download'
                    content='Create Single Page'
                    disabled={!enableDownloadZip}
                    onClick={() => createSinglePage()} />
            </div>

            {/* <Button
                icon='add'
                style={enableGenerateAsinBtn ? { visibility: 'visible' } : { visibility: 'hidden' }}
                content='Generate ASIN Data'
                onClick={() => generateAsinData()} /> */}


            {comparisonPagesArr && comparisonPagesArr.length > 0 && <div style={{ marginTop: '-30px', marginBottom: '20px', border: '6px double #9cc8ff', padding: '15px' }}>
                <div><b>This ASIN appears in the following articles:</b></div>

                <Table sortable celled fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={2} >
                                Keyword
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2} >
                                Jpost URL
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {comparisonPagesArr.map((c, i) => (
                            <Table.Row key={i}>
                                <Table.Cell>{c.keyword}</Table.Cell>
                                <Table.Cell><a href={c.productionPageUrl} target="_blank">{c.productionPageUrl}</a></Table.Cell>
                            </Table.Row>))}
                    </Table.Body>
                </Table>

            </div>}
            <Form>

                <Form.Group widths='equal'>
                    <Form.Field
                        id='form-input-control-affiliate-type'
                        control={Input}
                        label='Affiliate Type'
                        placeholder='Affiliate Type'
                        value={affiliateType}
                        onChange={(e: any) => { setAffiliateType(e.target.value) }}
                    />
                    <Form.Field
                        id='form-input-control-affiliate-link'
                        control={Input}
                        label='Affiliate Link'
                        placeholder='Affiliate Link'
                        value={affiliateLink}
                        onChange={(e: any) => { setAffiliateLink(e.target.value) }}
                    />
                    <Form.Field
                        id='form-input-control-affiliate-merchant'
                        control={Input}
                        label='Affiliate Merchant'
                        placeholder='Affiliate Merchant'
                        value={affiliateMerchant}
                        onChange={(e: any) => { setAffiliateMerchant(e.target.value) }}
                    />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field
                        id='form-input-control-name'
                        control={Input}
                        label='Name'
                        placeholder='Name'
                        value={name}
                        onChange={(e: any) => { setName(e.target.value) }}
                    />
                    <Form.Field
                        id='form-input-control-star-ranking'
                        control={Input}
                        label='Star Ranking'
                        placeholder='Star Ranking'
                        value={rating}
                        onChange={(e: any) => { setRating(e.target.value) }}
                    />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field
                        id='form-input-control-asin'
                        control={Input}
                        label='ASIN'
                        placeholder='ASIN'
                        value={asin}
                        onChange={(e: any) => { setAsin(e.target.value) }}
                    />
                    <Form.Field
                        id='form-input-control-price'
                        control={Input}
                        label='Price'
                        placeholder='Price'
                        value={price}
                        onChange={(e: any) => { setPrice(e.target.value) }}
                    />
                </Form.Group>

                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Display Name' onClick={() => regenerateJpostProductDisplayName()} />
                </div>

                <Form.Field
                    id='form-textarea-control-product-display-name'
                    control={TextArea}
                    label='Display Name'
                    placeholder='Display Name'
                    value={displayName}
                    onChange={(e: any) => { setDisplayName(e.target.value) }}
                />


                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Title' onClick={() => regenerateJpostProductTitle()} />
                </div>

                <Form.Field
                    id='form-textarea-control-product-title'
                    control={TextArea}
                    label='Title'
                    placeholder='Title'
                    value={title}
                    onChange={(e: any) => { setTitle(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Pros and Cons' onClick={() => regenerateJpostProductProsAndCons()} />
                </div>

                <Form.Field
                    id='form-input-control-pros'
                    control={TextArea}
                    label='Pros'
                    placeholder='Pros'
                    value={pros}
                    onChange={(e: any) => { setPros(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-cons'
                    control={TextArea}
                    label='Cons'
                    placeholder='Cons'
                    value={cons}
                    onChange={(e: any) => { setCons(e.target.value) }}
                />

                <div className='divider-dotted' />
                {/*   <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Bottom Line and Best Use' onClick={() => regenerateJpostProductBottomLineAndBestUse()} />
                </div>
                <Form.Field
                    id='form-input-control-bottom-line'
                    control={TextArea}
                    label='Bottom Line'
                    placeholder='Bottom Line'
                    value={bottomLine}
                    onChange={(e: any) => { setBottomLine(e.target.value) }}
                />

                <Form.Field
                    id='form-input-control-best-use'
                    control={TextArea}
                    label='Best Use'
                    placeholder='Best Use'
                    value={bestUse}
                    onChange={(e: any) => { setBestUse(e.target.value) }}
                />

                <div className='divider-dotted' /> */}
                {/*  <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Product Description' onClick={() => regenerateJpostProductDescription()} />
                </div>

                <Form.Field
                    id='form-textarea-control-product-description'
                    control={TextArea}
                    label='Product Description'
                    placeholder='Product Description'
                    value={productDescription}
                    onChange={(e: any) => { setProductDescription(e.target.value) }}
                />


                <div className='divider-dotted' /> */}
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Product Mini Description' onClick={() => regenerateJpostMiniProductDescription()} />
                </div>

                <Form.Field
                    id='form-textarea-control-product-mini-description'
                    control={TextArea}
                    label='Product Mini Description'
                    placeholder='Product Mini Description'
                    value={miniProductDescription}
                    onChange={(e: any) => { setMiniProductDescription(e.target.value) }}
                />


                {/*   <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate What We Like' onClick={() => regenerateJpostWhatWeLike()} />
                </div>

                <Form.Field
                    id='form-textarea-control-what-we-like'
                    control={TextArea}
                    label='What We Like'
                    placeholder='What We Like'
                    value={whatWeLike}
                    onChange={(e: any) => { setWhatWeLike(e.target.value) }}
                /> */}

                {/*
                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate What We Didn`t Like' onClick={() => regenerateJpostWhatWeDidntLike()} />
                </div>

                <Form.Field
                    id='form-textarea-control-what-we-didnt-like'
                    control={TextArea}
                    label='What We Didn`t Like'
                    placeholder='What We Didn`t Like'
                    value={whatWeDidntLike}
                    onChange={(e: any) => { setWhatWeDidntLike(e.target.value) }}
                />

*/}
                {/*   <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Product Conclusion' onClick={() => regenerateJpostProductConclusion()} />
                </div>

                <Form.Field
                    id='form-textarea-control-product-conclusion'
                    control={TextArea}
                    label='Product Conclusion'
                    placeholder='Product Conclusion'
                    value={conclusion}
                    onChange={(e: any) => { setConclusion(e.target.value) }}
                />

                <div className='divider-dotted' />
                <div className='generate-part'>
                    <Button icon='refresh' content='Re-Generate Product Main Specs' onClick={() => regenerateJpostProductMainSpecs()} />
                </div>

                <Form.Field
                    id='form-textarea-control-product-main-specs'
                    control={TextArea}
                    label='Product Main Specs'
                    placeholder='Product Main Specs'
                    value={mainSpecs}
                    onChange={(e: any) => { setMainSpecs(e.target.value) }}
                />
 */}

                <div className='divider-dotted' />

                <div className='img-container-image-url'>
                    <Form.Field
                        id='form-input-control-image-url'
                        control={Input}
                        label='Image Url'
                        placeholder='Image Url'
                        value={image1}
                        onChange={(e: any) => { setImage1(e.target.value) }}
                    />
                    <Image
                        src={image1}
                        as='a'
                        href={image1}
                        target='_blank'
                    />
                </div>

                {/* <div className='img-container-image-url'>
                    <Form.Field
                        id='form-input-control-image-url-hq'
                        control={Input}
                        label='Image Url 2'
                        placeholder='Image Url 2'
                        value={image2}
                        onChange={(e: any) => { setImage2(e.target.value) }}
                    />
                    <Image
                        src={image2}
                        as='a'
                        href={image2}
                        target='_blank'
                    />
                </div> */}
                <div className='update-btn'>
                    <Form.Field
                        id='form-button-control-update-btn'
                        control={Button}
                        content='Update Product Data'
                        disabled={asin === ''}
                        onClick={() => updateProductData()}
                    />
                </div>
            </Form>
        </div>
    );

}

export default JpostProductEditComponent;