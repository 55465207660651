import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Dropdown,
  DropdownProps,
  Input,
  Icon,
  Image,
  Form,
  TextArea,
  Checkbox,
  Modal,
  ModalHeader,
  ModalContent,
  ModalDescription,
  ModalActions,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Popup,
  DropdownOnSearchChangeData,
  Select,
} from 'semantic-ui-react';
import './EditArticleComponent.scss';
import { appContext } from '../../App';
import {
  addNewProductsToExistingJpostComparisonId,
  createAnalystVersionByComparisonPageId,
  createManualVersionByComparisonPageId,
  deleteComparisonPageById,
  downloadSelectedImageByIndex,
  generateJpostBuyersGuide,
  generateJpostConclusion,
  generateJpostCoverImage,
  generateJpostFaq,
  generateJpostIntroContent,
  generateJpostTeaser,
  generateJpostTitle,
  getAllAmobeezCatalogProductsByCategories,
  getAllAmobeezCatalogProductsByCategoriesFlatPath,
  getComparisonPageByKeyword,
  getComparisonPageByPath,
  getTopAffiliateProducts,
  getResourcesOptionsFromDB,
  removeByComparisonPagePath,
  rewriteComparisonPageProductsById,
  searchArticleForAdminPanel,
  updateComparisonPage,
  updateProduct,
} from '../../Helpers/api';
import { AmazonProduct, ComparisonPage, ComparisonPageResp, Product } from '../../Modals/ContextObj';
import { DropDownObj } from '../../Helpers/customTypes';
import {
  ACCESS_LEVEL,
  PUBLISHERS,
  WEBSITE_DOMAIN_NAMES,
  WEBSITE_ID,
  getPathFromCategories,
  getVersionStringByComparisonPage,
  publisherNameByWebsiteIdPosition,
  randomGradeByPosition,
} from '../../Helpers/consts';
import DraggableList from '../DraggableList/DraggableList';
import {
  downloadLayoutsFiles,
  parentCtechYnetDownloadZipComparisonPage,
  parentPublishComparisonPageINN,
  parentPublishComparisonPageNewsDirect,
  publishComparisonPageToWordpress,
  publishComparisonPageToWpCms,
  updateImageToWPCms,
} from '../JpostHomeComponent/JpostHomeComponent';
import EditProductComponent from '../EditProductComponent/EditProductComponent';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  createGoogleAdsCampaignForComparisonPage,
  getCampaignByUrl,
  removeGoogleIndexByUrl,
} from '../../Helpers/affMsGoogle.api';

interface EditArticleComponentProps {
  domain: string;
  selectedWebsiteId: number;
  urlsDropDown: DropDownObj[];
  comparisonPagesByWebsite: ComparisonPage[];
}

const EditArticleComponent = (props: EditArticleComponentProps) => {
  const { setIsLoading, currentEditComparisonPage, userAccessLevel } = useContext(appContext);
  const [comparisonPagesFromSearchTerm, setComparisonPagesFromSearchTerm] = useState<ComparisonPage[]>();
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);
  const [activeMainAccordionIndex, setActiveMainAccordionIndex] = useState(-1);
  const [activeProductsAccordionIndex, setActiveProductsAccordionIndex] = useState(-1);
  const [activeAsinsAccordionIndex, setActiveAsinsAccordionIndex] = useState(-1);

  const [writerOptions, setWriterOptions] = useState<{ key: string; text: string; value: string }[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<{ key: string; text: string; value: string }[]>([]);

  const fontSizeArr = ['12px', '14px', '16px', '20px', '24px', '32px'];

  var Size = ReactQuill.Quill.import('attributors/style/size');
  Size.whitelist = fontSizeArr;
  ReactQuill.Quill.register(Size, true);

  const comparisonPageTypeOptions = [
    { key: 'COMPARISON', text: 'COMPARISON', value: 'COMPARISON' },
    { key: 'BLOG', text: 'BLOG', value: 'BLOG' },
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ size: fontSizeArr }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'size',
    '12px',
    '14px',
    '16px',
    '20px',
    '24px',
    '32px',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'color',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const [affiliationModalOpen, setAffiliationModalOpen] = useState(false);
  const [additionalAffiliationProducts, setAdditionalAffiliationProducts] = useState<
    {
      asin: string;
      image: string;
      price: number;
      percentage: string;
      affiliateType: string;
      potentialProfit: number;
      reviews: number;
      rating: number;
      link: string;
      brand: string;
    }[]
  >([]);

  const [id, setId] = useState(-1);
  const [comparisonPage, setComparisonPage] = useState<ComparisonPage>();
  const [keyword, setKeyword] = useState('');
  const [relatedKeywords, setRelatedKeywords] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [writterName, setWritterName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [introContent, setIntroContent] = useState('');
  const [introContentEdited, setIntroContentEdited] = useState('');
  const [conclusion, setConclusion] = useState('');
  const [conclusionEdited, setConclusionEdited] = useState('');
  const [buyersGuideAndFaq, setBuyersGuideAndFaq] = useState('');
  const [buyersGuideAndFaqEdited, setBuyersGuideAndFaqEdited] = useState('');
  const [faq, setFaq] = useState('');
  const [faqEdited, setFaqEdited] = useState('');
  const [teaser, setTeaser] = useState('');
  const [teaserEdited, setTeaserEdited] = useState('');
  const [notes, setNotes] = useState('');
  const [categoryImage, setCategoryImage] = useState('');
  const [productionPageUrl, setProductionPageUrl] = useState('');
  const [category3, setCategory3] = useState('');
  const [verified, setVerified] = useState(false);
  const [comparisonPageType, setComparisonPageType] = useState('');
  const [excludedAsins, setExcludedAsins] = useState('');
  const [excludedWords, setExcludedWords] = useState('');
  const [amazonCategoryId, setAmazonCategoryId] = useState('');
  const [forceBrandingLimit, setForceBrandingLimit] = useState(false);
  const [epcTestingModeEnabled, setEpcTestingModeEnabled] = useState(false);
  const [version, setVersion] = useState(1);

  const [urlsDropDown, setUrlsDropDown] = useState<DropDownObj[]>(props.urlsDropDown);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [linksForGenerate, setLinksForGenerate] = useState('');
  const [originalProducts, setOriginalProducts] = useState<
    {
      product: Product;
      amazonData: AmazonProduct;
      keyword?: string | undefined;
    }[]
  >([]);
  const [finalHeaders, setFinalHeaders] = useState<string[]>([]);

  const [asinToRemove, setAsinToRemove] = useState('');
  const [asinToAdd, setAsinToAdd] = useState('');

  const [alreadyHandled, setAlreadyHandled] = useState(false);

  const [urlPath, setUrlPath] = useState('');

  const [campaignsData, setCampaignsData] = useState<any[]>([]);

  const getAffiliateProductsFromDB = async () => {
    setIsLoading(true);

    const res: {
      asin: string;
      image: string;
      price: number;
      percentage: string;
      affiliateType: string;
      potentialProfit: number;
      reviews: number;
      rating: number;
      link: string;
      brand: string;
    }[] = await getAllAmobeezCatalogProductsByCategories({
      categoriesFlatPath: originalProducts?.[3]?.amazonData?.categoriesFlat.split(' > ').slice(-3).join(' > '),
      keyword,
    });

    setAdditionalAffiliationProducts(res.sort((a, b) => b.reviews - a.reviews));
    setAffiliationModalOpen(true);
    setIsLoading(false);
  };

  const getTopAffiliateProductsFromDB = async () => {
    setIsLoading(true);

    const res: {
      asin: string;
      image: string;
      price: number;
      percentage: string;
      affiliateType: string;
      potentialProfit: number;
      reviews: number;
      rating: number;
      link: string;
      brand: string;
    }[] = await getTopAffiliateProducts({
      categoriesFlatPath: originalProducts?.[3]?.amazonData?.categoriesFlat.split(' > ').slice(-3).join(' > '),
      amazonCategoryId,
      keyword,
    });

    setAdditionalAffiliationProducts(res);
    setAffiliationModalOpen(true);
    setIsLoading(false);
  };

  function isWriterFound(writerName: string, array: { key: string; text: string; value: string }[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].text === writerName) {
        return true;
      }
    }
    return false;
  }

  function getWPCategoryByName(categoryName: string, array: any[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].category === categoryName) {
        return array[i];
      }
    }
    return null;
  }

  function getWPCategoryTitleByName(categoryName: string, array: { id: string; title: string; category: string }[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].title === categoryName) {
        return array[i].category;
      }
    }

    return -1;
  }
  function getWPCategoryIdByName(categoryName: string, array: { id: string; title: string; category: string }[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].title === categoryName) {
        return array[i].id;
      }
    }

    return -1;
  }

  function isCategoryFound(categoryName: string, array: { id: string; title: string; category: string }[]) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].category === categoryName) {
        return true;
      }
    }
    return false;
  }

  const getComparisonDataFromDB = async (keyword: string, version: number) => {
    setIsLoading(true);

    const { writers, categories } = await getResourcesOptionsFromDB(props.selectedWebsiteId);

    const res: ComparisonPageResp = await getComparisonPageByKeyword(keyword, props.selectedWebsiteId, version);
    if (res?.comparisonPage) {
      setComparisonPage(res.comparisonPage);
      setId(res.comparisonPage.id);
      setKeyword(res.comparisonPage.keyword);
      setRelatedKeywords(res.comparisonPage.relatedKeywords);
      setTitle(res.comparisonPage.title);
      setDate(res.comparisonPage.date);
      setWritterName(res.comparisonPage.writterName);
      const catObj = getWPCategoryByName(res.comparisonPage?.wordpressPageSlug, categories);
      setCategoryName(catObj ? catObj.text : '');
      setBlogContent(res.comparisonPage.blogContent);
      setIntroContent(res.comparisonPage.introContent);
      setIntroContentEdited(res.comparisonPage.introContentEdited);
      setConclusion(res.comparisonPage.conclusion);
      setConclusionEdited(res.comparisonPage.conclusionEdited);
      setBuyersGuideAndFaq(res.comparisonPage.buyersGuideAndFaq);
      setBuyersGuideAndFaqEdited(res.comparisonPage.buyersGuideAndFaqEdited);
      setFaq(res.comparisonPage.faq);
      setFaqEdited(res.comparisonPage.faqEdited);
      setTeaser(res.comparisonPage.teaser);
      setTeaserEdited(res.comparisonPage.teaserEdited);
      setNotes(res.comparisonPage.notes);
      setProductionPageUrl(res.comparisonPage.productionPageUrl || '');
      setCategoryImage(res.comparisonPage.categoryImage);
      setCategory3(res.comparisonPage.category3);
      setVerified(res.comparisonPage.verified || false);
      setComparisonPageType(res.comparisonPage.comparisonPageType);
      setExcludedAsins(res.comparisonPage.excludedAsins || '');
      setExcludedWords(res.comparisonPage.excludedWords || '');
      setAmazonCategoryId(res.comparisonPage.amazonCategoryId || '');
      setForceBrandingLimit(res.comparisonPage.forceBrandingLimit || false);
      setEpcTestingModeEnabled(res.comparisonPage.epcTestingModeEnabled || false);
      setVersion(res.comparisonPage.version || 1);

      setLinksForGenerate(
        res.products
          .slice(0, 6)
          .map((p) => `link title: ${p.amazonData.title}.  from this url: ${p.amazonData.link} . `)
          .join(', '),
      );

      const originalProductsss = [
        ...res?.products
          .filter((p) => p.product.itemType === 'REGULAR')
          .sort((a, b) => a.product.position - b.product.position),
        ...res?.products
          .filter((p) => p.product.itemType === 'DEAL')
          .sort((a, b) => a.product.position - b.product.position),
      ];
      setOriginalProducts(originalProductsss);

      const category1 = res?.comparisonPage?.category1
        .replace(/\s+/g, '')
        .replace(/[^\w\s]/g, '-')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase();
      const category2 = res?.comparisonPage?.category2
        .replace(/\s+/g, '')
        .replace(/[^\w\s]/g, '-')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase();
      const category3 = res?.comparisonPage?.category3
        .replace(/\s+/g, '')
        .replace(/[^\w\s]/g, '-')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase();
      setUrlPath(category3);

      if (
        [WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) &&
        res?.comparisonPage?.productionPageUrl
      ) {
        const campaignResuls = await getCampaignByUrl({
          campaignUrl: res.comparisonPage.productionPageUrl,
          websiteId: props.selectedWebsiteId,
        });
        if (campaignResuls?.length > 0) {
          setCampaignsData(campaignResuls.map((c: any) => c.campaign));
        } else {
          setCampaignsData([]);
        }
      } else {
        setCampaignsData([]);
      }
    } else {
      toast.error('Comparison Data not found for this category!');
    }

    let writersMapped: { key: string; text: string; value: string }[] = [];

    if (writerOptions.length === 0) {
      writersMapped = writers.map((w: any) => ({
        key: w.id,
        text: w.name,
        value: w.name,
      }));

      setWriterOptions(writersMapped);

      if (!isWriterFound(res?.comparisonPage?.writterName, writersMapped) && writersMapped.length > 0) {
        setWritterName(writersMapped[0].text);
      }
    } else {
      if (!isWriterFound(res?.comparisonPage?.writterName, writerOptions)) {
        setWritterName(writerOptions[0].text);
      }
    }

    let categoriesMapped: { key: string; text: string; value: string }[] = [];

    if (props.selectedWebsiteId === WEBSITE_ID.YNET_NEWS || props.selectedWebsiteId === WEBSITE_ID.ISRAEL_HAYOM) {
      if (categoryOptions.length === 0) {
        categoriesMapped = categories.map((w: any) => ({
          key: w.title,
          text: w.title,
          value: w.title,
        }));

        setCategoryOptions(categoriesMapped);

        if (!isCategoryFound(res?.comparisonPage?.wordpressPageSlug, categories) && categoriesMapped.length > 0) {
          setCategoryName('');
        } else {
          const catObj = getWPCategoryByName(res?.comparisonPage?.wordpressPageSlug, categories);
          setCategoryName(catObj ? catObj.title : '');
        }
      } else {
        if (!isCategoryFound(res?.comparisonPage?.wordpressPageSlug, categories)) {
          setCategoryName('');
        }
      }
    }

    setIsLoading(false);
  };

  const regenerateData = async () => {
    try {
      setIsLoading(true);

      // await generateComparisonPageData(selectedUrl);
      setIsLoading(false);
      toast.success('Comparison data generated successfully!');
    } catch (e) {
      setIsLoading(false);
      toast.error('Failed to generated Comparison Page');
    }
  };

  const updateComparisonPageData = async () => {
    const { writers, categories } = await getResourcesOptionsFromDB(props.selectedWebsiteId);

    const payload = {
      id,
      title,
      date,
      writterName,
      introContent,
      introContentEdited,
      conclusion,
      conclusionEdited,
      categoryImage,
      buyersGuideAndFaq,
      buyersGuideAndFaqEdited,
      blogContent,
      faq,
      faqEdited,
      teaser,
      teaserEdited,
      notes,
      productionPageUrl,
      relatedKeywords,
      excludedAsins: excludedAsins?.replace(/\s/g, '') || '',
      excludedWords: excludedWords?.toLowerCase().replace(/\s/g, '') || '',
      forceBrandingLimit,
      epcTestingModeEnabled,
      comparisonPageType,
      amazonCategoryId,
      ...((props.selectedWebsiteId === WEBSITE_ID.YNET_NEWS || props.selectedWebsiteId === WEBSITE_ID.ISRAEL_HAYOM) && {
        wordpressPageSlug: getWPCategoryTitleByName(categoryName, categories),
      }),
      ...((props.selectedWebsiteId === WEBSITE_ID.YNET_NEWS || props.selectedWebsiteId === WEBSITE_ID.ISRAEL_HAYOM) && {
        wordpressParentId: getWPCategoryIdByName(categoryName, categories),
      }),
    };

    const res = await updateComparisonPage(payload);

    if (props.selectedWebsiteId === WEBSITE_ID.GUIDE_TOP_REVIEW) {
      await removeByComparisonPagePath({
        path: urlPath,
        websiteId: WEBSITE_ID.GUIDE_TOP_REVIEW,
      });
    } else if (props.selectedWebsiteId === WEBSITE_ID.BUYEREVIEWS) {
      await removeByComparisonPagePath({
        path: urlPath,
        websiteId: WEBSITE_ID.BUYEREVIEWS,
      });
    }

    if (res?.length > 0) {
      toast.success('Updated Successfully!');
    } else {
      toast.error('Something went wrong..');
    }
  };

  const handleUrlChange = async (event: React.SyntheticEvent<HTMLElement, Event> | null, data: DropdownProps) => {
    const value = urlsDropDown.find((o: DropDownObj) => o.value === data.value)?.value;

    if (value) {
      const obj = [...props.comparisonPagesByWebsite, ...(comparisonPagesFromSearchTerm || [])].find(
        (cp) => getPathFromCategories(cp) + getVersionStringByComparisonPage(cp) === value,
      );
      if (obj) {
        setSelectedUrl(value);
        await getComparisonDataFromDB(obj.keyword, obj.version);
      }
    }
  };

  const handleUrlSearchChange = async (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownOnSearchChangeData,
  ) => {
    if (data.searchQuery.length >= 3) {
      const foundPages: ComparisonPage[] = await searchArticleForAdminPanel({
        searchTerm: data.searchQuery,
        websiteId: props.selectedWebsiteId,
      });
      setComparisonPagesFromSearchTerm(foundPages || []);

      let tmpUrls = foundPages.map((c: ComparisonPage) => ({
        key: c.id,
        text: getPathFromCategories(c) + getVersionStringByComparisonPage(c),
        value: getPathFromCategories(c) + getVersionStringByComparisonPage(c),
      }));

      setUrlsDropDown(tmpUrls);
    } else {
      setUrlsDropDown(props.urlsDropDown);
    }
  };

  const regenerateJpostTitle = async () => {
    setIsLoading(true);
    const res = await generateJpostTitle({ keyword, lastCategory: category3 });
    setTitle(res.title);
    setIsLoading(false);
  };

  const regenerateJpostIntroContent = async () => {
    setIsLoading(true);
    const res = await generateJpostIntroContent({ keyword, title });
    setIntroContent(res.introContent);
    setIsLoading(false);
  };

  const regenerateJpostConclusion = async () => {
    setIsLoading(true);
    const res = await generateJpostConclusion({
      listOfProducts: linksForGenerate,
      keyword,
    });
    setConclusion(res.conclusion);
    setIsLoading(false);
  };

  const regenerateJpostBuyersGuide = async () => {
    setIsLoading(true);
    let allKeywords = relatedKeywords !== '' ? `${keyword}, ${relatedKeywords}` : keyword;
    const res = await generateJpostBuyersGuide({ keyword: allKeywords });
    setBuyersGuideAndFaq(res.buyersGuideAndFaq);
    setIsLoading(false);
  };

  const regenerateJpostFaq = async () => {
    setIsLoading(true);
    let allKeywords = relatedKeywords !== '' ? `${keyword}, ${relatedKeywords}` : keyword;
    const res = await generateJpostFaq({ keyword: allKeywords });
    setFaq(res.faq);
    setIsLoading(false);
  };

  const regenerateJpostTeaser = async () => {
    setIsLoading(true);
    const res = await generateJpostTeaser({ keyword });
    setTeaser(res.teaser);
    setIsLoading(false);
  };

  const regenerateJpostCoverImage = async () => {
    setIsLoading(true);

    let type = '';
    if (props.selectedWebsiteId === WEBSITE_ID.JPOST) {
      type = PUBLISHERS.JPOST;
    } else if ([WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)) {
      type = PUBLISHERS.GUIDE_TOP_REVIEW;
    } else if (props.selectedWebsiteId === WEBSITE_ID.LENSVID) {
      type = PUBLISHERS.LENSVID;
    } else if (props.selectedWebsiteId === WEBSITE_ID.CTECH) {
      type = PUBLISHERS.CTEC;
    } else if (props.selectedWebsiteId === WEBSITE_ID.INN) {
      type = PUBLISHERS.INN;
    } else if (props.selectedWebsiteId === WEBSITE_ID.NEWS_DIRECT) {
      type = PUBLISHERS.NEWS_DIRECT;
    } else if (props.selectedWebsiteId === WEBSITE_ID.YNET) {
      type = PUBLISHERS.YNET;
    } else if (props.selectedWebsiteId === WEBSITE_ID.AMOBEEZ_DEMO) {
      type = PUBLISHERS.NEWS_DIRECT; // we want here image without lable
    } else if (props.selectedWebsiteId === WEBSITE_ID.YNET_NEWS) {
      type = PUBLISHERS.YNET; // image tag the same as YNET
    } else if (props.selectedWebsiteId === WEBSITE_ID.BUYEREVIEWS) {
      type = PUBLISHERS.BUYEREVIEWS;
    } else if (props.selectedWebsiteId === WEBSITE_ID.ISRAEL_HAYOM) {
      type = PUBLISHERS.ISRAEL_HAYOM;
    }

    const betterIntro =
      introContentEdited && introContentEdited !== '' && introContentEdited !== null
        ? introContentEdited
        : introContent;

    const res = await generateJpostCoverImage({
      introContent: betterIntro,
      title,
      type,
      comparisonPageType,
    });
    if (res.imageUrl) {
      setCategoryImage(res.imageUrl);
    } else {
      toast.error('Failed to generate image. :(');
    }
    setIsLoading(false);
  };

  const reloadProducts = async () => {
    //TODO
  };

  const deleteTheEntireComparisonPage = async () => {
    setIsLoading(true);

    await deleteComparisonPageById(id);

    if (props.selectedWebsiteId === WEBSITE_ID.GUIDE_TOP_REVIEW) {
      await removeGoogleIndexByUrl({
        url: productionPageUrl,
        websiteId: WEBSITE_ID.GUIDE_TOP_REVIEW,
      });
    } else if (props.selectedWebsiteId === WEBSITE_ID.BUYEREVIEWS) {
      await removeGoogleIndexByUrl({
        url: productionPageUrl,
        websiteId: WEBSITE_ID.BUYEREVIEWS,
      });
    }

    toast.success('Deleted successfully!');
    setIsLoading(false);
  };

  const formatFaq = (faq: string) => {
    let formattedText = faq.replace(/(Q:|A:)/g, (match, p1, offset) => {
      if (p1 === 'Q:' && offset > 0) {
        // Add line break before subsequent "Q:"
        return `\n${match}`;
      } else {
        // Add line break before each "A:" and first "Q:"
        return `${offset > 0 ? '' : ''}${match}`;
      }
    });

    return (
      <div>
        {formattedText.split(/\n/).map((line, index) => (
          <div style={{ marginBottom: index % 2 !== 0 ? '10px' : '0' }} key={line}>
            {line}
          </div>
        ))}
      </div>
    );
  };
  const createManualVersionComparisonPage = async () => {
    setIsLoading(true);
    await createManualVersionByComparisonPageId(id);
    setIsLoading(false);
  };

  const createAnalystVersionByComparison = async () => {
    setIsLoading(true);
    await createAnalystVersionByComparisonPageId(id);
    setIsLoading(false);
  };

  const previewComparisonPage = async () => {
    setIsLoading(true);
    setPreviewModalOpen(true);
    setIsLoading(false);
  };

  const rewriteComparisonPageProducts = async () => {
    rewriteComparisonPageProductsById({
      keyword,
      websiteId: props.selectedWebsiteId,
      version,
    });
    toast.success('Executed, it will take few minutes..');
  };

  const createGoogleAdsCampaign = async (websiteId: number) => {
    if (productionPageUrl && productionPageUrl !== null && productionPageUrl !== '') {
      setIsLoading(true);
      await createGoogleAdsCampaignForComparisonPage({
        keyword,
        productionPageUrl,
        websiteId,
      });
      setIsLoading(false);
    } else {
      toast.error('Production page URL is missing!');
    }
  };

  const removeAsinFromComparisonPage = async () => {
    const found = originalProducts.find((p) => p.product.asin === asinToRemove);

    if (found) {
      setIsLoading(true);

      const payload = {
        asin: asinToRemove,
        websiteId: props.selectedWebsiteId,
        comparisonPageId: id,
        shouldDelete: true,
      };

      await updateProduct(payload);
      setIsLoading(false);
      await reloadProducts();
      toast.success('Product removed successfully!');
    } else {
      toast.error('This ASIN doesn`t exist in this Comparison Page');
    }
  };

  const addAsinToComparisonPage = async () => {
    const found = originalProducts.find((p) => p.product.asin === asinToAdd);

    if (!found) {
      setIsLoading(true);

      const payload = {
        asins: [asinToAdd],
        websiteId: props.selectedWebsiteId,
        comparisonPageId: id,
        keyword: keyword,
        comparisonPageType,
      };

      await addNewProductsToExistingJpostComparisonId(payload);
      setIsLoading(false);
      await reloadProducts();
      toast.success('Product Added successfully!');
    } else {
      toast.error('This ASIN already exist in this Comparison Page');
    }
  };

  const saveNewProductsOrder = async () => {
    setIsLoading(true);

    let itemType = 'REGULAR';

    for (let i = 0; i < finalHeaders.length; i++) {
      const product = originalProducts.find((p) => p.product.asin === finalHeaders[i]);

      let position = i;

      /*     if ([WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && i >= 12) { //12 regular items
                    itemType = 'DEAL';
                    position = position - 12;
                } */

      const payload = {
        asin: product?.product.asin,
        position,
        itemType,
        comparisonPageId: id,
        grade: randomGradeByPosition(i),
        websiteId: props.selectedWebsiteId,
      };
      await updateProduct(payload);
    }

    if ([WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)) {
      await removeByComparisonPagePath({
        path: urlPath,
        websiteId: props.selectedWebsiteId,
      });
    }

    setIsLoading(false);
    toast.success('New product`s order saved successfully!');
    getComparisonDataFromDB(keyword, version);
  };

  const headersOrderChanged = (newList: any[]) => {
    setFinalHeaders(newList);
  };

  const cutImage = async (id: number) => {
    setIsLoading(true);
    try {
      const response: any = await downloadSelectedImageByIndex({
        id,
        title,
        categoryImage,
      });
      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'coverImage.png');

      // Simulate a click on the link to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
    setIsLoading(false);
  };

  if (currentEditComparisonPage.key !== -1 && !alreadyHandled) {
    handleUrlChange(null, currentEditComparisonPage);
    console.log('need to show ' + currentEditComparisonPage.text);
    setAlreadyHandled(true);
  }

  const copyToClipboard = (text: string) => {
    const input = document.createElement('input');
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);

    toast.success('Copied to clipboard!');
  };

  const handleAccordionChange = (idx: number) => {
    if (activeAccordionIndex === idx) {
      setActiveAccordionIndex(-1);
    } else {
      setActiveAccordionIndex(idx);
    }
  };

  const handleMainAccordionChange = (idx: number) => {
    if (activeMainAccordionIndex === idx) {
      setActiveMainAccordionIndex(-1);
    } else {
      setActiveMainAccordionIndex(idx);
    }
  };

  const handleProductsAccordionChange = (idx: number) => {
    if (activeProductsAccordionIndex === idx) {
      setActiveProductsAccordionIndex(-1);
    } else {
      setActiveProductsAccordionIndex(idx);
    }
  };

  const handleAsinsAccordionChange = (idx: number) => {
    if (activeAsinsAccordionIndex === idx) {
      setActiveAsinsAccordionIndex(-1);
    } else {
      setActiveAsinsAccordionIndex(idx);
    }
  };

  const validateOverallProductsData = (
    originalProducts: {
      product: Product;
      amazonData: AmazonProduct;
      keyword?: string | undefined;
    }[],
  ) => {
    let errorsCount = 0;

    originalProducts.forEach((p) => {
      const res = validateMissingFields(p);
      if (res !== `✅`) {
        errorsCount++;
      }
    });

    return errorsCount === 0 ? `✅` : `❌ (${errorsCount} Errors)`;
  };

  const validateMissingFields = (product: {
    product: Product;
    amazonData: AmazonProduct;
    keyword?: string | undefined;
  }) => {
    let missingFields = '';
    if (product.product.displayName === '') {
      missingFields = missingFields === '' ? 'Display Name' : `${missingFields}, Display Name`;
    }
    if (
      product.product.pros === '' &&
      ![WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)
    ) {
      missingFields = missingFields === '' ? 'Pros' : `${missingFields}, Pros`;
    }
    if (
      product.product.cons === '' &&
      ![WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)
    ) {
      missingFields = missingFields === '' ? 'Cons' : `${missingFields}, Cons`;
    }
    if (product.product.miniProductDescription === '') {
      missingFields = missingFields === '' ? 'Description' : `${missingFields}, Description`;
    }

    return missingFields === '' ? `✅` : `❌ ${missingFields}`;
  };

  return (
    <div className="edit-article-container">
      <div className="title">Select Comparison Page Url: </div>

      <div className="selected-url-and-reload-btn">
        <Dropdown
          search={(i) => i}
          selection
          options={urlsDropDown}
          onSearchChange={handleUrlSearchChange}
          onChange={handleUrlChange}
          value={selectedUrl}
        />

        {props.selectedWebsiteId === WEBSITE_ID.JPOST && (
          <Popup
            trigger={
              <Button
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: '0.5px solid',
                  margin: '0px',
                  marginLeft: '15px',
                  width: '50px',
                }}
                icon="send"
                disabled={selectedUrl === ''}
                onClick={(cp) => downloadLayoutsFiles(comparisonPage, '', '8', userAccessLevel, setIsLoading)}
              />
            }
            content="Download zip file"
            inverted
          />
        )}

        {props.selectedWebsiteId === WEBSITE_ID.LENSVID && (
          <Popup
            trigger={
              <Button
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: '0.5px solid',
                  margin: '0px',
                  marginLeft: '15px',
                  width: '50px',
                }}
                icon="send"
                disabled={selectedUrl === ''}
                onClick={(cp) => publishComparisonPageToWordpress(comparisonPage, '8', setIsLoading)}
              />
            }
            content="Upload to Lensvid"
            inverted
          />
        )}

        {[WEBSITE_ID.AMOBEEZ_DEMO, WEBSITE_ID.YNET_NEWS, WEBSITE_ID.ISRAEL_HAYOM].includes(props.selectedWebsiteId) && (
          <Popup
            trigger={
              <Button
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: '0.5px solid',
                  margin: '0px',
                  marginLeft: '15px',
                  width: '50px',
                }}
                icon="send"
                disabled={selectedUrl === ''}
                onClick={(cp) => publishComparisonPageToWpCms(comparisonPage, '8', setIsLoading)}
              />
            }
            content={`Upload to ${publisherNameByWebsiteIdPosition[props.selectedWebsiteId]}`}
            inverted
          />
        )}

        {props.selectedWebsiteId === WEBSITE_ID.CTECH ||
          (props.selectedWebsiteId === WEBSITE_ID.YNET && (
            <Popup
              trigger={
                <Button
                  style={{
                    backgroundColor: 'white',
                    color: 'black',
                    border: '0.5px solid',
                    margin: '0px',
                    marginLeft: '15px',
                    width: '50px',
                  }}
                  icon="download"
                  disabled={selectedUrl === ''}
                  onClick={(cp) => parentCtechYnetDownloadZipComparisonPage(comparisonPage, setIsLoading)}
                />
              }
              content="Download zip file"
              inverted
            />
          ))}

        {props.selectedWebsiteId === WEBSITE_ID.INN && (
          <Popup
            trigger={
              <Button
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: '0.5px solid',
                  margin: '0px',
                  marginLeft: '15px',
                  width: '50px',
                }}
                icon="send"
                disabled={selectedUrl === ''}
                onClick={(cp) => parentPublishComparisonPageINN(comparisonPage, '8', setIsLoading)}
              />
            }
            content="Upload to INN"
            inverted
          />
        )}

        {props.selectedWebsiteId === WEBSITE_ID.NEWS_DIRECT && (
          <Popup
            trigger={
              <Button
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: '0.5px solid',
                  margin: '0px',
                  marginLeft: '15px',
                  width: '50px',
                }}
                icon="send"
                disabled={selectedUrl === ''}
                onClick={(cp) => parentPublishComparisonPageNewsDirect(comparisonPage, '8', setIsLoading)}
              />
            }
            content="Upload to News Direct"
            inverted
          />
        )}
        <Popup
          trigger={
            <Button
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '0.5px solid',
                margin: '0px',
                marginLeft: '15px',
                width: '50px',
              }}
              icon="refresh"
              disabled={selectedUrl === ''}
              onClick={() => getComparisonDataFromDB(keyword, version)}
            />
          }
          content="Reload Article Data"
          inverted
        />
      </div>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button
          style={{
            backgroundColor: '#6435c9',
            color: 'white',
            margin: '0px',
            marginTop: '10px',
          }}
          icon="user"
          disabled={selectedUrl === '' || verified}
          content="Create Manual Editor Version"
          onClick={() => createManualVersionComparisonPage()}
        />

        <Button
          style={{
            backgroundColor: '#35c992',
            color: 'white',
            margin: '0px',
            marginTop: '10px',
          }}
          icon="line graph"
          disabled={selectedUrl === ''}
          content="Create Analyst Version"
          onClick={() => createAnalystVersionByComparison()}
        />

        {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
          <Button
            style={{
              backgroundColor: '#4c6de7',
              color: 'white',
              margin: '0px',
              marginTop: '10px',
            }}
            icon="google drive"
            disabled={selectedUrl === ''}
            content="Create Google Ads Campaign"
            onClick={() => createGoogleAdsCampaign(props.selectedWebsiteId)}
          />
        )}

        {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
          <Button
            style={{
              backgroundColor: 'black',
              color: 'white',
              margin: '0px',
              marginTop: '10px',
            }}
            icon="write"
            disabled={selectedUrl === ''}
            content="Re-write Article"
            onClick={() => rewriteComparisonPageProducts()}
          />
        )}
      </div>

      <Accordion fluid styled>
        <AccordionTitle active={activeMainAccordionIndex === 1} index={1} onClick={() => handleMainAccordionChange(1)}>
          <Icon name="dropdown" /> Products
        </AccordionTitle>

        <AccordionContent active={activeMainAccordionIndex === 1}>
          <Accordion fluid styled>
            <AccordionTitle
              active={activeProductsAccordionIndex === 3}
              index={3}
              onClick={() => handleProductsAccordionChange(3)}
            >
              <Icon name="dropdown" /> Add / Remove Products
            </AccordionTitle>

            <AccordionContent active={activeProductsAccordionIndex === 3}>
              <div className="remove-product-by-asin">
                <Form>
                  <Form.Field
                    id="form-input-control-remove-asin"
                    control={Input}
                    label="Remove Product by ASIN"
                    placeholder="ASIN"
                    value={asinToRemove}
                    onChange={(e: any) => {
                      setAsinToRemove(e.target.value);
                    }}
                  />
                </Form>
                <Button icon="trash" content="Remove" onClick={() => removeAsinFromComparisonPage()} />
              </div>

              <div className="add-product-by-asin">
                <Form>
                  <Form.Field
                    id="form-input-control-add-asin"
                    control={Input}
                    label="Add Product by ASIN"
                    placeholder="ASIN"
                    value={asinToAdd}
                    onChange={(e: any) => {
                      setAsinToAdd(e.target.value);
                    }}
                  />
                </Form>
                <Button icon="edit" content="Add New" onClick={() => addAsinToComparisonPage()} />
              </div>

              <div className="more-affiliation-products" onClick={() => getAffiliateProductsFromDB()}>
                More Affiliation Products
              </div>
              <div className="more-affiliation-products" onClick={() => getTopAffiliateProductsFromDB()}>
                TOP 2 Affiliation Products
              </div>
            </AccordionContent>

            <AccordionTitle
              active={activeProductsAccordionIndex === 0}
              index={0}
              onClick={() => handleProductsAccordionChange(0)}
            >
              <Icon name="dropdown" /> Change Products Order
            </AccordionTitle>

            <AccordionContent active={activeProductsAccordionIndex === 0}>
              <DraggableList
                items={originalProducts}
                headersOrderChanged={headersOrderChanged}
                websiteId={props.selectedWebsiteId}
                comparisonPageId={id}
              ></DraggableList>

              <div className="update-btn">
                <Form.Field
                  id="form-button-control-save-order-btn"
                  control={Button}
                  content="Save New Order"
                  onClick={() => saveNewProductsOrder()}
                />
              </div>

              {notes && notes !== '' && (
                <div
                  style={{
                    border: '0.5px solid lightgray',
                    padding: '20px',
                    margin: '20px',
                    backgroundColor: '#ffb70130',
                  }}
                >
                  Suggested Products to Add:
                  {notes?.split(',')?.map((asin: string, index) => (
                    <div key={index}>
                      {' '}
                      <a href={`https://www.amazon.com/dp/${asin.trim()}`} target="_blank">
                        https://www.amazon.com/dp/{asin.trim()}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </AccordionContent>

            <AccordionTitle
              active={activeProductsAccordionIndex === 1}
              index={1}
              onClick={() => handleProductsAccordionChange(1)}
            >
              <Icon name="dropdown" /> {validateOverallProductsData(originalProducts)} Products Data (
              {originalProducts.length} Products)
            </AccordionTitle>

            <AccordionContent active={activeProductsAccordionIndex === 1}>
              <Accordion fluid styled>
                {originalProducts?.map(
                  (
                    product: {
                      product: Product;
                      amazonData: AmazonProduct;
                      keyword?: string | undefined;
                    },
                    key: number,
                  ) => (
                    <>
                      <AccordionTitle
                        active={activeAsinsAccordionIndex === key}
                        index={key}
                        onClick={() => handleAsinsAccordionChange(key)}
                      >
                        <Icon name="dropdown" /> {key + 1}. {validateMissingFields(product)} {product.amazonData.asin}
                      </AccordionTitle>
                      <AccordionContent active={activeAsinsAccordionIndex === key}>
                        <EditProductComponent
                          key={key + product.product.asin}
                          product={product.product}
                          amazonData={product.amazonData}
                          selectedWebsiteId={props.selectedWebsiteId}
                          keyword={keyword}
                        ></EditProductComponent>
                      </AccordionContent>
                    </>
                  ),
                )}
              </Accordion>
            </AccordionContent>
          </Accordion>
        </AccordionContent>

        <AccordionTitle active={activeMainAccordionIndex === 0} index={0} onClick={() => handleMainAccordionChange(0)}>
          <Icon name="dropdown" /> Comparison Page Data
          {![WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
            <div style={{ float: 'right' }}>
              <Button
                style={{
                  width: 'auto',
                  padding: '0px',
                  backgroundColor: 'transparent',
                  color: 'darkblue',
                  margin: '0px',
                }}
                icon="eye"
                disabled={selectedUrl === ''}
                content="Preview"
                onClick={() => previewComparisonPage()}
              />
            </div>
          )}
        </AccordionTitle>

        <AccordionContent active={activeMainAccordionIndex === 0}>
          <Accordion fluid styled>
            {campaignsData?.length > 0 && (
              <AccordionTitle active={activeAccordionIndex === 0} index={0} onClick={() => handleAccordionChange(0)}>
                <Icon name="dropdown" /> Google Ads Campigns ({campaignsData?.length})
              </AccordionTitle>
            )}
            {campaignsData?.length > 0 && (
              <AccordionContent active={activeAccordionIndex === 0}>
                <div
                  style={{
                    border: '0.5px solid lightgray',
                    padding: '20px',
                    marginTop: '15px',
                    backgroundColor: '#00a4ff0f',
                  }}
                >
                  {campaignsData.map((c: any, key: number) => (
                    <div
                      key={key}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <b>Campaign ID: </b>
                        {c.id}
                      </div>
                      <div>
                        <b>Name: </b>
                        {c.name}
                      </div>
                      <div>
                        <b>Started At: </b>
                        {c.startDate}
                      </div>
                      <div>
                        <b>Status: </b>
                        <span style={c.status === 'ENABLED' ? { color: 'green' } : { color: 'red' }}>
                          <b>{c.status}</b>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </AccordionContent>
            )}

            {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
              <AccordionTitle active={activeAccordionIndex === 4} index={4} onClick={() => handleAccordionChange(4)}>
                <Icon name="dropdown" />
                Excluded ASINs | Excluded Words | Amazon Category Id |{' '}
                {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)
                  ? 'Branded Lineup | EPC Testing Mode Enabled'
                  : 'Force Branding Limit'}
              </AccordionTitle>
            )}
            {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
              <AccordionContent active={activeAccordionIndex === 4}>
                <Form>
                  <Form.Field
                    id="form-input-control-excludedAsins"
                    control={Input}
                    label="Excluded ASINs - (List of ASINs that will not be part of this article)"
                    placeholder="B066351GHG,B068351GU7,B01835OP7H..."
                    value={excludedAsins}
                    onChange={(e: any) => {
                      setExcludedAsins(e.target.value);
                    }}
                  />

                  <Form.Field
                    id="form-input-control-excludedAsins"
                    control={Input}
                    label="Excluded Words - (List of words that will not be part of the products title)"
                    placeholder="word1,word2,word3..."
                    value={excludedWords}
                    onChange={(e: any) => {
                      setExcludedWords(e.target.value);
                    }}
                  />

                  <Form.Group widths="equal">
                    <Form.Field
                      id="form-input-control-amazonCategoryId"
                      control={Input}
                      label="Amazon Category Id"
                      placeholder="Amazon Category Id"
                      value={amazonCategoryId}
                      onChange={(e: any) => {
                        setAmazonCategoryId(e.target.value);
                      }}
                    />

                    <Form.Field>
                      <Checkbox
                        style={{ marginTop: '35px', marginLeft: '30px' }}
                        label={
                          [WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId)
                            ? 'Branded Lineup'
                            : 'Force Branding Limit'
                        }
                        checked={forceBrandingLimit}
                        onChange={(e: any, data: any) => {
                          console.log(data.checked);
                          setForceBrandingLimit(data.checked);
                        }}
                      />
                    </Form.Field>

                    {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
                      <Form.Field>
                        <Checkbox
                          style={{ marginTop: '35px', marginLeft: '30px' }}
                          label="EPC Testing Mode Enabled"
                          checked={epcTestingModeEnabled}
                          onChange={(e: any, data: any) => {
                            console.log(data.checked);
                            setEpcTestingModeEnabled(data.checked);
                          }}
                        />
                      </Form.Field>
                    )}
                  </Form.Group>
                </Form>
              </AccordionContent>
            )}

            {(props.selectedWebsiteId === WEBSITE_ID.YNET_NEWS ||
              props.selectedWebsiteId === WEBSITE_ID.ISRAEL_HAYOM) && (
              <AccordionTitle active={activeAccordionIndex === 0} index={0} onClick={() => handleAccordionChange(0)}>
                <Icon name="dropdown" />
                Page Type: {comparisonPageType}
              </AccordionTitle>
            )}
            {(props.selectedWebsiteId === WEBSITE_ID.YNET_NEWS ||
              props.selectedWebsiteId === WEBSITE_ID.ISRAEL_HAYOM) && (
              <AccordionContent active={activeAccordionIndex === 0}>
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field
                      control={Select}
                      options={comparisonPageTypeOptions}
                      label="Change Page Type"
                      placeholder="COMPARISON"
                      search
                      value={comparisonPageType}
                      onChange={(e: any) => {
                        setComparisonPageType(e.target.innerText);
                      }}
                      searchInput={{
                        id: 'form-select-control-comparison-page-type-dd',
                      }}
                    />
                  </Form.Group>
                </Form>
              </AccordionContent>
            )}

            <AccordionTitle active={activeAccordionIndex === 1} index={1} onClick={() => handleAccordionChange(1)}>
              <Icon name="dropdown" />
              {productionPageUrl !== '' && writterName !== '' ? `✅` : `❌`} Keyword | Related Keywords | Production
              Page URL | Writter Name{' '}
              {props.selectedWebsiteId === WEBSITE_ID.YNET_NEWS || props.selectedWebsiteId === WEBSITE_ID.ISRAEL_HAYOM
                ? `|  Category`
                : ``}
            </AccordionTitle>
            <AccordionContent active={activeAccordionIndex === 1}>
              <Form>
                <Form.Group widths="equal">
                  <Form.Field
                    id="form-input-control-keyword"
                    control={Input}
                    label="Keyword - (not editable)"
                    placeholder="Keyword"
                    value={keyword}
                    // disabled={true}
                    onChange={(e: any) => {
                      setKeyword(keyword);
                    }}
                  />

                  <Form.Field
                    id="form-input-control-relatedKeywords"
                    control={Input}
                    label="Related Keywords"
                    placeholder="Related Keywords"
                    value={relatedKeywords}
                    onChange={(e: any) => {
                      setRelatedKeywords(relatedKeywords);
                    }}
                  />

                  {/* <Form.Field
                                        id='form-input-control-dateAndName'
                                        control={Input}
                                        label='Writter Name'
                                        placeholder='Writter Name'
                                        value={writterName}
                                        onChange={(e: any) => { setWritterName(e.target.value) }}
                                    /> */}
                  <Form.Field
                    control={Select}
                    options={writerOptions}
                    label="Writer"
                    placeholder="Writer"
                    search
                    value={writterName}
                    onChange={(e: any) => {
                      setWritterName(e.target.innerText);
                    }}
                    searchInput={{ id: 'form-select-control-writer-dd' }}
                  />
                </Form.Group>

                {(props.selectedWebsiteId === WEBSITE_ID.YNET_NEWS ||
                  props.selectedWebsiteId === WEBSITE_ID.ISRAEL_HAYOM) && (
                  <Form.Field
                    control={Select}
                    options={categoryOptions}
                    label="Category"
                    placeholder="Category"
                    search
                    value={categoryName}
                    onChange={(e: any) => {
                      setCategoryName(e.target.innerText);
                    }}
                    searchInput={{ id: 'form-select-control-category-dd' }}
                  />
                )}
                <Form.Field
                  icon={
                    <Icon
                      name="share square"
                      size="large"
                      color="blue"
                      link
                      onClick={() =>
                        productionPageUrl && productionPageUrl !== '' ? window.open(productionPageUrl, '_target') : null
                      }
                    />
                  }
                  id="form-input-control-productionPageUrl"
                  control={Input}
                  label="Production Page URL"
                  placeholder="...."
                  value={productionPageUrl}
                  onChange={(e: any) => {
                    setProductionPageUrl(e.target.value);
                  }}
                />
              </Form>
            </AccordionContent>

            <AccordionTitle active={activeAccordionIndex === 2} index={2} onClick={() => handleAccordionChange(2)}>
              {comparisonPageType === 'COMPARISON' ? (
                <>
                  <Icon name="dropdown" />
                  {title !== '' && teaser !== '' && introContent !== '' ? `✅` : `❌`} Title | Teaser | Intro
                </>
              ) : (
                <>
                  <Icon name="dropdown" />
                  {title !== '' && teaser !== '' ? `✅` : `❌`} Title | Teaser{' '}
                </>
              )}
            </AccordionTitle>
            <AccordionContent active={activeAccordionIndex === 2}>
              <Form>
                <div className="generate-part">
                  <Popup
                    trigger={<Button icon="refresh" onClick={() => regenerateJpostTitle()} />}
                    content="Re-Generate Title"
                    inverted
                  />
                  <Form.Field
                    id="form-input-control-title"
                    control={Input}
                    label="Title"
                    placeholder="Title"
                    value={title}
                    onChange={(e: any) => {
                      setTitle(e.target.value);
                    }}
                  />
                </div>

                <div className="generate-part">
                  <Popup
                    trigger={<Button icon="refresh" onClick={() => regenerateJpostTeaser()} />}
                    content="Re-Generate Teaser"
                    inverted
                  />
                  <Form.Field
                    id="form-textarea-control-teaser"
                    control={TextArea}
                    label={
                      <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        🪄 Teaser
                        <p
                          style={{
                            fontWeight: '300',
                            fontSize: '12px',
                            color: 'gray',
                            paddingLeft: '5px',
                          }}
                        >
                          ( Suggessted )
                        </p>
                      </div>
                    }
                    placeholder="Teaser - Suggessted"
                    value={teaser}
                    onChange={(e: any) => {
                      setTeaser(e.target.value);
                    }}
                  />
                </div>

                <Form.Field
                  id="form-textarea-control-teaser-edited"
                  control={TextArea}
                  label={
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      📝 Teaser
                      <p
                        style={{
                          fontWeight: '300',
                          fontSize: '12px',
                          color: 'gray',
                          paddingLeft: '5px',
                        }}
                      >
                        ( Edited )
                      </p>
                    </div>
                  }
                  placeholder="Teaser - Edited"
                  value={teaserEdited}
                  onChange={(e: any) => {
                    setTeaserEdited(e.target.value);
                  }}
                />

                {comparisonPageType === 'COMPARISON' && (
                  <div className="generate-part">
                    <Popup
                      trigger={<Button icon="refresh" onClick={() => regenerateJpostIntroContent()} />}
                      content="Re-Generate Intro Content"
                      inverted
                    />
                    <Form.Field
                      id="form-textarea-control-intro-content"
                      control={TextArea}
                      label={
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                          🪄 Intro Content
                          <p
                            style={{
                              fontWeight: '300',
                              fontSize: '12px',
                              color: 'gray',
                              paddingLeft: '5px',
                            }}
                          >
                            ( Suggessted )
                          </p>
                        </div>
                      }
                      placeholder="Intro Content - Suggessted"
                      value={introContent}
                      onChange={(e: any) => {
                        setIntroContent(e.target.value);
                      }}
                    />
                  </div>
                )}

                {comparisonPageType === 'COMPARISON' && (
                  <Form.Field
                    id="form-textarea-control-intro-content-edited"
                    control={TextArea}
                    label={
                      <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        📝 Intro Content
                        <p
                          style={{
                            fontWeight: '300',
                            fontSize: '12px',
                            color: 'gray',
                            paddingLeft: '5px',
                          }}
                        >
                          ( Edited )
                        </p>
                      </div>
                    }
                    placeholder="Intro Content - Edited"
                    value={introContentEdited}
                    onChange={(e: any) => {
                      setIntroContentEdited(e.target.value);
                    }}
                  />
                )}
              </Form>
            </AccordionContent>

            {comparisonPageType === 'COMPARISON' && (
              <AccordionTitle active={activeAccordionIndex === 3} index={3} onClick={() => handleAccordionChange(3)}>
                <Icon name="dropdown" />
                {conclusion !== '' && faq !== '' ? `✅` : `❌`} Conclusion | FAQ | Buyers Guide
              </AccordionTitle>
            )}
            {comparisonPageType === 'COMPARISON' && (
              <AccordionContent active={activeAccordionIndex === 3}>
                <Form>
                  <div className="generate-part">
                    <Popup
                      trigger={<Button icon="refresh" onClick={() => regenerateJpostConclusion()} />}
                      content="Re-Generate Conclusion"
                      inverted
                    />
                    <Form.Field
                      id="form-textarea-control-conclusion"
                      control={TextArea}
                      label={
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                          🪄 Conclusion
                          <p
                            style={{
                              fontWeight: '300',
                              fontSize: '12px',
                              color: 'gray',
                              paddingLeft: '5px',
                            }}
                          >
                            ( Suggessted )
                          </p>
                        </div>
                      }
                      placeholder="Conclusion - Suggessted"
                      value={conclusion}
                      onChange={(e: any) => {
                        setConclusion(e.target.value);
                      }}
                    />
                  </div>

                  <Form.Field
                    id="form-textarea-control-conclusion-edited"
                    control={TextArea}
                    label={
                      <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        📝 Conclusion
                        <p
                          style={{
                            fontWeight: '300',
                            fontSize: '12px',
                            color: 'gray',
                            paddingLeft: '5px',
                          }}
                        >
                          ( Edited )
                        </p>
                      </div>
                    }
                    placeholder="Conclusion - Edited"
                    value={conclusionEdited}
                    onChange={(e: any) => {
                      setConclusionEdited(e.target.value);
                    }}
                  />

                  <div className="generate-part">
                    <Popup
                      trigger={<Button icon="refresh" onClick={() => regenerateJpostFaq()} />}
                      content="Re-Generate FAQ"
                      inverted
                    />
                    <Form.Field
                      id="form-textarea-control-Faq"
                      control={TextArea}
                      label={
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                          🪄 FAQ
                          <p
                            style={{
                              fontWeight: '300',
                              fontSize: '12px',
                              color: 'gray',
                              paddingLeft: '5px',
                            }}
                          >
                            ( Suggessted )
                          </p>
                        </div>
                      }
                      placeholder="FAQ - Suggessted"
                      value={faq}
                      onChange={(e: any) => {
                        setFaq(e.target.value);
                      }}
                    />
                  </div>

                  <Form.Field
                    id="form-textarea-control-Faq-edited"
                    control={TextArea}
                    label={
                      <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        📝 FAQ
                        <p
                          style={{
                            fontWeight: '300',
                            fontSize: '12px',
                            color: 'gray',
                            paddingLeft: '5px',
                          }}
                        >
                          ( Edited )
                        </p>
                      </div>
                    }
                    placeholder="FAQ - Edited"
                    value={faqEdited}
                    onChange={(e: any) => {
                      setFaqEdited(e.target.value);
                    }}
                  />

                  <div className="generate-part">
                    <Popup
                      trigger={<Button icon="refresh" onClick={() => regenerateJpostBuyersGuide()} />}
                      content="Re-Generate Buyers Guide"
                      inverted
                    />
                    <Form.Field
                      id="form-textarea-control-buyersGuide"
                      control={TextArea}
                      label={
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                          🪄 Buyers Guide
                          <p
                            style={{
                              fontWeight: '300',
                              fontSize: '12px',
                              color: 'gray',
                              paddingLeft: '5px',
                            }}
                          >
                            ( Suggessted )
                          </p>
                        </div>
                      }
                      placeholder="Buyers Guide - Suggessted"
                      value={buyersGuideAndFaq}
                      onChange={(e: any) => {
                        setBuyersGuideAndFaq(e.target.value);
                      }}
                    />
                  </div>

                  <Form.Field
                    id="form-textarea-control-buyersGuide-edited"
                    control={TextArea}
                    label={
                      <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        📝 Buyers Guide
                        <p
                          style={{
                            fontWeight: '300',
                            fontSize: '12px',
                            color: 'gray',
                            paddingLeft: '5px',
                          }}
                        >
                          ( Edited )
                        </p>
                      </div>
                    }
                    placeholder="Buyers Guide - Edited"
                    value={buyersGuideAndFaqEdited}
                    onChange={(e: any) => {
                      setBuyersGuideAndFaqEdited(e.target.value);
                    }}
                  />
                </Form>
              </AccordionContent>
            )}

            {comparisonPageType === 'BLOG' && (
              <AccordionTitle active={activeAccordionIndex === 4} index={4} onClick={() => handleAccordionChange(4)}>
                <Icon name="dropdown" />
                {blogContent !== '' ? `✅` : `❌`} Blog Content
              </AccordionTitle>
            )}
            {comparisonPageType === 'BLOG' && (
              <AccordionContent active={activeAccordionIndex === 4}>
                <Form>
                  <div className="generate-part">
                    <div style={{ fontSize: '13px', userSelect: 'none' }}>
                      * In order to add Product Item use: {`{{PRODUCT:ASIN}}`} example: {`{{PRODUCT:B00Q8M0NWE}}`}
                    </div>
                    <div style={{ fontSize: '13px', userSelect: 'none' }}>
                      * In order to use Product Link use: {`{{URL:ASIN}}`} example: {`{{URL:B00Q8M0NWE}}`}
                    </div>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      value={blogContent}
                      onChange={setBlogContent}
                    />
                    {/*  <Form.Field
                                        id='form-textarea-control-conclusion'
                                        control={TextArea}
                                        label={<div style={{ display: 'flex', alignItems: 'baseline' }}>📝 blogContent<p style={{ fontWeight: '300', fontSize: '12px', color: 'gray', paddingLeft: '5px' }}></p></div>}
                                        placeholder='blogContent'
                                        value={blogContent}
                                        onChange={(e: any) => { setBlogContent(e.target.value) }}
                                    /> */}
                  </div>
                </Form>
              </AccordionContent>
            )}

            <AccordionTitle active={activeAccordionIndex === 5} index={5} onClick={() => handleAccordionChange(5)}>
              <Icon name="dropdown" />
              {categoryImage !== '' ? `✅` : `❌`} Cover Image
            </AccordionTitle>
            <AccordionContent active={activeAccordionIndex === 5}>
              {(props.selectedWebsiteId === WEBSITE_ID.YNET_NEWS ||
                props.selectedWebsiteId === WEBSITE_ID.ISRAEL_HAYOM) && (
                <Popup
                  trigger={
                    <Button
                      style={{
                        width: '300px',
                        backgroundColor: 'darkolivegreen',
                        color: 'white',
                        margin: '0px',
                        marginTop: '10px',
                      }}
                      icon="image"
                      disabled={selectedUrl === '' || verified}
                      content={`Update cover image to ${publisherNameByWebsiteIdPosition[props.selectedWebsiteId]}`}
                      onClick={() => updateImageToWPCms(comparisonPage, setIsLoading)}
                    />
                  }
                  content={`Update Cover Image to ${publisherNameByWebsiteIdPosition[props.selectedWebsiteId]} (must save image url before if url was change)`}
                  inverted
                />
              )}
              <Form>
                <div className="img-container-image-url">
                  <div className="generate-part">
                    <Popup
                      trigger={<Button icon="refresh" onClick={() => regenerateJpostCoverImage()} />}
                      content="Re-Generate Cover Image"
                      inverted
                    />
                    <Form.Field
                      id="form-input-control-image-url"
                      control={Input}
                      label="Category Image"
                      placeholder="Category Image"
                      value={categoryImage}
                      onChange={(e: any) => {
                        setCategoryImage(e.target.value);
                      }}
                    />
                  </div>

                  {props.selectedWebsiteId === WEBSITE_ID.JPOST && (
                    <div className="split-images-container">
                      <div onClick={() => cutImage(1)}>1</div>
                      <div onClick={() => cutImage(2)}>2</div>
                      <div onClick={() => cutImage(3)}>3</div>
                      <div onClick={() => cutImage(4)}>4</div>
                    </div>
                  )}

                  <Image src={categoryImage} as="a" href={categoryImage} target="_blank" size="large" />
                </div>
              </Form>
            </AccordionContent>

            {userAccessLevel === ACCESS_LEVEL.ADMIN && (
              <AccordionTitle active={activeAccordionIndex === 6} index={6} onClick={() => handleAccordionChange(6)}>
                <Icon name="dropdown" />
                🗑️ Delete Article
              </AccordionTitle>
            )}
            {userAccessLevel === ACCESS_LEVEL.ADMIN && (
              <AccordionContent active={activeAccordionIndex === 6}>
                <Form>
                  <Button
                    color="red"
                    icon="trash"
                    content="Remove Comparison Page"
                    onClick={() => deleteTheEntireComparisonPage()}
                  />
                </Form>
              </AccordionContent>
            )}
          </Accordion>
          <div className="update-btn">
            <Form.Field
              id="form-button-control-update-btn"
              control={Button}
              content="Update Comparison Page Data"
              disabled={id === -1}
              onClick={() => updateComparisonPageData()}
            />
          </div>
        </AccordionContent>
      </Accordion>

      <Modal
        centered={true}
        open={previewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        onOpen={() => setPreviewModalOpen(true)}
      >
        <ModalHeader>Preview</ModalHeader>
        <ModalContent>
          <ModalDescription>
            <div className="modal-preview-container">
              <label className="modal-preview-title">{title}</label>
              <Image src={categoryImage} as="a" href={categoryImage} target="_blank" size="large" />
              <label
                style={{
                  marginTop: '10px',
                  color: `${teaserEdited === '' || teaserEdited === null ? 'red' : 'green'}`,
                }}
              >
                {teaserEdited === '' || teaserEdited === null ? 'Teaser <Suggested>' : 'Teaser <Edited>'}
              </label>
              <label style={{ marginTop: '10px' }}>
                {teaserEdited === '' || teaserEdited === null ? teaser : teaserEdited}
              </label>

              {comparisonPageType === 'COMPARISON' && <div className="divider-solid" />}
              {comparisonPageType === 'COMPARISON' && (
                <label
                  style={{
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    color: `${introContentEdited === '' || introContentEdited === null ? 'red' : 'green'}`,
                  }}
                >
                  {introContentEdited === '' || introContentEdited === null
                    ? 'Intro Content <Suggested>'
                    : 'Intro Content <Edited>'}
                </label>
              )}
              {comparisonPageType === 'COMPARISON' && (
                <label>
                  {introContentEdited === '' || introContentEdited === null ? introContent : introContentEdited}
                </label>
              )}
              {comparisonPageType === 'COMPARISON' && <div className="divider-solid" />}

              {comparisonPageType === 'COMPARISON' &&
                originalProducts &&
                originalProducts.map((item, i) => (
                  <div key={i} className="preview-product-container">
                    <label className="preview-product-name">
                      {i + 1}. {item.product.displayName}
                    </label>
                    <Image
                      src={item.amazonData.image1}
                      as="a"
                      href={item.amazonData.image1}
                      target="_blank"
                      size="small"
                    />
                    <label
                      style={{
                        marginTop: '20px',
                        marginBottom: '10px',
                        fontWeight: 'bold',
                        color: `${item.product.miniProductDescriptionEdited === '' || item.product.miniProductDescriptionEdited === null ? 'red' : 'green'}`,
                      }}
                    >
                      {item.product.miniProductDescriptionEdited === '' ||
                      item.product.miniProductDescriptionEdited === null
                        ? 'Description <Suggested>'
                        : 'Description <Edited>'}
                    </label>
                    <label>
                      {item.product.miniProductDescriptionEdited === '' ||
                      item.product.miniProductDescriptionEdited === null
                        ? item.product.miniProductDescription
                        : item.product.miniProductDescriptionEdited}
                    </label>

                    <label
                      style={{
                        marginTop: '20px',
                        marginBottom: '10px',
                        fontWeight: 'bold',
                        color: `${item.product.prosEdited === '' || item.product.prosEdited === null ? 'red' : 'green'}`,
                      }}
                    >
                      {item.product.prosEdited === '' || item.product.prosEdited === null
                        ? 'Pros <Suggested>'
                        : 'Pros <Edited>'}
                    </label>
                    <label>{item.product.pros}</label>
                    <label
                      style={{
                        marginTop: '20px',
                        marginBottom: '10px',
                        fontWeight: 'bold',
                        color: `${item.product.consEdited === '' || item.product.consEdited === null ? 'red' : 'green'}`,
                      }}
                    >
                      {item.product.consEdited === '' || item.product.consEdited === null
                        ? 'Cons <Suggested>'
                        : 'Cons <Edited>'}
                    </label>
                    <label>{item.product.cons}</label>

                    <div className="divider-solid" />
                  </div>
                ))}

              {comparisonPageType === 'COMPARISON' && (
                <label
                  style={{
                    marginBottom: '10px',
                    fontWeight: 'bold',
                    color: `${faqEdited === '' || faqEdited === null ? 'red' : 'green'}`,
                  }}
                >
                  {faqEdited === '' || faqEdited === null ? 'FAQ <Suggested>' : 'FAQ <Edited>'}
                </label>
              )}
              {comparisonPageType === 'COMPARISON' && (
                <label>{faqEdited === '' || faqEdited === null ? formatFaq(faq) : formatFaq(faqEdited)}</label>
              )}

              {comparisonPageType === 'BLOG' && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${blogContent}`,
                  }}
                />
              )}
            </div>
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => setPreviewModalOpen(false)}>Close</Button>
        </ModalActions>
      </Modal>
      <Modal
        centered={true}
        open={affiliationModalOpen}
        onClose={() => setAffiliationModalOpen(false)}
        onOpen={() => setAffiliationModalOpen(true)}
      >
        <ModalHeader>More Affiliation Product</ModalHeader>
        <ModalContent>
          <ModalDescription
            style={{
              padding: '10px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '30px',
            }}
          >
            {additionalAffiliationProducts &&
              additionalAffiliationProducts.map((item, i) => (
                <div
                  key={i}
                  style={{
                    width: '170px',
                    height: 'auto',
                    border: '0.5px solid gray',
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <a href={`https://www.amazon.com/dp/${item.asin}`} target="_blank">
                    <Image src={item.image} size="small" />
                  </a>

                  {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) &&
                    userAccessLevel === ACCESS_LEVEL.ADMIN && (
                      <div
                        style={{
                          color: 'gray',
                          fontWeight: 'bold',
                          paddingTop: '10px',
                        }}
                      >
                        {item.affiliateType}
                      </div>
                    )}
                  <div
                    style={{
                      color: 'dodgerblue',
                      fontWeight: 'bold',
                      paddingTop: '10px',
                    }}
                  >
                    {item.brand}
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      paddingTop: '1px',
                    }}
                    onClick={() => copyToClipboard(item.asin)}
                  >
                    {item.asin}
                  </div>
                  <div style={{ color: 'gray', fontWeight: 'bold' }}>
                    ★{item.rating} ({item.reviews})
                  </div>
                  <div style={{ color: '#f2711c', fontWeight: 'bold' }}>Price: ${item.price}</div>
                  {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
                    <div style={{ color: 'purple', fontWeight: 'bold' }}>Percentage: {item.percentage}</div>
                  )}
                  {[WEBSITE_ID.GUIDE_TOP_REVIEW, WEBSITE_ID.BUYEREVIEWS].includes(props.selectedWebsiteId) && (
                    <div style={{ color: 'green', fontWeight: 'bold' }}>Profit: ${item.potentialProfit.toFixed(2)}</div>
                  )}
                </div>
              ))}
          </ModalDescription>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => setAffiliationModalOpen(false)}>Close</Button>
        </ModalActions>
      </Modal>
    </div>
  );
};

export default EditArticleComponent;
